import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { IFetchPageParams } from 'alpha-api-client';
import { RootAction, RootState, Services } from 'alpha-screener-types';

import store from '../../../../store/index';
import { computeFiltersWithDateRange } from '../../../common/epicsUtils';
import { FETCH_CHART_AGGREGATES, FETCH_LIST_AGGREGATES, MORE_LIST_AGGREGATES } from './actions';

export const fetchListAggregatesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(FETCH_LIST_AGGREGATES.request)),
        mergeMap(async (action) => {
            const aggregatesState = store.getState().aggregates;

            const startDate = action.payload.startDate;
            const endDate = action.payload.endDate;
            const filters = computeFiltersWithDateRange('date', action.payload.filters || aggregatesState.list.query.filters, startDate, endDate);

            const sortBy = action.payload.sortBy || aggregatesState.list.query.sortBy;
            const limit = action.payload.limit || aggregatesState.list.query.limit;

            const { simulationId, ..._params } = action.payload;
            const params: IFetchPageParams = {
                ..._params,
                filters,
                sortBy,
                limit,
            };

            try {
                const result = await api.apiClient.simulations.getSimulationAggregates(simulationId, params);
                return FETCH_LIST_AGGREGATES.success({ params: action.payload, result });
            } catch (e) {
                return FETCH_LIST_AGGREGATES.failure(e.message);
            }
        }),
    );
};

export const moreListAggregatesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(MORE_LIST_AGGREGATES.request)),
        mergeMap(async (action) => {
            const aggregatesState = store.getState().aggregates;
            if (!aggregatesState.list.hasNext) {
                throw new Error('NO NEXT');
            }
            const stateQuery = aggregatesState.list.query || {};
            const { simulationId, ..._params } = action.payload;
            const params: IFetchPageParams = {
                ...stateQuery,
                ..._params,
                page: (aggregatesState.list.page || 1) + 1,
            };

            try {
                const result = await api.apiClient.simulations.getSimulationAggregates(simulationId, params);
                return MORE_LIST_AGGREGATES.success({ params: action.payload, result });
            } catch (e) {
                return MORE_LIST_AGGREGATES.failure(e.message);
            }
        }),
    );
};

export const fetchChartAggregatesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(FETCH_CHART_AGGREGATES.request)),
        mergeMap(async (action) => {
            const aggregatesState = store.getState().aggregates;

            const startDate = action.payload.startDate;
            const endDate = action.payload.endDate;
            const filters = computeFiltersWithDateRange('date', action.payload.filters || aggregatesState.chart.query.filters, startDate, endDate);

            const sortBy = action.payload.sortBy || aggregatesState.chart.query.sortBy;
            const limit = action.payload.limit || aggregatesState.chart.query.limit;

            const { simulationId, ..._params } = action.payload;
            const params: IFetchPageParams = {
                ..._params,
                filters,
                sortBy,
                limit,
            };

            try {
                const result = await api.apiClient.simulations.getSimulationAggregates(simulationId, params);
                return FETCH_CHART_AGGREGATES.success({ params: action.payload, result });
            } catch (e) {
                return FETCH_CHART_AGGREGATES.failure(e.message);
            }
        }),
    );
};
