import { UiSchema } from '@rjsf/utils';
import * as _ from 'lodash';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import { IEntityDetailResponse, IEntityListCapabilities, IEntitySchemaDefinition, ISimulation, JSONSchema7Extended, SimulationAction } from 'alpha-api-client';

import { reducePage } from '../common/reducersUtils';
import { IFiltersParams, ISortByParams } from '../common/types';
import {
    CREATE_SIMULATION,
    DELETE_SIMULATION,
    GET_SIMULATION,
    GET_SIMULATION_SCHEMA,
    IGetSimulationActionResult,
    LIST_SIMULATIONS,
    SET_INITIAL_CAPITAL,
    SET_MAX_LEVERAGE,
    SET_NOMINAL_INVEST,
    SET_RISK_FACTOR,
    UPDATE_SIMULATION,
} from './actions';

export interface ISimulatorState {
    nominalInvest: number;
    initialCapital: number | null;
    riskFactor: number | null;
    maxLeverage?: number;
}

export interface ISimulationsState {
    schema?: JSONSchema7Extended;
    representation?: UiSchema;
    capabilities: IEntityListCapabilities;
    action?: { name: SimulationAction } & IEntitySchemaDefinition;
    redirect?: { simulationId?: string };
    items: IEntityDetailResponse<ISimulation>[];
    query: {
        filters: IFiltersParams[];
        sortBy: ISortByParams[];
    };
    isLoading: boolean;
    current?: IGetSimulationActionResult;
}

interface ISimulationStates {
    simulator: ISimulatorState;
    simulations: ISimulationsState;
}

const initialState: ISimulationStates = {
    simulator: {
        nominalInvest: 10,
        initialCapital: null,
        riskFactor: null,
        maxLeverage: null,
    },
    simulations: {
        items: [],
        query: {
            filters: [],
            sortBy: [{
                field: 'name',
            }],
        },
        capabilities: {
            $canCreate: false,
        },
        isLoading: false,
    },
};

export default combineReducers({
    simulator: createReducer(initialState.simulator)

        .handleAction([SET_NOMINAL_INVEST],
            (state: ISimulatorState, action: ActionType<typeof SET_NOMINAL_INVEST>): ISimulatorState => {
                return {
                    ...state,
                    nominalInvest: action.payload,
                };
            })

        .handleAction([SET_INITIAL_CAPITAL],
            (state: ISimulatorState, action: ActionType<typeof SET_INITIAL_CAPITAL>): ISimulatorState => {
                return {
                    ...state,
                    initialCapital: action.payload,
                };
            })
        .handleAction([SET_RISK_FACTOR],
            (state: ISimulatorState, action: ActionType<typeof SET_RISK_FACTOR>): ISimulatorState => {
                return {
                    ...state,
                    riskFactor: action.payload,
                };
            })
        .handleAction([SET_MAX_LEVERAGE],
            (state: ISimulatorState, action: ActionType<typeof SET_MAX_LEVERAGE>): ISimulatorState => {
                return {
                    ...state,
                    maxLeverage: action.payload,
                };
            }),

    simulations: createReducer(initialState.simulations)
        .handleAction([LIST_SIMULATIONS.request],
            (state: ISimulationsState): ISimulationsState => {
                return {
                    ...state,
                    isLoading: true,
                };
            })

        .handleAction([LIST_SIMULATIONS.failure],
            (state: ISimulationsState): ISimulationsState => {
                return {
                    ...state,
                    isLoading: false,
                };
            })

        .handleAction([LIST_SIMULATIONS.success],
            (state: ISimulationsState, action: ActionType<typeof LIST_SIMULATIONS.success>): ISimulationsState => {
                return {
                    ...state,
                    ...reducePage(action.payload.result) as any,
                    query: {
                        filters: action.payload.params.filters || state.query.filters,
                        sortBy: action.payload.params.sortBy || state.query.sortBy,
                    },
                    capabilities: action.payload.result.$capabilities,
                };
            })

        .handleAction([GET_SIMULATION_SCHEMA.request],
            (state: ISimulationsState, action: ActionType<typeof GET_SIMULATION_SCHEMA.request>): ISimulationsState => {
                return {
                    ...state,
                    action: undefined,
                };
            })

        .handleAction([GET_SIMULATION_SCHEMA.success],
            (state: ISimulationsState, action: ActionType<typeof GET_SIMULATION_SCHEMA.success>): ISimulationsState => {
                return {
                    ...state,
                    action: {
                        name: action.payload.name,
                        $schema: action.payload.result.$schema,
                        $representation: action.payload.result.$representation,
                    },
                };
            })

        .handleAction([GET_SIMULATION.request],
            (state: ISimulationsState): ISimulationsState => {
                return {
                    ...state,
                    isLoading: true,
                    current: undefined,
                };
            })

        .handleAction([GET_SIMULATION.failure],
            (state: ISimulationsState): ISimulationsState => {
                return {
                    ...state,
                    isLoading: false,
                    current: undefined,
                };
            })

        .handleAction([GET_SIMULATION.success],
            (state: ISimulationsState, action: ActionType<typeof GET_SIMULATION.success>): ISimulationsState => {
                return {
                    ...state,
                    isLoading: false,
                    current: action.payload,
                    redirect: undefined,
                };
            })

        .handleAction([CREATE_SIMULATION.request],
            (state: ISimulationsState): ISimulationsState => {
                return {
                    ...state,
                    isLoading: true,
                };
            })

        .handleAction([CREATE_SIMULATION.failure],
            (state: ISimulationsState): ISimulationsState => {
                return {
                    ...state,
                    isLoading: false,
                };
            })

        .handleAction([CREATE_SIMULATION.success],
            (state: ISimulationsState, action: ActionType<typeof CREATE_SIMULATION.success>): ISimulationsState => {
                return {
                    ...state,
                    isLoading: false,
                    current: action.payload,
                    items: state.items.concat(action.payload),
                    redirect: {
                        simulationId: action.payload.id,
                    },
                };
            })

        .handleAction([UPDATE_SIMULATION.request],
            (state: ISimulationsState): ISimulationsState => {
                return {
                    ...state,
                    isLoading: true,
                    current: undefined,
                };
            })

        .handleAction([UPDATE_SIMULATION.failure],
            (state: ISimulationsState): ISimulationsState => {
                return {
                    ...state,
                    isLoading: false,
                    current: undefined,
                };
            })

        .handleAction([UPDATE_SIMULATION.success],
            (state: ISimulationsState, action: ActionType<typeof UPDATE_SIMULATION.success>): ISimulationsState => {
                return {
                    ...state,
                    isLoading: false,
                    current: action.payload,
                    items: state.items.map((item) => {
                        if (item.id === action.payload.id) {
                            return action.payload;
                        }
                        return item;
                    }),
                };
            })

        .handleAction([DELETE_SIMULATION.request],
            (state: ISimulationsState): ISimulationsState => {
                return {
                    ...state,
                    isLoading: true,
                    current: undefined,
                };
            })

        .handleAction([DELETE_SIMULATION.failure],
            (state: ISimulationsState): ISimulationsState => {
                return {
                    ...state,
                    isLoading: false,
                    current: undefined,
                };
            })

        .handleAction([DELETE_SIMULATION.success],
            (state: ISimulationsState, action: ActionType<typeof DELETE_SIMULATION.success>): ISimulationsState => {
                const items = _.filter(state.items, item => item.id !== action.payload.simulationId);
                return {
                    ...state,
                    isLoading: false,
                    items,
                };
            }),
});
