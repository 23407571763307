import { IEntityListResponse } from './entities';

export enum OptimizationConfigKind {
    TRAILING = 'trailing',
    TRAILING_CONVERGENCE = 'trailing_convergence',
    INTERVALS = 'intervals',
    TAKE_PROFIT = 'takeprofit',
}

export enum OptimizationConfigMode {
    ALL_SYMBOLS = 'all-symbols',
    BY_SYMBOL = 'by-symbol',
}

export interface IOptimizationStopConfig {
    indicator?: 'atr' | 'distSupResAvg';
    factor?: number;
}

export interface IOptimizationConfigBase {
    from?: Date;
    to?: Date;
    profitOverSuccesFactor?: number;
    trendRanges?: string;
    stopLoss?: IOptimizationStopConfig;
    /* deprecated */
    stopAtrFactor?: number;
    /* deprecated */
    events?: string[];
    event?: string;
}

export interface IOptimizationConfigTrailing extends IOptimizationConfigBase {
    firstStopProfitCandlesCount?: number;
    nextStopProfitCandlesCount?: number;
}

export interface IOptimizationConfigTrailingConvergence extends IOptimizationConfigBase {
    firstStopProfitGapPercentage?: number;
    nextStopProfitGapPercentage?: number;
}

export interface IOptimizationConfigIntervalLimit extends IOptimizationConfigBase {
    nbIntervals?: number;
}

export interface IOptimizationConfigTakeProfit extends IOptimizationConfigBase {
    takeProfitFactor?: IOptimizationStopConfig;
    /* deprecated */
    takeProfitAtrFactor?: number;
}

type IOptimizationConfigParamsByTypeMap = { [k in keyof OptimizationConfigKind]: IOptimizationConfigBase };

export interface IOptimizationConfigParamsByType extends IOptimizationConfigParamsByTypeMap {
    [OptimizationConfigKind.TRAILING]: IOptimizationConfigTrailing;
    [OptimizationConfigKind.TRAILING_CONVERGENCE]: IOptimizationConfigTrailingConvergence;
    [OptimizationConfigKind.INTERVALS]: IOptimizationConfigIntervalLimit;
    [OptimizationConfigKind.TAKE_PROFIT]: IOptimizationConfigTakeProfit;
}

export interface IOptimizationConfigSuccessMethods {
    ma?: {
        windows?: string;
    };
}

export interface IOptimizationConfig<T extends OptimizationConfigKind = OptimizationConfigKind> {
    kind: T;
    mode: OptimizationConfigMode;
    riskFactorMultiplier: number;
    maxNominaInvest: number;
    maxCapitalWeight?: number;
    history?: IOptimizationHistoryRange;
    successMethods?: IOptimizationConfigSuccessMethods;
    short?: IOptimizationConfigParamsByType[T];
    shortEvents?: IOptimizationConfigParamsByType[T][];
    long?: IOptimizationConfigParamsByType[T];
    longEvents?: IOptimizationConfigParamsByType[T][];
}

export interface IOptimizationCreate {
    alias: string;
}

export interface IOptimizationHistoryRange {
    from?: Date;
    to?: Date;
}

export interface IOptimizationUpdate {
    alias: string;
    config: IOptimizationConfig;
}

export interface IOptimization {
    id: string;
    simulationId: string;
    alias: string;
    config: IOptimizationConfig;
}

export enum OptimizationAction {
    CREATE = 'create',
    COMPUTE_RULES = 'compute-rules',
}

export type IOptimizationsPageApiResult = IEntityListResponse<IOptimization>;

export type OptimizationSummaryTimeframe = 'byYear' | 'byMonth';

export interface IComputeOptimizationsRulesParams {
    dryRun?: boolean;
}
