import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, AlertTitle } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as _ from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { IDiagnose, SseEventType } from 'alpha-api-client';
import { RootState } from 'alpha-screener-types';

import LinearProgressWithLabel from '../../common/components/LinearProgressWithLabel';
import { INotification } from '../actions';

const PREFIX = 'NotificationList';

const classes = {
    root: `${PREFIX}-root`,
    summary: `${PREFIX}-summary`,
    details: `${PREFIX}-details`,
    heading: `${PREFIX}-heading`,
    title: `${PREFIX}-title`,
    phase: `${PREFIX}-phase`,
    progress: `${PREFIX}-progress`,
    diagnoses: `${PREFIX}-diagnoses`,
    diagnose: `${PREFIX}-diagnose`,
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.root}`]: {
        width: '100%',
    },

    [`& .${classes.summary}`]: {
        paddingLeft: '40px',
    },

    [`& .${classes.details}`]: {
        padding: 0,
    },

    [`&.${classes.heading}`]: {
        marginTop: '24px',
        width: '100%',
    },

    [`& .${classes.title}`]: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },

    [`& .${classes.phase}`]: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightLight,
    },

    [`& .${classes.progress}`]: {
        active: {},
        success: { backgroundColor: '#c80b0b' },
        error: {},
    },

    [`& .${classes.diagnoses}`]: {
        width: '100%',
    },

    [`& .${classes.diagnose}`]: {
        width: '100%',
    },
}));

export default () => {

    const notifications = useSelector((state: RootState) => state.notifications);

    const renderNotificationItems = () => {
        return _.sortBy(_.values(notifications.list.items), 'index')
            .map((notification) => {
                return renderNotificationItem(notification);
            });
    };

    const renderNotificationItem = (notification: INotification) => {
        return renderNotificationAccordion(notification.id, notification.type === SseEventType.JOB);
    };

    const renderNotificationAccordion = (id: string, showId ?: boolean) => {
        const notification = notifications.list.items[id];
        let status: 'error' | 'active' | 'success' = 'active';
        if (notification.progress === 100) {
            status = _.some(notification.diagnoses, d => d.severity === 'error') ? 'error' : 'success';
        }

        return (
            <Accordion key={`notification-${id}`} TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    className={classes.summary}
                    expandIcon={notification.diagnoses ? <ExpandMoreIcon/> : <></>}
                    aria-controls={'panel1a-content'}
                    id={'panel1a-header'}

                >
                    <Root className={classes.heading}>
                        <Typography className={classes.title}>{notification.title}</Typography>
                        <Typography className={classes.phase}>{notification.phase}</Typography>
                        {notification.progress ? <LinearProgressWithLabel value={notification.progress || 0} status={status}/> : null}
                    </Root>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    {renderDiagnoses(id, notification.diagnoses)}
                </AccordionDetails>
            </Accordion>
        );
    };

    const renderDiagnoses = (id: string, diagnoses ?: IDiagnose[]) => {
        return <List key={`${id}-diagnoses`} sx={{ p: 0 }} className={classes.diagnoses}>
            {
                _.map(diagnoses, (d, i) => {
                    return <ListItem key={`${id}-diagnoses-${i}`}
                                     alignItems={'flex-start'}
                                     sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}>
                        <Alert severity={d.severity} className={classes.diagnose}>
                            <AlertTitle>{d.title}</AlertTitle>
                            {d.message}
                        </Alert>
                    </ListItem>;
                })
            }

        </List>;
    };

    return <div className={classes.root}>
        {renderNotificationItems()}
    </div>;

};
