import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import global from '../content/global/reducer';
import notifications from '../content/notifications/reducer';
import aggregates from '../content/simulations/components/aggregates/reducer';
import candles from '../content/simulations/components/candles/reducer';
import optimizations from '../content/simulations/components/optimizations/reducer';
import positions from '../content/simulations/components/positions/reducer';
import simulationsSummaries from '../content/simulations/components/summaries/reducer';
import simulationSymbols from '../content/simulations/components/symbols/reducer';
import simulations from '../content/simulations/reducer';
// simulations
import symbols from '../content/symbols/reducer';
import orders from '../content/wallets/components/orders/reducer';
import walletsSummaries from '../content/wallets/components/summaries/reducer';
// wallets
import wallets from '../content/wallets/reducer';

const createRootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    notifications,
    global,
    // simulations
    symbols,
    candles,
    aggregates,
    simulations,
    simulationSymbols,
    simulationsSummaries,
    positions,
    optimizations,
    // wallets
    wallets,
    orders,
    walletsSummaries,
});

export default createRootReducer;
