import * as _ from 'lodash';
import { Moment } from 'moment';

import { IApiCommonPageQueryFilter } from 'alpha-api-client';

export function computeFiltersWithDateRange(field: string,
                                            filters: IApiCommonPageQueryFilter[],
                                            startDate?: Moment,
                                            endDate?: Moment): IApiCommonPageQueryFilter[] {

    if (!startDate && !endDate) {
        // remove current filter from state
        _.remove(filters, (f: IApiCommonPageQueryFilter) => f.field === field);
    } else {

        const startDateString = startDate && startDate.toISOString();
        const endDateString = endDate && endDate.toISOString();
        // remove current filter from state
        _.remove(filters, (f: IApiCommonPageQueryFilter) => f.field === field);

        if (startDateString) {
            filters.push({ field, operator: '>=', value: startDateString });
        }
        if (endDateString) {
            filters.push({ field, operator: '<=', value: endDateString });
        }
    }
    return filters;
}
