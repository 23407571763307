import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { IFetchPageParams } from 'alpha-api-client';
import { RootAction, RootState, Services } from 'alpha-screener-types';

import store from '../../store/index';
import { LIST_SYMBOLS } from './actions';

export const listSymbolsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(LIST_SYMBOLS.request)),
        mergeMap(async (action) => {
            const { simulations } = store.getState().simulations;

            const filters = action.payload.filters || simulations.query.filters;
            const sortBy = action.payload.sortBy || simulations.query.sortBy;

            const params: IFetchPageParams = {
                ...action.payload,
                filters,
                sortBy,
            };

            try {
                const result = await api.apiClient.symbols.listSymbols(params);
                return LIST_SYMBOLS.success({
                    params,
                    result,
                });
            } catch (e) {
                return LIST_SYMBOLS.failure(e.message);
            }
        }),
    );
};
