import { UiSchema } from '@rjsf/utils';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import { IEntityDetailResponse, IEntityListCapabilities, IEntitySchemaDefinition, ISymbol, JSONSchema7Extended, SimulationAction } from 'alpha-api-client';

import { reducePage } from '../common/reducersUtils';
import { IFiltersParams, ISortByParams } from '../common/types';
import { LIST_SYMBOLS } from './actions';

export interface ISymbolsListState {
    schema?: JSONSchema7Extended;
    representation?: UiSchema;
    capabilities: IEntityListCapabilities;
    action?: { name: SimulationAction } & IEntitySchemaDefinition;
    redirect?: { simulationId?: string };
    items: IEntityDetailResponse<ISymbol>[];
    query: {
        filters: IFiltersParams[];
        sortBy: ISortByParams[];
    };
    isLoading: boolean;
}

interface ISymbolsStates {
    list: ISymbolsListState;
}

const initialState: ISymbolsStates = {
    list: {
        items: [],
        query: {
            filters: [],
            sortBy: [{
                field: 'name',
            }],
        },
        capabilities: {
            $canCreate: false,
        },
        isLoading: false,
    },
};

export default combineReducers({
    list: createReducer(initialState.list)
        .handleAction([LIST_SYMBOLS.request],
            (state: ISymbolsListState): ISymbolsListState => {
                return {
                    ...state,
                    isLoading: true,
                };
            })

        .handleAction([LIST_SYMBOLS.failure],
            (state: ISymbolsListState): ISymbolsListState => {
                return {
                    ...state,
                    isLoading: false,
                };
            })

        .handleAction([LIST_SYMBOLS.success],
            (state: ISymbolsListState, action: ActionType<typeof LIST_SYMBOLS.success>): ISymbolsListState => {
                return {
                    ...state,
                    ...reducePage(action.payload.result) as any,
                    query: {
                        filters: action.payload.params.filters || state.query.filters,
                        sortBy: action.payload.params.sortBy || state.query.sortBy,
                    },
                    capabilities: action.payload.result.$capabilities,
                };
            }),
});
