import { AlphaApiClient } from 'alpha-api-client';

import { HttpDownloader } from './HttpDownloader';
import { HttpRequestHelpers } from './HttpRequestHelpers';
import { SseClient } from './SseClient';

export class ClientFactory {

    public static apiClient(): AlphaApiClient {
        return new AlphaApiClient({
            apiUrl: `/api`,
            additionalRequestHeaders: () => HttpRequestHelpers.buildHttpRequestHeaders(),
        });
    }

    public static sseClient(): SseClient {
        return new SseClient(`/sse`);
    }

    public static httpDownloader(): HttpDownloader {
        return new HttpDownloader(`/api`);
    }
}
