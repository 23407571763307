import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { IFetchPageParams } from 'alpha-api-client';
import { RootAction, RootState, Services } from 'alpha-screener-types';

import store from '../../../../store/index';
import {
    ADD_SIMULATION_SYMBOL,
    GET_SIMULATION_SYMBOL,
    GET_SIMULATION_SYMBOL_SCHEMA,
    IMPORT_SIMULATION_CANDLES,
    LIST_SIMULATION_SYMBOLS,
    REMOVE_SIMULATION_SYMBOL,
} from './actions';

export const getSimulationSymbolSchemaEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(GET_SIMULATION_SYMBOL_SCHEMA.request)),
        mergeMap(async (action) => {
            const { simulationId, name } = action.payload;
            try {
                const result = await api.apiClient.simulations.symbols.getSimulationSymbolSchema(simulationId, name);
                return GET_SIMULATION_SYMBOL_SCHEMA.success({ name, simulationId, result });
            } catch (e) {
                return GET_SIMULATION_SYMBOL_SCHEMA.failure(e.message);
            }
        }),
    );
};

export const fetchSymbolsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(LIST_SIMULATION_SYMBOLS.request)),
        mergeMap(async (action) => {
            const symbolsState = store.getState().simulationSymbols;

            const filters = action.payload.filters || symbolsState.list.query.filters;
            const sortBy = action.payload.sortBy || symbolsState.list.query.sortBy;
            const limit = action.payload.limit || symbolsState.list.query.limit;

            const { simulationId, ..._params } = action.payload;
            const params: IFetchPageParams = {
                ..._params,
                filters,
                sortBy,
                limit,
            };

            try {
                const result = await api.apiClient.simulations.symbols.listSimulationSymbols(simulationId, params);
                return LIST_SIMULATION_SYMBOLS.success({
                    params: action.payload,
                    result,
                });
            } catch (e) {
                return LIST_SIMULATION_SYMBOLS.failure(e.message);
            }
        }),
    );
};

export const getSimulationSymbolEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(GET_SIMULATION_SYMBOL.request)),
        mergeMap(async (action) => {
            const { simulationId, symbolId } = action.payload;
            try {
                const result = await api.apiClient.simulations.symbols.getSimulationSymbolDetail(simulationId, symbolId);
                return GET_SIMULATION_SYMBOL.success(result);
            } catch (e) {
                return GET_SIMULATION_SYMBOL.failure(e.message);
            }
        }),
    );
};

export const addSimulationSymbolEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(ADD_SIMULATION_SYMBOL.request)),
        mergeMap(async (action) => {
            const { simulationId, ...params } = action.payload;
            try {
                const result = await api.apiClient.simulations.symbols.addSimulationSymbol(simulationId, params);
                return ADD_SIMULATION_SYMBOL.success({
                    params: action.payload,
                    result,
                });
            } catch (e) {
                return ADD_SIMULATION_SYMBOL.failure(e.message);
            }
        }),
    );
};

export const removeSimulationSymbolEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(REMOVE_SIMULATION_SYMBOL.request)),
        mergeMap(async (action) => {
            const { simulationId, symbolId } = action.payload;
            try {
                const result = await api.apiClient.simulations.symbols.removeSimulationSymbol(simulationId, symbolId);
                return REMOVE_SIMULATION_SYMBOL.success({
                    params: { simulationId, symbolId },
                    result,
                });
            } catch (e) {
                return REMOVE_SIMULATION_SYMBOL.failure(e.message);
            }
        }),
    );
};

export const importSimulationCandlesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(IMPORT_SIMULATION_CANDLES.request)),
        mergeMap(async (action) => {
            const { fromDate, simulationId, resetData } = action.payload;
            try {
                const result = await api.apiClient.simulations.importSimulationCandles(simulationId, undefined, fromDate, resetData);
                return IMPORT_SIMULATION_CANDLES.success({
                    params: { fromDate },
                    result,
                });
            } catch (e) {
                return IMPORT_SIMULATION_CANDLES.failure(e.message);
            }
        }),
    );
};
