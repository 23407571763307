import { UiSchema } from '@rjsf/utils';
import * as _ from 'lodash';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import { IEntityDetailResponse, IEntityListCapabilities, IEntitySchemaDefinition, IWallet, JSONSchema7Extended, WalletAction } from 'alpha-api-client';

import { reducePage } from '../common/reducersUtils';
import { IFiltersParams, ISortByParams } from '../common/types';
import {
    CREATE_WALLET,
    DELETE_WALLET,
    GET_WALLET,
    GET_WALLET_BALANCE,
    GET_WALLET_SCHEMA,
    IGetWalletBalanceResult,
    IGetWalletResult,
    LIST_WALLETS,
    UPDATE_WALLET,
} from './actions';

interface IWalletBalanceState {
    isLoading: boolean;
    data?: IGetWalletBalanceResult;
}

export interface IWalletListState {
    schema?: JSONSchema7Extended;
    representation?: UiSchema;
    capabilities: IEntityListCapabilities;
    action?: { name: WalletAction } & IEntitySchemaDefinition;
    redirect?: { walletId?: string };
    items: IEntityDetailResponse<IWallet>[];
    query: {
        filters: IFiltersParams[];
        sortBy: ISortByParams[];
    };
    isLoading: boolean;
}

export interface IWalletState {
    list: IWalletListState;
    current?: IGetWalletResult;
    balance: IWalletBalanceState;
}

const initialState: IWalletState = {
    list: {
        items: [],
        query: {
            filters: [],
            sortBy: [{
                field: 'name',
            }],
        },
        capabilities: {
            $canCreate: false,
        },
        isLoading: false,
    },
    balance: {
        isLoading: false,
    },
};

export default combineReducers({
    wallets: createReducer(initialState)
        .handleAction([LIST_WALLETS.request],
            (state: IWalletState): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        isLoading: true,
                    },
                };
            })

        .handleAction([LIST_WALLETS.failure],
            (state: IWalletState): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        isLoading: false,
                    },
                };
            })

        .handleAction([LIST_WALLETS.success],
            (state: IWalletState, action: ActionType<typeof LIST_WALLETS.success>): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        ...reducePage(action.payload.result) as any,
                        query: {
                            filters: action.payload.params.filters || state.list.query.filters,
                            sortBy: action.payload.params.sortBy || state.list.query.sortBy,
                        },
                        redirect: undefined,
                        capabilities: action.payload.result.$capabilities,
                    },
                };
            })

        .handleAction([GET_WALLET_SCHEMA.request],
            (state: IWalletState, action: ActionType<typeof GET_WALLET_SCHEMA.request>): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        action: undefined,
                    },
                };
            })

        .handleAction([GET_WALLET_SCHEMA.success],
            (state: IWalletState, action: ActionType<typeof GET_WALLET_SCHEMA.success>): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        action: {
                            name: action.payload.name,
                            $schema: action.payload.result.$schema,
                            $representation: action.payload.result.$representation,
                        },
                    },
                };
            })

        .handleAction([GET_WALLET.request],
            (state: IWalletState): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        isLoading: true,
                    },
                    current: undefined,
                    balance: { isLoading: false },
                };
            })

        .handleAction([GET_WALLET.failure],
            (state: IWalletState): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        isLoading: false,
                    },
                    current: undefined,
                    balance: { isLoading: false },
                };
            })

        .handleAction([GET_WALLET.success],
            (state: IWalletState, action: ActionType<typeof GET_WALLET.success>): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        isLoading: false,
                        redirect: undefined,
                    },
                    current: action.payload,
                    balance: { isLoading: false },
                };
            })

        .handleAction([CREATE_WALLET.request],
            (state: IWalletState): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        isLoading: true,
                    },
                };
            })

        .handleAction([CREATE_WALLET.failure],
            (state: IWalletState): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        isLoading: false,
                    },
                };
            })

        .handleAction([CREATE_WALLET.success],
            (state: IWalletState, action: ActionType<typeof CREATE_WALLET.success>): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        isLoading: false,
                        items: state.list.items.concat(action.payload),
                        redirect: {
                            walletId: action.payload.id,
                        },
                    },
                    current: action.payload,
                    balance: { isLoading: false },
                };
            })

        .handleAction([UPDATE_WALLET.request],
            (state: IWalletState): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        isLoading: true,
                    },
                    current: undefined,
                };
            })

        .handleAction([UPDATE_WALLET.failure],
            (state: IWalletState): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        isLoading: false,
                    },
                    current: undefined,
                };
            })

        .handleAction([UPDATE_WALLET.success],
            (state: IWalletState, action: ActionType<typeof UPDATE_WALLET.success>): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        isLoading: false,
                        items: state.list.items.map((item) => {
                            if (item.id === action.payload.id) {
                                return action.payload;
                            }
                            return item;
                        }),
                    },
                    current: action.payload,
                };
            })

        .handleAction([DELETE_WALLET.request],
            (state: IWalletState): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        isLoading: true,
                    },
                    current: undefined,
                    balance: { isLoading: false },
                };
            })

        .handleAction([DELETE_WALLET.failure],
            (state: IWalletState): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        isLoading: false,
                    },
                    current: undefined,
                    balance: { isLoading: false },
                };
            })

        .handleAction([DELETE_WALLET.success],
            (state: IWalletState, action: ActionType<typeof DELETE_WALLET.success>): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        isLoading: false,
                        items: _.filter(state.list.items, item => item.id !== action.payload.walletId),
                    },
                };
            })

        .handleAction([GET_WALLET_BALANCE.request],
            (state: IWalletState): IWalletState => {
                return {
                    ...state,
                    balance: { isLoading: true },
                };
            })

        .handleAction([GET_WALLET_BALANCE.failure],
            (state: IWalletState): IWalletState => {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        isLoading: false,
                    },
                    balance: { isLoading: false },
                };
            })

        .handleAction([GET_WALLET_BALANCE.success],
            (state: IWalletState, action: ActionType<typeof GET_WALLET_BALANCE.success>): IWalletState => {
                return {
                    ...state,

                    list: {
                        ...state.list,
                        isLoading: false,
                        redirect: undefined,
                    },
                    balance: {
                        data: action.payload,
                        isLoading: false,
                    },
                };
            }),
});
