import { IApiCommonPageResult } from './pagination';

export enum SymbolKind {
    FOREX = 'forex',
    CRYPTO = 'crypto',
    STOCK = 'stock',
    INDICE = 'indice',
}

export enum SymbolOrigin {
    BINANCE = 'binance',
    BYBIT = 'bybit',
    BITGET = 'bitget',
    FCSAPI = 'fcsapi',
}

export interface ISymbol {
    id: string;
    name: string;
    description: string;
    icon?: string;
}

export interface ISymbolUpdate {
    name: string;
    description: string;
    icon?: string;
}

export interface ISymbolFull extends ISymbol {
    kind: SymbolKind;
    origin: SymbolOrigin;
    country?: string;
    category?: string;
    isin?: string;
    ticker?: string;
    ref?: string;
}

export type ISymbolPageApiResult = IApiCommonPageResult<ISymbol>;
