import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { IFetchPageParams } from 'alpha-api-client';
import { RootAction, RootState, Services } from 'alpha-screener-types';

import store from '../../store/index';
import { CREATE_SIMULATION, DELETE_SIMULATION, GET_SIMULATION, GET_SIMULATION_SCHEMA, LIST_SIMULATIONS, UPDATE_SIMULATION } from './actions';

export const getSimulationSchemaEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(GET_SIMULATION_SCHEMA.request)),
        mergeMap(async (action) => {
            const { name } = action.payload;
            try {
                const result = await api.apiClient.simulations.getSimulationSchema(name);
                return GET_SIMULATION_SCHEMA.success({ name, result });
            } catch (e) {
                return GET_SIMULATION_SCHEMA.failure(e.message);
            }
        }),
    );
};

export const getSimulationEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(GET_SIMULATION.request)),
        mergeMap(async (action) => {
            const simulationId = action.payload.simulationId;
            try {
                const result = await api.apiClient.simulations.getSimulation(simulationId);
                return GET_SIMULATION.success(result);
            } catch (e) {
                return GET_SIMULATION.failure(e.message);
            }
        }),
    );
};

export const listSimulationsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(LIST_SIMULATIONS.request)),
        mergeMap(async (action) => {
            const { simulations } = store.getState().simulations;

            const filters = action.payload.filters || simulations.query.filters;
            const sortBy = action.payload.sortBy || simulations.query.sortBy;

            const params: IFetchPageParams = {
                ...action.payload,
                filters,
                sortBy,
            };

            try {
                const result = await api.apiClient.simulations.listSimulations(params);
                return LIST_SIMULATIONS.success({
                    params,
                    result,
                });
            } catch (e) {
                return LIST_SIMULATIONS.failure(e.message);
            }
        }),
    );
};

export const createSimulationEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(CREATE_SIMULATION.request)),
        mergeMap(async (action) => {
            try {
                const result = await api.apiClient.simulations.createSimulation(action.payload);
                return CREATE_SIMULATION.success(result);
            } catch (e) {
                return CREATE_SIMULATION.failure(e.message);
            }
        }),
    );
};

export const updateSimulationEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(UPDATE_SIMULATION.request)),
        mergeMap(async (action) => {
            try {
                const { simulationId, ...params } = action.payload;
                const result = await api.apiClient.simulations.updateSimulation(simulationId, params);
                return UPDATE_SIMULATION.success(result);
            } catch (e) {
                return UPDATE_SIMULATION.failure(e.message);
            }
        }),
    );
};

export const deleteSimulationEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(DELETE_SIMULATION.request)),
        mergeMap(async (action) => {
            const { simulationId } = action.payload;
            try {
                await api.apiClient.simulations.deleteSimulation(simulationId);
                return DELETE_SIMULATION.success({ simulationId });
            } catch (e) {
                return DELETE_SIMULATION.failure(e.message);
            }
        }),
    );
};
