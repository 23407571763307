import { IApiCommonPageResult } from './pagination';

export enum OrderStatus {
    OPENED = 'opened',
    CLOSED = 'closed',
}

export interface IWalletOrder {
    id: string;
    simulationId: string;
    symbolId: string;
    symbolName: string;
    direction: 'long' | 'short';
    event: string;
    quantity: number;
    leverage: number;
    openDate: Date;
    openQuote: number;
    stopLoss: number;
    stopIndex?: number;
    stopProfit?: number;
    expirationDate: Date;
    closeDate?: Date;
    closeQuote?: number;
    updateId?: string;
    updateDate?: Date;
    positionId?: string;
    lastCandleClose?: number;
    // computed fields
    margin: number;
    fee: number;
    gain: number;
    roe: number;
    tmpGain?: number;
}

export type IOrdersPageApiResult = IApiCommonPageResult<IWalletOrder>;
