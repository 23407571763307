import { combineEpics } from 'redux-observable';

import * as globalEpics from '../content/global/epics';
import * as aggregatesEpics from '../content/simulations/components/aggregates/epics';
import * as candlesEpics from '../content/simulations/components/candles/epics';
import * as optimizationsEpics from '../content/simulations/components/optimizations/epics';
import * as positionsEpics from '../content/simulations/components/positions/epics';
import * as simulaationsSummariesEpics from '../content/simulations/components/summaries/epics';
import * as simulationsSymbolsEpics from '../content/simulations/components/symbols/epics';
import * as simulationsEpics from '../content/simulations/epics';
import * as symbolsEpics from '../content/symbols/epics';
import * as ordersEpics from '../content/wallets/components/orders/epics';
import * as walletsSummariesEpics from '../content/wallets/components/summaries/epics';
import * as walletsEpics from '../content/wallets/epics';

export default combineEpics(
    ...Object.values(globalEpics),
    ...Object.values(symbolsEpics),
    ...Object.values(candlesEpics),
    ...Object.values(aggregatesEpics),
    ...Object.values(simulationsEpics),
    ...Object.values(simulationsSymbolsEpics),
    ...Object.values(simulaationsSummariesEpics),
    ...Object.values(positionsEpics),
    ...Object.values(optimizationsEpics),
    ...Object.values(walletsEpics),
    ...Object.values(ordersEpics),
    ...Object.values(walletsSummariesEpics),
);
