import * as _ from 'lodash';
import { combineReducers } from 'redux';
import { ISummaryTrendState } from 'src/components/Summaries/SummaryTrendsTable';
import { ActionType, createReducer } from 'typesafe-actions';

import { INamedItemSummary, ISymbolSummary } from 'alpha-api-client';

import { ISummaryState } from '../../../../components/Summaries/SummaryContainer';
import { SET_GLOBAL_DATES_RANGE } from '../../../global/actions';
import { GET_SIMULATION, SET_INITIAL_CAPITAL, SET_MAX_LEVERAGE, SET_NOMINAL_INVEST, SET_RISK_FACTOR } from '../../actions';
import { SELECT_OPTIMIZATION } from '../optimizations/actions';
import { FETCH_SIMULATION_SUMMARY } from './actions';

export interface ISimulationSummaryItemState extends INamedItemSummary {
    id: string;
}

export interface ISimulationSummarySymbolState extends ISimulationSummaryItemState {
    icon?: string;
}

export interface ISimulationSummaryState extends ISummaryState {
    simulationId?: string;
    isLoading: boolean;
}

interface ISummariesState {
    state: ISimulationSummaryState;
}

const initialState: ISummariesState = {
    state: {
        content: null,
        isLoading: false,
        symbols: [],
        events: [],
        trends: [],
    },
};

function mapItemSummary(symbolId: string, item: INamedItemSummary): ISimulationSummaryItemState {
    return {
        id: symbolId,
        ...item,
    };
}

function mapTrendSummary(symbolId: string, item: INamedItemSummary): ISummaryTrendState {
    return {
        id: symbolId,
        name: item.name,
        margin: item.margin,
        profit: item.profit,
        l_balance: item.balance.long,
        l_success: item.success.long,
        l_wins: item.wins.long,
        l_loses: item.loses.long,
        l_draws: item.draws.long,
        s_balance: item.balance.short,
        s_success: item.success.short,
        s_wins: item.wins.short,
        s_loses: item.loses.short,
        s_draws: item.draws.short,
    };
}

const computeSummarySymbolsToDisplay = (items: { [item: string]: ISymbolSummary }): ISimulationSummarySymbolState[] => {
    const result = _.reduce(items, (acc, item, symbolId) => {
        acc.push({ ...mapItemSummary(symbolId, item), icon: item.icon });
        return acc;
    }, [] as ISimulationSummarySymbolState[]);
    return _.sortBy(result, s => s.name);
};

const computeSummaryItemsToDisplay = (items: { [item: string]: INamedItemSummary }): ISimulationSummaryItemState[] => {
    const result = _.reduce(items, (acc, item, symbolId) => {
        acc.push(mapItemSummary(symbolId, item));
        return acc;
    }, [] as ISimulationSummaryItemState[]);
    return _.sortBy(result, s => s.name);
};

const computeSummaryTrendsToDisplay = (items: { [item: string]: INamedItemSummary }): ISummaryTrendState[] => {
    const result = _.reduce(items, (acc, item, symbolId) => {
        acc.push(mapTrendSummary(symbolId, item));
        return acc;
    }, [] as ISummaryTrendState[]);
    return _.sortBy(result, s => +s.name);
};

export const summariesReducer = combineReducers({

    state: createReducer(initialState.state)

        .handleAction([FETCH_SIMULATION_SUMMARY.request],
            (state: ISimulationSummaryState, action: ActionType<typeof FETCH_SIMULATION_SUMMARY.request>): ISimulationSummaryState => {
                return {
                    simulationId: action.payload.simulationId,
                    content: null,
                    symbols: [],
                    events: [],
                    trends: [],
                    isLoading: true,
                };
            })

        .handleAction([FETCH_SIMULATION_SUMMARY.failure],
            (state: ISimulationSummaryState): ISimulationSummaryState => {
                return {
                    ...state,
                    isLoading: false,
                };
            })

        .handleAction([FETCH_SIMULATION_SUMMARY.success],
            (state: ISimulationSummaryState, action: ActionType<typeof FETCH_SIMULATION_SUMMARY.success>): ISimulationSummaryState => {
                return {
                    ...state,
                    content: action.payload.result,
                    symbols: computeSummarySymbolsToDisplay(action.payload.result.symbols),
                    events: computeSummaryItemsToDisplay(action.payload.result.events),
                    trends: computeSummaryTrendsToDisplay(action.payload.result.trends),
                    isLoading: false,
                };
            })

        .handleAction([GET_SIMULATION.request],
            (state: ISimulationSummaryState, action: ActionType<typeof GET_SIMULATION.request>): ISimulationSummaryState => {
                return {
                    ...initialState.state,
                };
            })

        .handleAction([SELECT_OPTIMIZATION],
            (state: ISimulationSummaryState, action: ActionType<typeof SELECT_OPTIMIZATION>): ISimulationSummaryState => {
                return {
                    ...state,
                    content: null,
                    symbols: [],
                    events: [],
                    trends: [],
                };
            })

        .handleAction([SET_NOMINAL_INVEST],
            (state: ISimulationSummaryState, action: ActionType<typeof SELECT_OPTIMIZATION>): ISimulationSummaryState => {
                return {
                    ...state,
                    content: null,
                    symbols: [],
                    events: [],
                };
            })

        .handleAction([SET_INITIAL_CAPITAL],
            (state: ISimulationSummaryState, action: ActionType<typeof SET_INITIAL_CAPITAL>): ISimulationSummaryState => {
                return {
                    ...state,
                    content: null,
                    symbols: [],
                    events: [],
                    trends: [],
                };
            })

        .handleAction([SET_RISK_FACTOR],
            (state: ISimulationSummaryState, action: ActionType<typeof SET_RISK_FACTOR>): ISimulationSummaryState => {
                return {
                    ...state,
                    content: null,
                    symbols: [],
                    events: [],
                    trends: [],
                };
            })

        .handleAction([SET_MAX_LEVERAGE],
            (state: ISimulationSummaryState, action: ActionType<typeof SET_MAX_LEVERAGE>): ISimulationSummaryState => {
                return {
                    ...state,
                    content: null,
                    symbols: [],
                    events: [],
                    trends: [],
                };
            })

        .handleAction([SET_GLOBAL_DATES_RANGE],
            (state: ISimulationSummaryState): ISimulationSummaryState => {
                return {
                    ...state,
                    content: null,
                    symbols: [],
                    events: [],
                    trends: [],
                };
            }),

});

export default summariesReducer;
