import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import { CandleInterval, ICandle } from 'alpha-api-client';

import { reducePage } from '../../../common/reducersUtils';
import { IFiltersParams, ISortByParams } from '../../../common/types';
import { FETCH_CHART_CANDLES, FETCH_LIST_CANDLES, MORE_LIST_CANDLES, SELECT_CANDLES_CHART_INTERVAL } from './actions';

export interface ICandlesState {
    symbolId?: string;
    interval: CandleInterval;
    items: ICandle[];
    page: number | null;
    hasNext: boolean;
    totalCount: number;
    query: {
        limit: string;
        filters: IFiltersParams[];
        sortBy: ISortByParams[];
    };
    isLoading: boolean;
}

interface ICandlesStates {
    list: ICandlesState;
    chart: ICandlesState;
}

const initialState: ICandlesStates = {
    list: {
        interval: CandleInterval.ONE_HOUR,
        items: [],
        page: null,
        hasNext: true,
        totalCount: 0,
        query: {
            limit: '300',
            filters: [],
            sortBy: [{
                field: 'date',
                direction: 'desc',
            }],
        },
        isLoading: false,
    },
    chart: {
        interval: CandleInterval.ONE_HOUR,
        items: [],
        page: null,
        hasNext: true,
        totalCount: 0,
        query: {
            limit: '100000',
            filters: [],
            sortBy: [{
                field: 'date',
                direction: 'asc',
            }],
        },
        isLoading: false,
    },
};

const candlesReducer = combineReducers({
    list: createReducer(initialState.list)

        .handleAction([FETCH_LIST_CANDLES.request],
            (state: ICandlesState, action: ActionType<typeof FETCH_LIST_CANDLES.request>): ICandlesState => {
                if (state.symbolId != null && state.symbolId !== action.payload.symbolId) {
                    return {
                        ...initialState.list,
                        symbolId: action.payload.symbolId,
                        isLoading: true,
                    };
                }
                return {
                    ...state,
                    isLoading: true,
                };
            })

        .handleAction([MORE_LIST_CANDLES.request],
            (state: ICandlesState): ICandlesState => {
                return {
                    ...state,
                    isLoading: true,
                };
            })

        .handleAction([FETCH_LIST_CANDLES.failure, MORE_LIST_CANDLES.failure],
            (state: ICandlesState): ICandlesState => {
                return {
                    ...state,
                    isLoading: false,
                };
            })

        .handleAction([FETCH_LIST_CANDLES.success],
            (state: ICandlesState, action: ActionType<typeof FETCH_LIST_CANDLES.success>): ICandlesState => {
                const symbolChanged = action.payload.params.symbolId !== state.symbolId;
                return {
                    ...(symbolChanged ? initialState.list : state),
                    ...reducePage(action.payload.result),
                    query: {
                        limit: action.payload.params.limit || state.query.limit,
                        filters: action.payload.params.filters || state.query.filters,
                        sortBy: action.payload.params.sortBy || state.query.sortBy,
                    },
                    symbolId: action.payload.params.symbolId,
                    isLoading: false,
                };
            })

        .handleAction([MORE_LIST_CANDLES.success],
            (state: ICandlesState, action: ActionType<typeof MORE_LIST_CANDLES.success>): ICandlesState => {
                return {
                    ...state,
                    ...reducePage(action.payload.result, state.items, action.payload.params.reverse),
                    isLoading: false,
                };
            }),
    chart: createReducer(initialState.chart)

        .handleAction([SELECT_CANDLES_CHART_INTERVAL],
            (state: ICandlesState, action: ActionType<typeof SELECT_CANDLES_CHART_INTERVAL>): ICandlesState => {
                return {
                    ...state,
                    interval: action.payload,
                };
            })

        .handleAction([FETCH_CHART_CANDLES.request],
            (state: ICandlesState, action: ActionType<typeof FETCH_CHART_CANDLES.request>): ICandlesState => {
                if (state.symbolId != null && state.symbolId !== action.payload.symbolId) {
                    return {
                        ...initialState.chart,
                        symbolId: action.payload.symbolId,
                        isLoading: true,
                    };
                }
                return {
                    ...state,
                    isLoading: true,
                };
            })

        .handleAction([FETCH_CHART_CANDLES.failure],
            (state: ICandlesState): ICandlesState => {
                return {
                    ...state,
                    isLoading: false,
                };
            })

        .handleAction([FETCH_CHART_CANDLES.success],
            (state: ICandlesState, action: ActionType<typeof FETCH_CHART_CANDLES.success>): ICandlesState => {
                const symbolChanged = action.payload.params.symbolId !== state.symbolId;
                return {
                    ...(symbolChanged ? initialState.chart : state),
                    ...reducePage(action.payload.result),
                    query: {
                        limit: action.payload.params.limit || state.query.limit,
                        filters: action.payload.params.filters || state.query.filters,
                        sortBy: action.payload.params.sortBy || state.query.sortBy,
                    },
                    symbolId: action.payload.params.symbolId,
                    isLoading: false,
                };
            }),
});

export default candlesReducer;
