import * as _ from 'lodash';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import { INamedItemSummary, ISymbolSummary } from 'alpha-api-client';

import { ISummaryState } from '../../../../components/Summaries/SummaryContainer';
import { SET_GLOBAL_DATES_RANGE } from '../../../global/actions';
import { GET_WALLET } from '../../actions';
import { FETCH_WALLET_SUMMARY } from './actions';

export interface IWalletSummaryItemState extends INamedItemSummary {
    id: string;
}

export interface IWalletSummarySymbolState extends IWalletSummaryItemState {
    icon?: string;
}

interface IWalletSummaryState extends ISummaryState {
    walletId?: string;
    isLoading: boolean;
    initialCapital: number;
}

interface ISummariesState {
    state: IWalletSummaryState;
}

const initialState: ISummariesState = {
    state: {
        content: null,
        isLoading: false,
        symbols: [],
        trends: [],
        events: [],
        initialCapital: 0,
    },
};

function mapItemSummary(symbolId: string, item: INamedItemSummary) {
    return {
        id: symbolId,
        ...item,
    };
}

const computeSummarySymbolsToDisplay = (items: { [item: string]: ISymbolSummary }): IWalletSummarySymbolState[] => {
    const result = _.reduce(items, (acc, item, symbolId) => {
        acc.push({ ...mapItemSummary(symbolId, item), icon: item.icon });
        return acc;
    }, [] as IWalletSummarySymbolState[]);
    return _.sortBy(result, s => s.name);
};

export const summariesReducer = combineReducers({

    state: createReducer(initialState.state)

        .handleAction([FETCH_WALLET_SUMMARY.request],
            (state: IWalletSummaryState, action: ActionType<typeof FETCH_WALLET_SUMMARY.request>): IWalletSummaryState => {
                return {
                    walletId: action.payload.walletId,
                    content: null,
                    symbols: [],
                    events: [],
                    trends: [],
                    isLoading: true,
                    initialCapital: 0,
                };
            })

        .handleAction([FETCH_WALLET_SUMMARY.failure],
            (state: IWalletSummaryState): IWalletSummaryState => {
                return {
                    ...state,
                    isLoading: false,
                };
            })

        .handleAction([FETCH_WALLET_SUMMARY.success],
            (state: IWalletSummaryState, action: ActionType<typeof FETCH_WALLET_SUMMARY.success>): IWalletSummaryState => {
                return {
                    ...state,
                    content: action.payload.result,
                    symbols: computeSummarySymbolsToDisplay(action.payload.result.symbols),
                    isLoading: false,
                    initialCapital: action.payload.result.initialCapital,
                };
            })

        .handleAction([GET_WALLET.request],
            (state: IWalletSummaryState, action: ActionType<typeof GET_WALLET.request>): IWalletSummaryState => {
                return {
                    ...initialState.state,
                };
            })

        .handleAction([SET_GLOBAL_DATES_RANGE],
            (state: IWalletSummaryState): IWalletSummaryState => {
                return {
                    ...state,
                    content: null,
                    symbols: [],
                };
            }),
});

export default summariesReducer;
