import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import { IWalletOrder } from 'alpha-api-client';

import { reducePage } from '../../../common/reducersUtils';
import { IFiltersParams, ISortByParams } from '../../../common/types';
import { SET_GLOBAL_DATES_RANGE } from '../../../global/actions';
import { GET_WALLET } from '../../actions';
import {
    CLOSE_WALLET_ORDER,
    FETCH_WALLET_CLOSED_ORDERS,
    FETCH_WALLET_OPENED_ORDERS,
    FETCH_WALLET_SYMBOL_CLOSED_ORDERS,
    FETCH_WALLET_SYMBOL_OPENED_ORDERS,
    MORE_WALLET_CLOSED_ORDERS,
    MORE_WALLET_OPENED_ORDERS,
    MORE_WALLET_SYMBOL_CLOSED_ORDERS,
    MORE_WALLET_SYMBOL_OPENED_ORDERS,
} from './actions';

export interface IOrdersStateQuery {
    limit: string;
    filters: IFiltersParams[];
    sortBy: ISortByParams[];
    query?: string;
}

interface IWalletOrdersState {
    items: IWalletOrder[];
    page: number | null;
    hasNext: boolean;
    totalCount: number;
    query: IOrdersStateQuery;
    isLoading: boolean;
}

interface IWalletOrdersStates {
    walletId?: string;
    opened: IWalletOrdersState;
    closed: IWalletOrdersState;
}

interface IWalletSymbolOrdersStates extends IWalletOrdersStates {
    symbolId?: string;
}

interface IOrdersStates {
    wallet: IWalletOrdersStates;
    symbol: IWalletSymbolOrdersStates;
}

const defaultOrderState: () => IWalletOrdersState = () => ({
    items: [],
    page: null,
    hasNext: false,
    totalCount: 0,
    query: {
        limit: '300',
        filters: [],
        sortBy: [{
            field: 'opendate',
            direction: 'desc',
        }],
        query: '',
    },
    isLoading: false,
});

const initialState: IOrdersStates = {
    wallet: {
        opened: defaultOrderState(),
        closed: defaultOrderState(),
    },
    symbol: {
        opened: defaultOrderState(),
        closed: defaultOrderState(),
    },
};

const candlesReducer = combineReducers({
    wallet: createReducer(initialState.wallet)

        .handleAction([FETCH_WALLET_OPENED_ORDERS.request],
            (state: IWalletOrdersStates, action: ActionType<typeof FETCH_WALLET_OPENED_ORDERS.request>): IWalletOrdersStates => {
                if (state.walletId != null && state.walletId !== action.payload.walletId) {
                    return {
                        ...initialState.wallet,
                        walletId: action.payload.walletId,
                        opened: {
                            ...initialState.wallet.opened,
                            isLoading: true,
                        },
                    };
                }
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        isLoading: true,
                    },
                };
            })

        .handleAction([MORE_WALLET_OPENED_ORDERS.request],
            (state: IWalletOrdersStates): IWalletOrdersStates => {
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        isLoading: true,
                    },
                };
            })

        .handleAction([FETCH_WALLET_OPENED_ORDERS.failure, MORE_WALLET_OPENED_ORDERS.failure],
            (state: IWalletOrdersStates): IWalletOrdersStates => {
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        isLoading: false,
                    },
                };
            })

        .handleAction([FETCH_WALLET_OPENED_ORDERS.success],
            (state: IWalletOrdersStates, action: ActionType<typeof FETCH_WALLET_OPENED_ORDERS.success>): IWalletOrdersStates => {
                const walletChanged = action.payload.walletId !== state.walletId;
                return {
                    walletId: action.payload.walletId,
                    ...(walletChanged ? initialState.wallet : state),
                    ...state,
                    opened: {
                        ...state.opened,
                        ...reducePage(action.payload.result),
                        query: {
                            limit: action.payload.params.limit || state.opened.query.limit,
                            filters: action.payload.params.filters || state.opened.query.filters,
                            sortBy: action.payload.params.sortBy || state.opened.query.sortBy,
                            query: action.payload.params.query || state.opened.query.query,
                        },
                        isLoading: false,
                    },
                };
            })

        .handleAction([MORE_WALLET_OPENED_ORDERS.success],
            (state: IWalletOrdersStates, action: ActionType<typeof MORE_WALLET_OPENED_ORDERS.success>): IWalletOrdersStates => {
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        ...reducePage(action.payload.result, state.opened.items, action.payload.params.reverse),
                        isLoading: false,
                    },
                };
            })

        .handleAction([FETCH_WALLET_CLOSED_ORDERS.request],
            (state: IWalletOrdersStates, action: ActionType<typeof FETCH_WALLET_CLOSED_ORDERS.request>): IWalletOrdersStates => {
                if (state.walletId != null && state.walletId !== action.payload.walletId) {
                    return {
                        ...initialState.wallet,
                        walletId: action.payload.walletId,
                        closed: {
                            ...initialState.wallet.closed,
                            isLoading: true,
                        },
                    };
                }
                return {
                    ...state,
                    closed: {
                        ...state.closed,
                        isLoading: true,
                    },
                };
            })

        .handleAction([MORE_WALLET_CLOSED_ORDERS.request],
            (state: IWalletOrdersStates): IWalletOrdersStates => {
                return {
                    ...state,
                    closed: {
                        ...state.closed,
                        isLoading: true,
                    },
                };
            })

        .handleAction([FETCH_WALLET_CLOSED_ORDERS.failure, FETCH_WALLET_CLOSED_ORDERS.failure],
            (state: IWalletOrdersStates): IWalletOrdersStates => {
                return {
                    ...state,
                    closed: {
                        ...state.closed,
                        isLoading: false,
                    },
                };
            })

        .handleAction([FETCH_WALLET_CLOSED_ORDERS.success],
            (state: IWalletOrdersStates, action: ActionType<typeof FETCH_WALLET_CLOSED_ORDERS.success>): IWalletOrdersStates => {
                const walletChanged = action.payload.walletId !== state.walletId;
                return {
                    walletId: action.payload.walletId,
                    ...(walletChanged ? initialState.wallet : state),
                    ...state,
                    closed: {
                        ...state.closed,
                        ...reducePage(action.payload.result),
                        query: {
                            limit: action.payload.params.limit || state.closed.query.limit,
                            filters: action.payload.params.filters || state.closed.query.filters,
                            sortBy: action.payload.params.sortBy || state.closed.query.sortBy,
                            query: action.payload.params.query || state.closed.query.query,
                        },
                        isLoading: false,
                    },
                };
            })

        .handleAction([MORE_WALLET_CLOSED_ORDERS.success],
            (state: IWalletOrdersStates, action: ActionType<typeof MORE_WALLET_CLOSED_ORDERS.success>): IWalletOrdersStates => {
                return {
                    ...state,
                    closed: {
                        ...state.closed,
                        ...reducePage(action.payload.result, state.closed.items, action.payload.params.reverse),
                        isLoading: false,
                    },
                };
            })

        .handleAction([CLOSE_WALLET_ORDER.request],
            (state: IWalletOrdersStates): IWalletOrdersStates => {
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        isLoading: true,
                    },
                    closed: {
                        ...state.closed,
                        isLoading: true,
                    },
                };
            })

        .handleAction([CLOSE_WALLET_ORDER.failure],
            (state: IWalletOrdersStates): IWalletOrdersStates => {
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        isLoading: false,
                    },
                    closed: {
                        ...state.closed,
                        isLoading: false,
                    },
                };
            })

        .handleAction([CLOSE_WALLET_ORDER.success],
            (state: IWalletOrdersStates, action: ActionType<typeof CLOSE_WALLET_ORDER.success>): IWalletOrdersStates => {
                return {
                    ...state,
                    opened: initialState.wallet.opened,
                    closed: initialState.wallet.closed,
                };
            })

        .handleAction([GET_WALLET.request],
            (state: IWalletOrdersStates, action: ActionType<typeof GET_WALLET.request>): IWalletOrdersStates => {
                return {
                    ...initialState.wallet,
                };
            })

        .handleAction([SET_GLOBAL_DATES_RANGE],
            (state: IWalletOrdersStates, action: ActionType<typeof SET_GLOBAL_DATES_RANGE>): IWalletOrdersStates => {
                return {
                    ...state,
                    opened: initialState.wallet.opened,
                    closed: initialState.wallet.closed,
                };
            }),

    symbol: createReducer(initialState.symbol)

        .handleAction([FETCH_WALLET_SYMBOL_OPENED_ORDERS.request],
            (state: IWalletSymbolOrdersStates, action: ActionType<typeof FETCH_WALLET_SYMBOL_OPENED_ORDERS.request>): IWalletSymbolOrdersStates => {
                if (
                    state.walletId != null && state.walletId !== action.payload.walletId ||
                    state.symbolId != null && state.symbolId !== action.payload.symbolId
                ) {
                    return {
                        ...initialState.symbol,
                        walletId: action.payload.walletId,
                        symbolId: action.payload.symbolId,
                        opened: {
                            ...initialState.symbol.opened,
                            isLoading: true,
                        },
                    };
                }
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        isLoading: true,
                    },
                };
            })

        .handleAction([MORE_WALLET_SYMBOL_OPENED_ORDERS.request],
            (state: IWalletSymbolOrdersStates): IWalletSymbolOrdersStates => {
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        isLoading: true,
                    },
                };
            })

        .handleAction([FETCH_WALLET_SYMBOL_OPENED_ORDERS.failure, MORE_WALLET_SYMBOL_OPENED_ORDERS.failure],
            (state: IWalletSymbolOrdersStates): IWalletSymbolOrdersStates => {
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        isLoading: false,
                    },
                };
            })

        .handleAction([FETCH_WALLET_SYMBOL_OPENED_ORDERS.success],
            (state: IWalletSymbolOrdersStates, action: ActionType<typeof FETCH_WALLET_SYMBOL_OPENED_ORDERS.success>): IWalletSymbolOrdersStates => {
                const walletChanged = action.payload.walletId !== state.walletId || action.payload.symbolId !== state.symbolId;
                return {
                    walletId: action.payload.walletId,
                    symbolId: action.payload.symbolId,
                    ...(walletChanged ? initialState.symbol : state),
                    ...state,
                    opened: {
                        ...state.opened,
                        ...reducePage(action.payload.result),
                        query: {
                            limit: action.payload.params.limit || state.opened.query.limit,
                            filters: action.payload.params.filters || state.opened.query.filters,
                            sortBy: action.payload.params.sortBy || state.opened.query.sortBy,
                            query: action.payload.params.query || state.opened.query.query,
                        },
                        isLoading: false,
                    },
                };
            })

        .handleAction([MORE_WALLET_SYMBOL_OPENED_ORDERS.success],
            (state: IWalletSymbolOrdersStates, action: ActionType<typeof MORE_WALLET_SYMBOL_OPENED_ORDERS.success>): IWalletSymbolOrdersStates => {
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        ...reducePage(action.payload.result, state.opened.items, action.payload.params.reverse),
                        isLoading: false,
                    },
                };
            })

        .handleAction([FETCH_WALLET_SYMBOL_CLOSED_ORDERS.request],
            (state: IWalletSymbolOrdersStates, action: ActionType<typeof FETCH_WALLET_SYMBOL_CLOSED_ORDERS.request>): IWalletSymbolOrdersStates => {
                if (
                    state.walletId != null && state.walletId !== action.payload.walletId ||
                    state.symbolId != null && state.symbolId !== action.payload.symbolId
                ) {
                    return {
                        ...initialState.symbol,
                        walletId: action.payload.walletId,
                        symbolId: action.payload.symbolId,
                        closed: {
                            ...initialState.symbol.closed,
                            isLoading: true,
                        },
                    };
                }
                return {
                    ...state,
                    closed: {
                        ...state.closed,
                        isLoading: true,
                    },
                };
            })

        .handleAction([MORE_WALLET_SYMBOL_CLOSED_ORDERS.request],
            (state: IWalletSymbolOrdersStates): IWalletSymbolOrdersStates => {
                return {
                    ...state,
                    closed: {
                        ...state.closed,
                        isLoading: true,
                    },
                };
            })

        .handleAction([FETCH_WALLET_SYMBOL_CLOSED_ORDERS.failure, FETCH_WALLET_SYMBOL_CLOSED_ORDERS.failure],
            (state: IWalletSymbolOrdersStates): IWalletSymbolOrdersStates => {
                return {
                    ...state,
                    closed: {
                        ...state.closed,
                        isLoading: false,
                    },
                };
            })

        .handleAction([FETCH_WALLET_SYMBOL_CLOSED_ORDERS.success],
            (state: IWalletSymbolOrdersStates, action: ActionType<typeof FETCH_WALLET_SYMBOL_CLOSED_ORDERS.success>): IWalletSymbolOrdersStates => {
                const walletChanged = action.payload.walletId !== state.walletId || action.payload.walletId !== state.walletId;
                return {
                    walletId: action.payload.walletId,
                    symbolId: action.payload.symbolId,
                    ...(walletChanged ? initialState.symbol : state),
                    ...state,
                    closed: {
                        ...state.closed,
                        ...reducePage(action.payload.result),
                        query: {
                            limit: action.payload.params.limit || state.closed.query.limit,
                            filters: action.payload.params.filters || state.closed.query.filters,
                            sortBy: action.payload.params.sortBy || state.closed.query.sortBy,
                            query: action.payload.params.query || state.closed.query.query,
                        },
                        isLoading: false,
                    },
                };
            })

        .handleAction([MORE_WALLET_SYMBOL_CLOSED_ORDERS.success],
            (state: IWalletSymbolOrdersStates, action: ActionType<typeof MORE_WALLET_SYMBOL_CLOSED_ORDERS.success>): IWalletSymbolOrdersStates => {
                return {
                    ...state,
                    closed: {
                        ...state.closed,
                        ...reducePage(action.payload.result, state.closed.items, action.payload.params.reverse),
                        isLoading: false,
                    },
                };
            })

        .handleAction([CLOSE_WALLET_ORDER.request],
            (state: IWalletSymbolOrdersStates): IWalletSymbolOrdersStates => {
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        isLoading: true,
                    },
                    closed: {
                        ...state.closed,
                        isLoading: true,
                    },
                };
            })

        .handleAction([CLOSE_WALLET_ORDER.failure],
            (state: IWalletSymbolOrdersStates): IWalletSymbolOrdersStates => {
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        isLoading: false,
                    },
                    closed: {
                        ...state.closed,
                        isLoading: false,
                    },
                };
            })

        .handleAction([CLOSE_WALLET_ORDER.success],
            (state: IWalletSymbolOrdersStates, action: ActionType<typeof CLOSE_WALLET_ORDER.success>): IWalletSymbolOrdersStates => {
                return {
                    ...state,
                    opened: initialState.symbol.opened,
                    closed: initialState.symbol.closed,
                };
            })

        .handleAction([GET_WALLET.request],
            (state: IWalletSymbolOrdersStates, action: ActionType<typeof GET_WALLET.request>): IWalletSymbolOrdersStates => {
                return {
                    ...initialState.symbol,
                };
            })

        .handleAction([SET_GLOBAL_DATES_RANGE],
            (state: IWalletSymbolOrdersStates, action: ActionType<typeof SET_GLOBAL_DATES_RANGE>): IWalletSymbolOrdersStates => {
                return {
                    ...state,
                    opened: initialState.symbol.opened,
                    closed: initialState.symbol.closed,
                };
            }),
});

export default candlesReducer;
