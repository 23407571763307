import * as _ from 'lodash';
import qs from 'qs';
import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { OrderStatus } from 'alpha-api-client';
import { RootAction, RootState, Services } from 'alpha-screener-types';

import store from '../../../../store/index';
import { computeFiltersWithDateRange } from '../../../common/epicsUtils';
import { IFetchParams } from '../../../common/types';
import {
    CLOSE_WALLET_ORDER,
    FETCH_WALLET_CLOSED_ORDERS,
    FETCH_WALLET_OPENED_ORDERS,
    FETCH_WALLET_SYMBOL_CLOSED_ORDERS,
    FETCH_WALLET_SYMBOL_OPENED_ORDERS,
    IFetchWalletOrdersParams,
    IFetchWalletSymbolOrdersParams,
    MORE_WALLET_CLOSED_ORDERS,
    MORE_WALLET_OPENED_ORDERS,
    MORE_WALLET_SYMBOL_CLOSED_ORDERS,
    MORE_WALLET_SYMBOL_OPENED_ORDERS,
} from './actions';
import { IOrdersStateQuery } from './reducer';

function computeFetchWalletOrdersParams(_params: IFetchWalletOrdersParams, stateQuery: IOrdersStateQuery, status?: OrderStatus) {
    const walletId = _params.walletId;
    const startDate = _params.startDate;
    const endDate = _params.endDate;
    const filters = computeFiltersWithDateRange('opendate', _params.filters || stateQuery.filters, startDate, endDate);

    const sortBy = _params.sortBy || stateQuery.sortBy;
    const limit = _params.limit || stateQuery.limit;
    const query = _params.query || qs.stringify({ status });
    const params: IFetchParams = {
        page: _params.page,
        includes: _params.includes,
        reverse: _params.reverse,
        sortAscending: _params.sortAscending,
        next: _params.next,
        previous: _params.previous,
        query,
        filters,
        sortBy,
        limit,
    };
    return { walletId, params };
}

function computeFetchWalletSymbolOrdersParams(_params: IFetchWalletSymbolOrdersParams,
                                              stateQuery: IOrdersStateQuery,
                                              status?: OrderStatus) {
    const { walletId, params } = computeFetchWalletOrdersParams(_params, stateQuery, status);
    const symbolId = _params.symbolId;
    _.remove(params.filters, f => f.field === 'symbolid');
    params.filters.push({
        field: 'symbolid', // lower case necessary !
        operator: '=',
        value: _params.symbolId,
    });
    return { walletId, symbolId, params };
}

export const fetchWalletOpenedOrdersEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(FETCH_WALLET_OPENED_ORDERS.request)),
        mergeMap(async (action) => {
            const ordersState = store.getState().orders.wallet;
            const { walletId, params } = computeFetchWalletOrdersParams(action.payload, ordersState.opened.query, OrderStatus.OPENED);
            try {
                const result = await api.apiClient.wallets.getWalletOrders(walletId, params);
                return FETCH_WALLET_OPENED_ORDERS.success({ walletId, params, result });
            } catch (e) {
                return FETCH_WALLET_OPENED_ORDERS.failure(e.message);
            }
        }),
    );
};

export const moreWalletOpenedOrdersEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(MORE_WALLET_OPENED_ORDERS.request)),
        mergeMap(async (action) => {
            const ordersState = store.getState().orders.wallet;
            if (!ordersState.opened.hasNext) {
                throw new Error('NO NEXT');
            }
            const stateQuery = ordersState.opened.query || {};
            const _params: IFetchWalletOrdersParams = {
                ...stateQuery,
                ...action.payload,
                page: (ordersState.opened.page || 1) + 1,
            };
            const { walletId, params } = computeFetchWalletOrdersParams(_params, ordersState.opened.query, OrderStatus.OPENED);

            try {
                const result = await api.apiClient.wallets.getWalletOrders(walletId, params);
                return MORE_WALLET_OPENED_ORDERS.success({ walletId, params, result });
            } catch (e) {
                return MORE_WALLET_OPENED_ORDERS.failure(e.message);
            }
        }),
    );
};

export const fetchWalletClosedOrdersEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(FETCH_WALLET_CLOSED_ORDERS.request)),
        mergeMap(async (action) => {
            const ordersState = store.getState().orders.wallet;
            const { walletId, params } = computeFetchWalletOrdersParams(action.payload, ordersState.closed.query, OrderStatus.CLOSED);
            try {
                const result = await api.apiClient.wallets.getWalletOrders(walletId, params);
                return FETCH_WALLET_CLOSED_ORDERS.success({ walletId, params, result });
            } catch (e) {
                return FETCH_WALLET_CLOSED_ORDERS.failure(e.message);
            }
        }),
    );
};

export const moreWalletClosedOrdersEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(MORE_WALLET_CLOSED_ORDERS.request)),
        mergeMap(async (action) => {
            const ordersState = store.getState().orders.wallet;
            if (!ordersState.closed.hasNext) {
                throw new Error('NO NEXT');
            }
            const stateQuery = ordersState.closed.query || {};
            const _params: IFetchWalletOrdersParams = {
                ...stateQuery,
                ...action.payload,
                page: (ordersState.closed.page || 1) + 1,
            };
            const { walletId, params } = computeFetchWalletOrdersParams(_params, ordersState.closed.query, OrderStatus.CLOSED);
            try {
                const result = await api.apiClient.wallets.getWalletOrders(walletId, params);
                return MORE_WALLET_CLOSED_ORDERS.success({ walletId, params, result });
            } catch (e) {
                return MORE_WALLET_CLOSED_ORDERS.failure(e.message);
            }
        }),
    );
};

export const fetchWalletSymbolOpenedOrdersEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(FETCH_WALLET_SYMBOL_OPENED_ORDERS.request)),
        mergeMap(async (action) => {
            const ordersState = store.getState().orders.symbol;
            const { walletId, symbolId, params } = computeFetchWalletSymbolOrdersParams(action.payload, ordersState.opened.query, OrderStatus.OPENED);
            try {
                const result = await api.apiClient.wallets.getWalletOrders(walletId, params);
                return FETCH_WALLET_SYMBOL_OPENED_ORDERS.success({ walletId, symbolId, params, result });
            } catch (e) {
                return FETCH_WALLET_SYMBOL_OPENED_ORDERS.failure(e.message);
            }
        }),
    );
};

export const moreWalletSymbolOpenedOrdersEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(MORE_WALLET_SYMBOL_OPENED_ORDERS.request)),
        mergeMap(async (action) => {
            const ordersState = store.getState().orders.symbol;
            if (!ordersState.opened.hasNext) {
                throw new Error('NO NEXT');
            }
            const stateQuery = ordersState.opened.query || {};
            const _params: IFetchWalletSymbolOrdersParams = {
                ...stateQuery,
                ...action.payload,
                page: (ordersState.opened.page || 1) + 1,
            };
            const { walletId, symbolId, params } = computeFetchWalletSymbolOrdersParams(_params, ordersState.opened.query, OrderStatus.OPENED);

            try {
                const result = await api.apiClient.wallets.getWalletOrders(walletId, params);
                return MORE_WALLET_SYMBOL_OPENED_ORDERS.success({ walletId, symbolId, params, result });
            } catch (e) {
                return MORE_WALLET_SYMBOL_OPENED_ORDERS.failure(e.message);
            }
        }),
    );
};

export const fetchWalletSymbolClosedOrdersEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(FETCH_WALLET_SYMBOL_CLOSED_ORDERS.request)),
        mergeMap(async (action) => {
            const ordersState = store.getState().orders.symbol;
            const { walletId, symbolId, params } = computeFetchWalletSymbolOrdersParams(action.payload, ordersState.closed.query, OrderStatus.CLOSED);

            try {
                const result = await api.apiClient.wallets.getWalletOrders(walletId, params);
                return FETCH_WALLET_SYMBOL_CLOSED_ORDERS.success({ walletId, symbolId, params, result });
            } catch (e) {
                return FETCH_WALLET_SYMBOL_CLOSED_ORDERS.failure(e.message);
            }
        }),
    );
};

export const moreWalletSymbolClosedOrdersEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(MORE_WALLET_SYMBOL_CLOSED_ORDERS.request)),
        mergeMap(async (action) => {
            const ordersState = store.getState().orders.symbol;
            if (!ordersState.closed.hasNext) {
                throw new Error('NO NEXT');
            }
            const stateQuery = ordersState.closed.query || {};
            const _params: IFetchWalletSymbolOrdersParams = {
                ...stateQuery,
                ...action.payload,
                page: (ordersState.closed.page || 1) + 1,
            };
            const { walletId, symbolId, params } = computeFetchWalletSymbolOrdersParams(_params, ordersState.closed.query, OrderStatus.CLOSED);

            try {
                const result = await api.apiClient.wallets.getWalletOrders(walletId, params);
                return MORE_WALLET_SYMBOL_CLOSED_ORDERS.success({ walletId, symbolId, params, result });
            } catch (e) {
                return MORE_WALLET_SYMBOL_CLOSED_ORDERS.failure(e.message);
            }
        }),
    );
};

export const closeWalletOrder: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(CLOSE_WALLET_ORDER.request)),
        mergeMap(async (action) => {
            try {
                const { walletId, orderId } = action.payload;
                await api.apiClient.wallets.closeWalletOrder(walletId, orderId);
                return CLOSE_WALLET_ORDER.success({ walletId, orderId });
            } catch (e) {
                return CLOSE_WALLET_ORDER.failure(e.message);
            }
        }),
    );
};
