import { UiSchema } from '@rjsf/utils';
import * as _ from 'lodash';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import { IEntityDetailResponse, IEntityListCapabilities, IEntitySchemaDefinition, IOptimization, JSONSchema7Extended } from 'alpha-api-client';

import { reducePage } from '../../../common/reducersUtils';
import { IFiltersParams, ISortByParams } from '../../../common/types';
import { CREATE_SIMULATION, GET_SIMULATION } from '../../actions';
import {
    CREATE_SIMULATION_OPTIMIZATION,
    DELETE_SIMULATION_OPTIMIZATION,
    GET_SIMULATION_OPTIMIZATION,
    GET_SIMULATION_OPTIMIZATION_SCHEMA,
    LIST_SIMULATION_OPTIMIZATIONS,
    SELECT_OPTIMIZATION,
    UPDATE_SIMULATION_OPTIMIZATION,
} from './actions';

export interface IOptimizationsStateQuery {
    limit: string;
    filters: IFiltersParams[];
    sortBy: ISortByParams[];
    query?: string;
}

interface ISimulationOptimizationsState {
    schema?: JSONSchema7Extended;
    representation?: UiSchema;
    capabilities: IEntityListCapabilities;
    items: IOptimization[];
    page: number | null;
    hasNext: boolean;
    totalCount: number;
    query: IOptimizationsStateQuery;
}

export interface ISimulationOptimizationsStates {
    simulationId?: string;
    optimizationId?: string;
    current?: IEntityDetailResponse<IOptimization>;
    list: ISimulationOptimizationsState;
    isLoading: boolean;
    action?: { name: string } & IEntitySchemaDefinition;
}

interface ISimulationConfigurationStates {
    optimizations: ISimulationOptimizationsStates;
}

const initialState: ISimulationConfigurationStates = {
    optimizations: {
        isLoading: false,
        list: {
            items: [],
            page: null,
            hasNext: false,
            totalCount: 0,
            query: {
                limit: '10',
                filters: [],
                sortBy: [],
                query: '',
            },
            capabilities: {
                $canCreate: false,
            },
        },
    },
};

const candlesReducer = combineReducers({
    optimizations: createReducer(initialState.optimizations)

        .handleAction([GET_SIMULATION_OPTIMIZATION_SCHEMA.request],
            (state: ISimulationOptimizationsStates, action: ActionType<typeof GET_SIMULATION_OPTIMIZATION_SCHEMA.request>): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    action: undefined,
                };
            })
        .handleAction([GET_SIMULATION_OPTIMIZATION_SCHEMA.success],
            (state: ISimulationOptimizationsStates, action: ActionType<typeof GET_SIMULATION_OPTIMIZATION_SCHEMA.success>): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    action: {
                        name: action.payload.name,
                        $schema: action.payload.result.$schema,
                        $representation: action.payload.result.$representation,
                    },
                };
            })
        .handleAction([LIST_SIMULATION_OPTIMIZATIONS.request],
            (state: ISimulationOptimizationsStates, action: ActionType<typeof LIST_SIMULATION_OPTIMIZATIONS.request>): ISimulationOptimizationsStates => {
                if (state.simulationId != null && state.simulationId !== action.payload.simulationId) {
                    return {
                        ...initialState.optimizations,
                        isLoading: true,
                        simulationId: action.payload.simulationId,
                        list: {
                            ...initialState.optimizations.list,
                        },
                    };
                }
                return {
                    ...state,
                    isLoading: true,
                    list: {
                        ...state.list,
                    },
                };
            })
        .handleAction([LIST_SIMULATION_OPTIMIZATIONS.failure],
            (state: ISimulationOptimizationsStates): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    isLoading: false,
                    list: {
                        ...state.list,
                    },
                };
            })
        .handleAction([LIST_SIMULATION_OPTIMIZATIONS.success],
            (state: ISimulationOptimizationsStates, action: ActionType<typeof LIST_SIMULATION_OPTIMIZATIONS.success>): ISimulationOptimizationsStates => {
                const simulationChanged = action.payload.simulationId !== state.simulationId;
                return {
                    simulationId: action.payload.simulationId,
                    ...(simulationChanged ? initialState.optimizations : state),
                    ...state,
                    isLoading: false,
                    list: {
                        ...state.list,
                        ...reducePage(action.payload.result),
                        query: {
                            limit: action.payload.params.limit || state.list.query.limit,
                            filters: action.payload.params.filters || state.list.query.filters,
                            sortBy: action.payload.params.sortBy || state.list.query.sortBy,
                            query: action.payload.params.query || state.list.query.query,
                        },
                        capabilities: action.payload.result.$capabilities,
                    },
                };
            })

        .handleAction([GET_SIMULATION.success],
            (state: ISimulationOptimizationsStates, action: ActionType<typeof GET_SIMULATION.success>): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    optimizationId: action.payload.optimization,
                };
            })

        .handleAction([CREATE_SIMULATION.success],
            (state: ISimulationOptimizationsStates, action: ActionType<typeof CREATE_SIMULATION.success>): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    optimizationId: action.payload.optimization,
                };
            })

        .handleAction([GET_SIMULATION_OPTIMIZATION.request],
            (state: ISimulationOptimizationsStates): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    isLoading: true,
                    current: undefined,
                };
            })
        .handleAction([GET_SIMULATION_OPTIMIZATION.failure],
            (state: ISimulationOptimizationsStates): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    isLoading: false,
                    current: undefined,
                };
            })
        .handleAction([GET_SIMULATION_OPTIMIZATION.success],
            (state: ISimulationOptimizationsStates, action: ActionType<typeof GET_SIMULATION_OPTIMIZATION.success>): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    isLoading: false,
                    current: action.payload,
                };
            })
        .handleAction([CREATE_SIMULATION_OPTIMIZATION.request],
            (state: ISimulationOptimizationsStates): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    isLoading: true,
                };
            })
        .handleAction([CREATE_SIMULATION_OPTIMIZATION.failure],
            (state: ISimulationOptimizationsStates): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    isLoading: false,
                };
            })
        .handleAction([CREATE_SIMULATION_OPTIMIZATION.success],
            (state: ISimulationOptimizationsStates, action: ActionType<typeof CREATE_SIMULATION_OPTIMIZATION.success>): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    isLoading: false,
                    optimizationId: action.payload.id,
                    current: action.payload,
                    list: {
                        ...state.list,
                        items: state.list.items.concat(action.payload),
                    },
                };
            })
        .handleAction([UPDATE_SIMULATION_OPTIMIZATION.request],
            (state: ISimulationOptimizationsStates): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    isLoading: true,
                };
            })
        .handleAction([UPDATE_SIMULATION_OPTIMIZATION.failure],
            (state: ISimulationOptimizationsStates): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    isLoading: false,
                };
            })
        .handleAction([UPDATE_SIMULATION_OPTIMIZATION.success],
            (state: ISimulationOptimizationsStates, action: ActionType<typeof UPDATE_SIMULATION_OPTIMIZATION.success>): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    isLoading: false,
                    current: action.payload,
                    list: {
                        ...state.list,
                        items: state.list.items.map((item) => {
                            if (item.id === action.payload.id) {
                                return action.payload;
                            }
                            return item;
                        }),
                    },
                };
            })
        .handleAction([DELETE_SIMULATION_OPTIMIZATION.request],
            (state: ISimulationOptimizationsStates): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    isLoading: true,
                };
            })
        .handleAction([DELETE_SIMULATION_OPTIMIZATION.failure],
            (state: ISimulationOptimizationsStates): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    isLoading: false,
                };
            })
        .handleAction([DELETE_SIMULATION_OPTIMIZATION.success],
            (state: ISimulationOptimizationsStates, action: ActionType<typeof DELETE_SIMULATION_OPTIMIZATION.success>): ISimulationOptimizationsStates => {
                const items = _.filter(state.list.items, item => item.id !== action.payload.optimizationId);
                return {
                    ...state,
                    isLoading: false,
                    list: {
                        ...state.list,
                        items,
                        totalCount: state.list.totalCount--,
                    },
                    optimizationId: _.first(items)?.id,
                };
            })
        .handleAction([SELECT_OPTIMIZATION],
            (state: ISimulationOptimizationsStates, action: ActionType<typeof SELECT_OPTIMIZATION>): ISimulationOptimizationsStates => {
                return {
                    ...state,
                    optimizationId: action.payload,
                };
            }),
});

export default candlesReducer;
