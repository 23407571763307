import * as _ from 'lodash';
import { Epic } from 'redux-observable';
import { filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import * as uuid from 'uuid';

import { RootAction, RootState, Services } from 'alpha-screener-types';

import { ADD_NOTIFICATION } from '../notifications/actions';
import { LISTEN_GLOBAL_EVENTS } from './actions';

export const sseGlobalEventsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(LISTEN_GLOBAL_EVENTS.request)),
        switchMap((action) => {
            let trackingId = localStorage.getItem('trackingId');
            if (!trackingId) {
                trackingId = uuid.v4();
                localStorage.setItem('trackingId', trackingId);
            }
            return api.sseClient.listen('global', trackingId).pipe(
                map((resp) => {
                    return ADD_NOTIFICATION({
                        type: resp.type,
                        id: resp.id,
                        index: _.keys(state$.value.notifications.list.items).length,
                        title: resp.title,
                        phase: resp.phase,
                        progress: resp.progress,
                        diagnoses: resp.diagnoses,
                    });
                }),
            );
        }),
    );
};
