import { IncomingHttpHeaders } from 'http';

export interface IAlphaSessionOptions {
    apiUrl?: string;

    additionalRequestHeaders?: () => IncomingHttpHeaders;
}

const ALPHA_API_URL = `https://ted-home.eu/api`;

export class SessionContext {

    public readonly apiUrl: string;

    public readonly additionalRequestHeaders: (() => IncomingHttpHeaders) | undefined;

    private bearerToken: string;

    constructor(options?: IAlphaSessionOptions) {
        this.apiUrl = options?.apiUrl || ALPHA_API_URL;
        this.additionalRequestHeaders = options?.additionalRequestHeaders;
    }

    public set accessToken(token: string) {
        this.bearerToken = token;
    }

    public get accessToken(): string {
        return this.bearerToken;
    }

}
