import { createAsyncAction } from 'typesafe-actions';

import { IWalletSummary, IWalletSummaryParams } from 'alpha-api-client';

export interface IWalletSummaryActionParams extends IWalletSummaryParams {
    walletId: string;
    cashByInterval?: 'hour' | 'day';
}

export interface IWalletSummaryActionResult {
    params: IWalletSummaryActionParams;
    result: IWalletSummary;
}

export interface IWalletSymbolSummaryActionParams extends IWalletSummaryParams {
    walletId: string;
    symbolId: string;
}

export const FETCH_WALLET_SUMMARY = createAsyncAction(
    'FETCH_WALLET_SUMMARY_REQUEST',
    'FETCH_WALLET_SUMMARY_SUCCESS',
    'FETCH_WALLET_SUMMARY_FAILURE',
)<IWalletSummaryActionParams, IWalletSummaryActionResult, string>();
