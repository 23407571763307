import { IApiCommonPageResult } from './pagination';

export enum PositionStatus {
    OPENED = 'opened',
    CLOSED = 'closed',
}

export interface IPosition {
    id: string;
    simulationId: string;
    symbolId: string;
    name: string;
    optimization: string;
    date: Date;
    direction: 'short' | 'long';
    openQuote: number;
    event: string;
    stopLoss: number;
    stopProfit?: number;
    closeDate?: Date;
    closeQuote?: number;
    gain?: number;
    fee?: number;
    roe?: number;
}

export type IPositionsPageApiResult = IApiCommonPageResult<IPosition>;
