import { Moment } from 'moment';
import { createAsyncAction } from 'typesafe-actions';

import { IFetchPageParams, IPositionsPageApiResult } from 'alpha-api-client';

export interface IFetchSimulationPositionsParams extends IFetchPageParams {
    simulationId: string;
    optimizationId?: string;
    startDate?: Moment;
    endDate?: Moment;
    nominalInvest?: number;
}

export interface IFetchSimulationPositionsResult {
    simulationId: string;
    params: IFetchPageParams;
    result: IPositionsPageApiResult;
}

export interface IFetchSimulationSymbolPositionsParams extends IFetchPageParams {
    simulationId: string;
    symbolId: string;
    startDate?: Moment;
    endDate?: Moment;
}

export interface IFetchSimulationSymbolPositionsResult {
    simulationId: string;
    symbolId: string;
    params: IFetchPageParams;
    result: IPositionsPageApiResult;
}

export const FETCH_SIMULATION_OPENED_POSITIONS = createAsyncAction(
    'FETCH_SIMULATION_OPENED_POSITIONS_REQUEST',
    'FETCH_SIMULATION_OPENED_POSITIONS_SUCCESS',
    'FETCH_SIMULATION_OPENED_POSITIONS_FAILURE',
)<IFetchSimulationPositionsParams, IFetchSimulationPositionsResult, string>();

export const MORE_SIMULATION_OPENED_POSITIONS = createAsyncAction(
    'MORE_SIMULATION_OPENED_POSITIONS_REQUEST',
    'MORE_SIMULATION_OPENED_POSITIONS_SUCCESS',
    'MORE_SIMULATION_OPENED_POSITIONS_FAILURE',
)<IFetchSimulationPositionsParams, IFetchSimulationPositionsResult, string>();

export const FETCH_SIMULATION_CLOSED_POSITIONS = createAsyncAction(
    'FETCH_SIMULATION_CLOSED_POSITIONS_REQUEST',
    'FETCH_SIMULATION_CLOSED_POSITIONS_SUCCESS',
    'FETCH_SIMULATION_CLOSED_POSITIONS_FAILURE',
)<IFetchSimulationPositionsParams, IFetchSimulationPositionsResult, string>();

export const MORE_SIMULATION_CLOSED_POSITIONS = createAsyncAction(
    'MORE_SIMULATION_CLOSED_POSITIONS_REQUEST',
    'MORE_SIMULATION_CLOSED_POSITIONS_SUCCESS',
    'MORE_SIMULATION_CLOSED_POSITIONS_FAILURE',
)<IFetchSimulationPositionsParams, IFetchSimulationPositionsResult, string>();

export const FETCH_SIMULATION_SYMBOL_OPENED_POSITIONS = createAsyncAction(
    'FETCH_SIMULATION_SYMBOL_OPENED_POSITIONS_REQUEST',
    'FETCH_SIMULATION_SYMBOL_OPENED_POSITIONS_SUCCESS',
    'FETCH_SIMULATION_SYMBOL_OPENED_POSITIONS_FAILURE',
)<IFetchSimulationSymbolPositionsParams, IFetchSimulationSymbolPositionsResult, string>();

export const MORE_SIMULATION_SYMBOL_OPENED_POSITIONS = createAsyncAction(
    'MORE_SIMULATION_SYMBOL_OPENED_POSITIONS_REQUEST',
    'MORE_SIMULATION_SYMBOL_OPENED_POSITIONS_SUCCESS',
    'MORE_SIMULATION_SYMBOL_OPENED_POSITIONS_FAILURE',
)<IFetchSimulationSymbolPositionsParams, IFetchSimulationSymbolPositionsResult, string>();

export const FETCH_SIMULATION_SYMBOL_CLOSED_POSITIONS = createAsyncAction(
    'FETCH_SIMULATION_SYMBOL_CLOSED_POSITIONS_REQUEST',
    'FETCH_SIMULATION_SYMBOL_CLOSED_POSITIONS_SUCCESS',
    'FETCH_SIMULATION_SYMBOL_CLOSED_POSITIONS_FAILURE',
)<IFetchSimulationSymbolPositionsParams, IFetchSimulationSymbolPositionsResult, string>();

export const MORE_SIMULATION_SYMBOL_CLOSED_POSITIONS = createAsyncAction(
    'MORE_SIMULATION_SYMBOL_CLOSED_POSITIONS_REQUEST',
    'MORE_SIMULATION_SYMBOL_CLOSED_POSITIONS_SUCCESS',
    'MORE_SIMULATION_SYMBOL_CLOSED_POSITIONS_FAILURE',
)<IFetchSimulationSymbolPositionsParams, IFetchSimulationSymbolPositionsResult, string>();
