import { AbstractClient } from '../../AbstractClient';
import { IEntityListResponse, IEntitySchemaDefinition } from '../../types/entities';
import { IFetchPageParams } from '../../types/pagination';
import { IAddSimulationSymbolParams } from '../../types/simulations';
import { ISimulationSymbolSummaryParams, ISymbolRef, ISymbolRefPageApiResult, SymbolRefAction } from '../../types/simulations-symbols';
import { ISummary } from '../../types/summaries';

export class SimulationSymbolsClient extends AbstractClient {

    public getSimulationSymbolSchema(simulationId: string, schema: SymbolRefAction): Promise<IEntitySchemaDefinition> {
        return this.runRequest({
            method: 'GET',
            url: `${this.session.apiUrl}/simulations/${simulationId}/symbols/$schema/${schema}`,
        });
    }

    public getSimulationSymbolDetail(simulationId: string, symbolId: string): Promise<ISymbolRef> {
        const url = `${this.session.apiUrl}/simulations/${simulationId}/symbols/${symbolId}`;
        return this.runRequest({
            method: 'GET', url,
        });
    }

    public addSimulationSymbol(simulationId: string, data: IAddSimulationSymbolParams): Promise<IEntityListResponse<ISymbolRef>> {
        const url = `${this.session.apiUrl}/simulations/${simulationId}/symbols`;
        return this.runRequest({
            method: 'POST',
            url,
            data: {
                symbolId: data.symbolId,
                leverage: data.leverage,
            },
        });
    }

    public removeSimulationSymbol(simulationId: string, symbolId: string): Promise<void> {
        const url = `${this.session.apiUrl}/simulations/${simulationId}/symbols/${symbolId}`;
        return this.runRequest({
            method: 'DELETE',
            url,
        });
    }

    public listSimulationSymbols(simulationId: string, params: IFetchPageParams): Promise<ISymbolRefPageApiResult> {
        return this.getPaginatedEntity({
            method: 'GET',
            url: `${this.session.apiUrl}/simulations/${simulationId}/symbols`,
        }, params);
    }

    public getSimulationSymbolSummary(simulationId: string, symbolId: string, data: ISimulationSymbolSummaryParams): Promise<ISummary> {
        const query = this.generateQueryString({
            from: data.startDate,
            to: data.endDate,
            nominalInvest: data.nominalInvest,
            initialCapital: data.initialCapital,
            optimizationId: data.optimizationId,
        });
        const url = `${this.session.apiUrl}/simulations/${simulationId}/symbols/${symbolId}/summary?${query}`;
        return this.runRequest({
            method: 'GET',
            url,
        });
    }
}
