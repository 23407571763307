import * as qs from 'qs';
import streamSaver from 'streamsaver';
import { WritableStream } from 'web-streams-polyfill/ponyfill';

import { HttpRequestHelpers } from './HttpRequestHelpers';

// If the WritableStream is not available (Firefox, Safari), take it from the polyfill
if (!window.WritableStream) {
    streamSaver.WritableStream = WritableStream;
    window.WritableStream = WritableStream;
}

export class HttpDownloader {

    private readonly apiBaseUrl: string;
    private writer: WritableStreamDefaultWriter | undefined;

    constructor(apiBaseUrl: string) {
        this.apiBaseUrl = apiBaseUrl;
    }

    public async download(subUrl: string, query?: any): Promise<void> {
        // eslint-disable-next-line no-console
        console.log('Download file with streamsaver');

        // do not prevent unload with unsecured contexts because of new window
        // cf: https://www.npmjs.com/package/streamsaver#best-practice
        if (window.location.protocol === 'https:') {
            window.addEventListener('beforeunload', this.beforeUnloadListener);
        }
        const _query = qs.stringify({ ...query, tid: localStorage.getItem('trackingId') });
        const url = `${this.apiBaseUrl}${subUrl}?${_query}`;
        await fetch(url, {
            method: 'GET',
            headers: HttpRequestHelpers.buildHttpRequestHeaders() as HeadersInit,
        }).then((response: Response) => {
            if (response.status === 200) {
                const contentDisposition = response.headers.get('Content-Disposition');
                const fileName = contentDisposition ? this.extractFilenameFromContentDisposition(contentDisposition) : 'export.csv';
                const fileStream = streamSaver.createWriteStream(fileName);
                this.writer = fileStream.getWriter();
                const reader = response.body?.getReader()!;

                // abort so it dose not look stuck
                window.addEventListener('unload', this.unloadListener);

                const pump = (): any => {
                    return reader.read().then((res) => {
                        if (res.done) {
                            window.removeEventListener('beforeunload', this.beforeUnloadListener);
                            window.removeEventListener('unload', this.unloadListener);
                            return this.writer!.close();
                        }
                        return this.writer!.write(res.value).then(pump);

                    });
                };
                return pump();
            }
            window.removeEventListener('beforeunload', this.beforeUnloadListener);
        }).catch((e) => {
            window.removeEventListener('beforeunload', this.beforeUnloadListener);
            return Promise.reject(e);
        });
    }

    private beforeUnloadListener = (eventBeforeUnload: BeforeUnloadEvent) => {
        eventBeforeUnload.preventDefault();
        eventBeforeUnload.returnValue = 'download in progress';
    };

    private unloadListener = async (_evt: Event) => {
        await this.writer?.abort();
    };

    private extractFilenameFromContentDisposition(contentDisposition: string) {
        let fileName = contentDisposition.match(/filename="(.*)"/)![1];
        try {
            fileName = decodeURIComponent(fileName);
        } catch (e) {
            // noop
        }
        return fileName;
    }
}
