import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link, { LinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from 'src/router';

import { RootState } from 'alpha-screener-types';

const PREFIX = 'Breadcrumb';

const classes = {
    root: `${PREFIX}-root`,
    parents: `${PREFIX}-parents`,
    current: `${PREFIX}-current`,
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        width: 360,
    },

    [`& .${classes.parents}`]: {
        color: theme.palette.primary.light,
    },

    [`& .${classes.current}`]: {
        color: theme.palette.primary.contrastText,
    },
}));

interface ILinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

const LinkRouter = (props: ILinkRouterProps) => <Link {...props} component={RouterLink as any}/>;

export default () => {

    const { simulations } = useSelector((state: RootState) => state.simulations);
    const { wallets } = useSelector((state: RootState) => state.wallets);
    const currentSymbol = useSelector((state: RootState) => state.simulationSymbols.detail);

    const simulationsRouteMapping: ((snippet: string) => string)[] = [
        (snippet) => {
            switch (feature) {
            case 'simulations':
                return 'Simulations';
            case 'wallets':
                return 'Wallets';
            }
        },
        (snippet) => {
            switch (feature) {
            case 'simulations':
                return simulations.current?.name!;
            case 'wallets':
                return wallets.current?.name!;
            }
        },
        () => {
            switch (feature) {
            case 'simulations':
                return currentSymbol?.name!;
            }
        },
    ];

    const url = window.location.pathname.split('/').splice(1);
    const pathnames = url.filter(x => x);
    const feature = pathnames[0];

    return (
        <Root className={classes.root}>
            <Breadcrumbs aria-label={'breadcrumb'}>
                <LinkRouter color={'inherit'} to={Routes.ROOT}>
                    Home
                </LinkRouter>

                {pathnames.map((value, index) => {
                    const last = index === pathnames.length;
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    const title = simulationsRouteMapping[index](value);
                    return last ? (
                        <Typography className={classes.current} key={to}>
                            {title}
                        </Typography>
                    ) : (
                        <LinkRouter className={classes.parents} to={to} key={to}>
                            {title}
                        </LinkRouter>
                    );
                })}
            </Breadcrumbs>
        </Root>
    );
};
