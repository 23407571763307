import { IApiCommonPageResult } from './pagination';

export enum WalletAction {
    CREATE = 'create',
}

export enum ApiBrokerName {
    BINANCE = 'binance',
    BYBIT = 'bybit',
    BITGET = 'bitget',
}

export interface IApiBrokerAuthByName {
    [ApiBrokerName.BINANCE]: IApiBinanceAuth;
    [ApiBrokerName.BYBIT]: IApiBybitAuth;
    [ApiBrokerName.BITGET]: IApiBitgetAuth;
}

export interface IApiBinanceAuth {
    apiKey: string;
    apiSecret: string;
}

export interface IApiBybitAuth {
    apiKey: string;
    apiSecret: string;
}

export interface IApiBitgetAuth {
    apiKey: string;
    apiSecret: string;
    passphrase: string;
}

export enum ALPHA_ORDER_RISK {
    VERY_HIGH = 1,
    HIGH = 2,
    MEDIUM = 3,
    SMALL = 4,
    VERY_SMALL = 5,
}

export interface ICreateWalletBody {
    name: string;
    risk: number;
    maxLeverage?: number;
    strictSecure?: boolean;
    maxCapitalWeight?: number;
    broker: ApiBrokerName;
}

export interface IUpdateWalletBody {
    name: string;
    risk: number;
    maxLeverage?: number;
    strictSecure?: boolean;
    maxCapitalWeight?: number;
    activated: boolean;
    simulationId?: string;
    optimizationId?: string;
    auth?: IApiBrokerAuthByName[ApiBrokerName];
}

export interface IWallet {
    id: string;
    name: string;
    broker: ApiBrokerName;
    activated: boolean;
    simulationId?: string;
    optimizationId?: string;
    risk: ALPHA_ORDER_RISK;
    maxLeverage?: number;
    strictSecure?: boolean;
    maxCapitalWeight?: number;
    ownerId: string;
}

export interface IWalletAccountBalanceInfo {
    balance: number;
    floating: number;
    available: number;
}

export type IWalletPageApiResult = IApiCommonPageResult<IWallet>;

export interface IWalletSummaryParams {
    startDate?: string;
    endDate?: string;
    cashByInterval?: 'hour' | 'day';
}
