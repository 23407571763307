import Keycloak from 'keycloak-js';

const REFRESH_TOKEN_MARGIN_IN_SEC = 5;

export class AuthManager {

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    public readonly keycloak = new Keycloak({
        realm: 'alpha',
        clientId: 'alpha-screener-front',
        url: `/auth`,
    });
    private updateTokenTimeout: number | undefined;

    public initialize(): Promise<void> {
        let hasLoginError = true;

        this.keycloak.onAuthLogout = () => !hasLoginError && window.location.reload();
        this.keycloak.onAuthRefreshError = () => !hasLoginError && window.location.reload();
        this.keycloak.onTokenExpired = () => this._updateToken(5);

        if (this.authenticated) {
            return this._updateToken(5)
                .then(() => {
                    hasLoginError = false;
                })
                .catch(e => Promise.reject(e));
        }
        return Promise.resolve();

    }

    public onEvent = async (event, error?) => {
        if (event === 'onReady') {
            await this._updateToken();
        }
    };

    public onTokens = (tokens) => {
        // console.log('onKeycloakTokens', tokens);
    };

    public _updateToken = (minValidity = -1) => {
        window.clearTimeout(this.updateTokenTimeout);
        return new Promise<void>((resolve, reject) => {
            this.keycloak
                .updateToken(minValidity)
                .then(() => {
                    const { exp, iat } = this.keycloak.tokenParsed!;
                    const expireDelayInSec = exp! - iat! - REFRESH_TOKEN_MARGIN_IN_SEC - 2; // 2(s) is the of margin of error
                    // 60(s) is the min token lifespan on keycloak and 10(s) is the margin of error
                    const minValidExpireDelayInSec = 60 - REFRESH_TOKEN_MARGIN_IN_SEC - 10;
                    if (expireDelayInSec >= minValidExpireDelayInSec) {
                        this.updateTokenTimeout = window.setTimeout(
                            this._updateToken,
                            expireDelayInSec * 1000,
                        );
                    }
                    resolve();
                })
                .catch((err: Error) => reject(err));
        });
    };

    public get authenticated(): boolean {
        return this.keycloak?.authenticated || false;
    }

    public getUserId() {
        return this.authenticated ? this.keycloak.tokenParsed?.sub : undefined;
    }

    public forceRefreshToken() {
        return this._updateToken();
    }

    public get token() {
        if (this.authenticated) {
            return `Bearer ${this.keycloak.token}`;
        }
        return '';
    }

    public login() {
        void this.keycloak.login();
    }

    public logout() {
        void this.keycloak.logout();
    }

}

export const authManager = new AuthManager();
