import { Moment } from 'moment';
import { createAsyncAction } from 'typesafe-actions';

import { IOrdersPageApiResult } from 'alpha-api-client';

import { IFetchParams } from '../../../common/types';

export interface IFetchWalletOrdersParams extends IFetchParams {
    walletId: string;
    startDate?: Moment;
    endDate?: Moment;
}

export interface IFetchWalletOrdersResult {
    walletId: string;
    params: IFetchParams;
    result: IOrdersPageApiResult;
}

export interface IFetchWalletSymbolOrdersParams extends IFetchParams {
    walletId: string;
    symbolId: string;
    startDate?: Moment;
    endDate?: Moment;
}

export interface IFetchWalletSymbolOrdersResult {
    walletId: string;
    symbolId: string;
    params: IFetchParams;
    result: IOrdersPageApiResult;
}

export interface ICloseWalletOrderParams {
    walletId: string;
    orderId?: string;
}

export interface ICloseWalletOrderResult {
    walletId: string;
    orderId: string;
}

export const FETCH_WALLET_OPENED_ORDERS = createAsyncAction(
    'FETCH_WALLET_OPENED_ORDERS_REQUEST',
    'FETCH_WALLET_OPENED_ORDERS_SUCCESS',
    'FETCH_WALLET_OPENED_ORDERS_FAILURE',
)<IFetchWalletOrdersParams, IFetchWalletOrdersResult, string>();

export const MORE_WALLET_OPENED_ORDERS = createAsyncAction(
    'MORE_WALLET_OPENED_ORDERS_REQUEST',
    'MORE_WALLET_OPENED_ORDERS_SUCCESS',
    'MORE_WALLET_OPENED_ORDERS_FAILURE',
)<IFetchWalletOrdersParams, IFetchWalletOrdersResult, string>();

export const FETCH_WALLET_CLOSED_ORDERS = createAsyncAction(
    'FETCH_WALLET_CLOSED_ORDERS_REQUEST',
    'FETCH_WALLET_CLOSED_ORDERS_SUCCESS',
    'FETCH_WALLET_CLOSED_ORDERS_FAILURE',
)<IFetchWalletOrdersParams, IFetchWalletOrdersResult, string>();

export const MORE_WALLET_CLOSED_ORDERS = createAsyncAction(
    'MORE_WALLET_CLOSED_ORDERS_REQUEST',
    'MORE_WALLET_CLOSED_ORDERS_SUCCESS',
    'MORE_WALLET_CLOSED_ORDERS_FAILURE',
)<IFetchWalletOrdersParams, IFetchWalletOrdersResult, string>();

export const FETCH_WALLET_SYMBOL_OPENED_ORDERS = createAsyncAction(
    'FETCH_WALLET_SYMBOL_OPENED_ORDERS_REQUEST',
    'FETCH_WALLET_SYMBOL_OPENED_ORDERS_SUCCESS',
    'FETCH_WALLET_SYMBOL_OPENED_ORDERS_FAILURE',
)<IFetchWalletSymbolOrdersParams, IFetchWalletSymbolOrdersResult, string>();

export const MORE_WALLET_SYMBOL_OPENED_ORDERS = createAsyncAction(
    'MORE_WALLET_SYMBOL_OPENED_ORDERS_REQUEST',
    'MORE_WALLET_SYMBOL_OPENED_ORDERS_SUCCESS',
    'MORE_WALLET_SYMBOL_OPENED_ORDERS_FAILURE',
)<IFetchWalletSymbolOrdersParams, IFetchWalletSymbolOrdersResult, string>();

export const FETCH_WALLET_SYMBOL_CLOSED_ORDERS = createAsyncAction(
    'FETCH_WALLET_SYMBOL_CLOSED_ORDERS_REQUEST',
    'FETCH_WALLET_SYMBOL_CLOSED_ORDERS_SUCCESS',
    'FETCH_WALLET_SYMBOL_CLOSED_ORDERS_FAILURE',
)<IFetchWalletSymbolOrdersParams, IFetchWalletSymbolOrdersResult, string>();

export const MORE_WALLET_SYMBOL_CLOSED_ORDERS = createAsyncAction(
    'MORE_WALLET_SYMBOL_CLOSED_ORDERS_REQUEST',
    'MORE_WALLET_SYMBOL_CLOSED_ORDERS_SUCCESS',
    'MORE_WALLET_SYMBOL_CLOSED_ORDERS_FAILURE',
)<IFetchWalletSymbolOrdersParams, IFetchWalletSymbolOrdersResult, string>();

export const CLOSE_WALLET_ORDER = createAsyncAction(
    'CLOSE_WALLET_ORDER_REQUEST',
    'CLOSE_WALLET_ORDER_SUCCESS',
    'CLOSE_WALLET_ORDER_FAILURE',
)<ICloseWalletOrderParams, ICloseWalletOrderResult, string>();
