import { CandleInterval } from './candles';
import { IApiCommonPageResult, IFetchPageParams } from './pagination';
import { SymbolOrigin } from './symbols';

export type ISimulationInterval = '1 day' | '1 hour' | '1 minute';

export enum SimulationAction {
    CREATE = 'create',
}

export enum MacdPeriod {
    e5e15 = 'e5e15',
    e12e26 = 'e12e26',
    e36e78 = 'e36e78',
}

export interface ISimulationConfigEconv {
    maWindows: string;
    stochWindow?: number;
}

export interface ISimulationConfig {
    macdSupResPeriod: MacdPeriod;
    distSupResCount: number;
    econv: ISimulationConfigEconv;
    econv2: ISimulationConfigEconv;
    /** @deprecated **/
    maWindows: string;
}

export interface ISimulationAuthz {
    owner: string;
    readers: string[];
}

export interface ICreateSimulationBody {
    name: string;
    origin: SymbolOrigin;
    interval?: ISimulationInterval;
    auto: boolean;
    locked: boolean;
    config: ISimulationConfig;
}

export interface IUpdateSimulationBody {
    name: string;
    optimization: string;
    interval?: ISimulationInterval;
    auto: boolean;
    locked: boolean;
    config: ISimulationConfig;
}

export interface IDuplicateSimulationBody {
    name: string;
    origin: SymbolOrigin;
    interval?: ISimulationInterval;
    auto: boolean;
    locked: boolean;
    config: ISimulationConfig;
}

export interface ISimulation {
    id: string;
    name: string;
    auto: boolean;
    locked: boolean;
    optimization?: string;
    origin: SymbolOrigin;
    interval: ISimulationInterval;
    config: ISimulationConfig;
}

export type ISimulationsPageApiResult = IApiCommonPageResult<ISimulation>;

export interface IFetchSimulationSymbolCandlesParams extends IFetchPageParams {
    startDate?: string;
    endDate?: string;
    interval?: CandleInterval;
}

export interface IAddSimulationSymbolParams {
    symbolId: string;
    leverage: number;
}

export interface ISimulationSummaryParams {
    optimizationId: string;
    startDate?: string;
    endDate?: string;
    nominalInvest?: number;
    initialCapital?: number | null;
    riskFactor?: number | null;
    maxLeverage?: number | null;
    cashByInterval?: 'hour' | 'day';
}
