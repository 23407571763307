import moment from 'moment';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import { IDatesRange, SET_GLOBAL_DATES_RANGE } from './actions';

interface IGlobalStates {
    datesRange: IDatesRange;
}

const initialState: IGlobalStates = {
    datesRange: {
        startDate: moment().startOf('day').subtract(1, 'week'),
        endDate: moment().endOf('day'),
    },
};

export default combineReducers({
    datesRange: createReducer(initialState.datesRange)
        .handleAction([SET_GLOBAL_DATES_RANGE],
            (state: IDatesRange, action: ActionType<typeof SET_GLOBAL_DATES_RANGE>): IDatesRange => {
                return {
                    ...state,
                    startDate: action.payload.startDate?.startOf('day') || null,
                    endDate: action.payload.endDate?.endOf('day') || null,
                };
            }),
});
