import { ReactKeycloakProvider } from '@react-keycloak/web';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import SuspenseLoader from './components/SuspenseLoader/index';
import { SidebarProvider } from './contexts/SidebarContext';
import { authManager } from './services/AuthManager';
import * as serviceWorker from './serviceWorker';
import store from './store/index';

import 'nprogress/nprogress.css';

const Root = () => {
    return (
        <HelmetProvider>
            <SidebarProvider>
                <ReactKeycloakProvider authClient={window.auth.keycloak}
                    initOptions={{ onLoad: 'check-sso' }}
                    onEvent={window.auth.onEvent}
                    onTokens={window.auth.onTokens}
                    LoadingComponent={<SuspenseLoader />}>
                    <Provider store={store}>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </Provider>
                </ReactKeycloakProvider>
            </SidebarProvider>
        </HelmetProvider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
authManager.initialize().then(() => {
    window.auth = authManager;
    ReactDOM.render(<Root />, document.getElementById('root'));
}).catch((e) => {
    console.error('Error initializing authManager', e);
});
serviceWorker.unregister();
