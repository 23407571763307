import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRoutes } from 'react-router-dom';

import { LISTEN_GLOBAL_EVENTS } from './content/global/actions';
import { routes } from './router';
import ThemeProvider from './theme/ThemeProvider';

export default () => {

    const dispatch = useDispatch();
    const content = useRoutes(routes);

    useEffect(() => {
        dispatch(LISTEN_GLOBAL_EVENTS.request());
    }, [dispatch]);

    return (
        <>
            {window.auth.authenticated ?
                <ThemeProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <CssBaseline />
                        {content}
                    </LocalizationProvider>
                </ThemeProvider>
                : window.auth.login()}
        </>
    );
};
