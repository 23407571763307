import { ClientFactory } from './clients/ClientFactory';
import * as logger from './logger-service';

export default {
    logger,
    api: {
        apiClient: ClientFactory.apiClient(),
        sseClient: ClientFactory.sseClient(),
    },
};
