import { AbstractClient } from '../../AbstractClient';
import { SessionContext } from '../../SessionContext';
import { IAggregatePageApiResult, IFetchSimulationAggregatesParams } from '../../types/aggregates';
import { ICandlePageApiResult, IImportCandleApiResult } from '../../types/candles';
import { IEntityDetailResponse, IEntityListResponse, IEntitySchemaDefinition } from '../../types/entities';
import { IFetchPageParams } from '../../types/pagination';
import {
    ICreateSimulationBody,
    IFetchSimulationSymbolCandlesParams,
    ISimulation,
    ISimulationSummaryParams,
    IUpdateSimulationBody,
    SimulationAction,
} from '../../types/simulations';
import { ISummary } from '../../types/summaries';
import { SimulationOptimizationsClient } from './SimulationOptimizationsClient';
import { SimulationPositionsClient } from './SimulationPositionsClient';
import { SimulationSymbolsClient } from './SimulationSymbolsClient';

export class SimulationsClient extends AbstractClient {

    public readonly symbols: SimulationSymbolsClient;
    public readonly optimizations: SimulationOptimizationsClient;
    public readonly positions: SimulationPositionsClient;

    constructor(session: SessionContext) {
        super(session);
        this.positions = new SimulationPositionsClient(session);
        this.optimizations = new SimulationOptimizationsClient(session);
        this.symbols = new SimulationSymbolsClient(session);
    }

    public getSimulationSchema(schema: SimulationAction): Promise<IEntitySchemaDefinition> {
        return this.runRequest({
            method: 'GET',
            url: `${this.session.apiUrl}/simulations/$schema/${schema}`,
        });
    }

    public listSimulations(params: IFetchPageParams): Promise<IEntityListResponse<ISimulation>> {
        return this.getPaginatedEntity({
            method: 'GET',
            url: `${this.session.apiUrl}/simulations`,
        }, params);
    }

    public getSimulation(simulationId: string): Promise<IEntityDetailResponse<ISimulation>> {
        return this.runRequest({
            method: 'GET',
            url: `${this.session.apiUrl}/simulations/${simulationId}`,
        });
    }

    public createSimulation(data: ICreateSimulationBody): Promise<IEntityDetailResponse<ISimulation>> {
        return this.runRequest({
            method: 'POST',
            url: `${this.session.apiUrl}/simulations`,
            data,
        });
    }

    public updateSimulation(simulationId: string, data: IUpdateSimulationBody): Promise<IEntityDetailResponse<ISimulation>> {
        return this.runRequest({
            method: 'PUT',
            url: `${this.session.apiUrl}/simulations/${simulationId}`,
            data,
        });
    }

    public deleteSimulation(simulationId: string): Promise<void> {
        return this.runRequest({
            method: 'DELETE',
            url: `${this.session.apiUrl}/simulations/${simulationId}`,
        });
    }

    public getSimulationCandles(simulationId: string, symbolId: string, data: IFetchSimulationSymbolCandlesParams): Promise<ICandlePageApiResult> {
        return this.getPaginatedEntity({
            method: 'GET',
            url: `${this.session.apiUrl}/simulations/${simulationId}/symbols/${symbolId}/candles`,
        }, {
            ...data,
            query: this.generateQueryString({ interval: data.interval }),
        });
    }

    public getSimulationAggregates(simulationId: string, params: IFetchSimulationAggregatesParams): Promise<IAggregatePageApiResult> {
        return this.getPaginatedEntity({
            method: 'GET',
            url: `${this.session.apiUrl}/simulations/${simulationId}/aggregates`,
        }, {
            ...params,
            query: this.generateQueryString({ interval: params.interval }),
        });
    }

    public getSimulationSummary(simulationId: string, data: ISimulationSummaryParams): Promise<ISummary> {
        const query = this.generateQueryString({
            from: data.startDate,
            to: data.endDate,
            nominalInvest: data.nominalInvest,
            initialCapital: data.initialCapital,
            riskFactor: data.riskFactor,
            maxLeverage: data.maxLeverage,
            optimizationId: data.optimizationId,
            cashByInterval: data.cashByInterval,
        });
        const url = `${this.session.apiUrl}/simulations/${simulationId}/summary?${query}`;
        return this.runRequest({
            method: 'GET',
            url,
        });
    }

    public importSimulationCandles(simulationId?: string, symbolId?: string, from?: string, resetData?: boolean): Promise<IImportCandleApiResult> {
        return this.runRequest({
            method: 'POST',
            url: `${this.session.apiUrl}/simulations/$action/computeSimulations`,
            data: { simulationId, symbolId, from, importCandles: true, resetData },
        });
    }
}
