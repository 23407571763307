import * as _ from 'lodash';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import { ADD_NOTIFICATION, INotification, TOGGLE_NOTIFICATION_PANEL } from './actions';

export interface INotificationListState {
    items: { [requestId: string]: INotification };
}

export interface INotificationPanelState {
    visible: boolean;
}

const initialNotificationListState: INotificationListState = {
    items: {},
};

const initialState = {
    list: {
        ...initialNotificationListState,
    } as INotificationListState,
    panel: {
        visible: false,
    } as INotificationPanelState,
};

export const notificationsReducer = combineReducers({
    list: createReducer(initialState.list)

        .handleAction([ADD_NOTIFICATION],
            (state: INotificationListState, action: ActionType<typeof ADD_NOTIFICATION>): INotificationListState => {

                const notification = action.payload;
                const notif = state.items[notification.id];
                if (!notif) {
                    state.items[notification.id] = notification;
                    state.items[notification.id].index = _.keys(state.items).length;
                } else {
                    if (notification.title != null) {
                        notif.title = notification.title;
                    }
                    if (notification.phase != null) {
                        notif.phase = notification.phase;
                    }
                    if (notification.progress != null) {
                        notif.progress = notification.progress;
                    }
                    if (notification.diagnoses != null) {
                        notif.diagnoses = (notification.diagnoses).concat(notif.diagnoses || []);
                    }
                }

                return {
                    ...state,
                };
            }),

    panel: createReducer(initialState.panel)
        .handleAction([TOGGLE_NOTIFICATION_PANEL],
            (state: INotificationPanelState): INotificationPanelState => {
                return {
                    ...state,
                    visible: !state.visible,
                };
            }),
});

export default notificationsReducer;
