import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import { CandleInterval, IAggregate } from 'alpha-api-client';

import { reducePage } from '../../../common/reducersUtils';
import { IFiltersParams, ISortByParams } from '../../../common/types';
import { FETCH_CHART_AGGREGATES, FETCH_LIST_AGGREGATES, MORE_LIST_AGGREGATES, SELECT_AGGREGATES_CHART_INTERVAL } from './actions';

interface IAggregatesState {
    simulationId?: string;
    interval: CandleInterval;
    items: IAggregate[];
    page: number | null;
    hasNext: boolean;
    totalCount: number;
    query: {
        limit: string;
        filters: IFiltersParams[];
        sortBy: ISortByParams[];
    };
    isLoading: boolean;
}

interface IAggregatesStates {
    list: IAggregatesState;
    chart: IAggregatesState;
}

const initialState: IAggregatesStates = {
    list: {
        interval: CandleInterval.ONE_HOUR,
        items: [],
        page: null,
        hasNext: true,
        totalCount: 0,
        query: {
            limit: '300',
            filters: [],
            sortBy: [{
                field: 'date',
                direction: 'desc',
            }],
        },
        isLoading: false,
    },
    chart: {
        interval: CandleInterval.ONE_HOUR,
        items: [],
        page: null,
        hasNext: true,
        totalCount: 0,
        query: {
            limit: '100000',
            filters: [],
            sortBy: [{
                field: 'date',
                direction: 'asc',
            }],
        },
        isLoading: false,
    },
};

const aggregatesReducer = combineReducers({
    list: createReducer(initialState.list)

        .handleAction([FETCH_LIST_AGGREGATES.request],
            (state: IAggregatesState, action: ActionType<typeof FETCH_LIST_AGGREGATES.request>): IAggregatesState => {
                return {
                    ...state,
                    simulationId: action.payload.simulationId,
                    isLoading: true,
                };
            })

        .handleAction([MORE_LIST_AGGREGATES.request],
            (state: IAggregatesState): IAggregatesState => {
                return {
                    ...state,
                    isLoading: true,
                };
            })

        .handleAction([FETCH_LIST_AGGREGATES.failure, MORE_LIST_AGGREGATES.failure],
            (state: IAggregatesState): IAggregatesState => {
                return {
                    ...state,
                    simulationId: undefined,
                    isLoading: false,
                };
            })

        .handleAction([FETCH_LIST_AGGREGATES.success],
            (state: IAggregatesState, action: ActionType<typeof FETCH_LIST_AGGREGATES.success>): IAggregatesState => {
                return {
                    ...state,
                    ...reducePage(action.payload.result),
                    query: {
                        limit: action.payload.params.limit || state.query.limit,
                        filters: action.payload.params.filters || state.query.filters,
                        sortBy: action.payload.params.sortBy || state.query.sortBy,
                    },
                    isLoading: false,
                };
            })

        .handleAction([MORE_LIST_AGGREGATES.success],
            (state: IAggregatesState, action: ActionType<typeof MORE_LIST_AGGREGATES.success>): IAggregatesState => {
                return {
                    ...state,
                    ...reducePage(action.payload.result, state.items, action.payload.params.reverse),
                    isLoading: false,
                };
            }),
    chart: createReducer(initialState.chart)

        .handleAction([SELECT_AGGREGATES_CHART_INTERVAL],
            (state: IAggregatesState, action: ActionType<typeof SELECT_AGGREGATES_CHART_INTERVAL>): IAggregatesState => {
                return {
                    ...state,
                    interval: action.payload,
                };
            })

        .handleAction([FETCH_CHART_AGGREGATES.request],
            (state: IAggregatesState, action: ActionType<typeof FETCH_CHART_AGGREGATES.request>): IAggregatesState => {
                return {
                    ...state,
                    simulationId: action.payload.simulationId,
                    isLoading: true,
                };
            })

        .handleAction([FETCH_CHART_AGGREGATES.failure],
            (state: IAggregatesState): IAggregatesState => {
                return {
                    ...state,
                    simulationId: undefined,
                    isLoading: false,
                };
            })

        .handleAction([FETCH_CHART_AGGREGATES.success],
            (state: IAggregatesState, action: ActionType<typeof FETCH_CHART_AGGREGATES.success>): IAggregatesState => {
                return {
                    ...state,
                    ...reducePage(action.payload.result),
                    query: {
                        limit: action.payload.params.limit || state.query.limit,
                        filters: action.payload.params.filters || state.query.filters,
                        sortBy: action.payload.params.sortBy || state.query.sortBy,
                    },
                    isLoading: false,
                };
            }),
});

export default aggregatesReducer;
