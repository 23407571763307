import * as _ from 'lodash';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import { IEntityListCapabilities, IEntitySchemaDefinition, ISymbolRef, SymbolRefAction } from 'alpha-api-client';

import { reducePage } from '../../../common/reducersUtils';
import { IFiltersParams, ISortByParams } from '../../../common/types';
import { GET_SIMULATION } from '../../actions';
import { ADD_SIMULATION_SYMBOL, GET_SIMULATION_SYMBOL, GET_SIMULATION_SYMBOL_SCHEMA, LIST_SIMULATION_SYMBOLS, REMOVE_SIMULATION_SYMBOL } from './actions';

interface ISymbolsListState {
    simulationId?: string;
    items: ISymbolRef[];
    page: number | null;
    hasNext: boolean;
    totalCount: number;
    query: {
        filters: IFiltersParams[];
        sortBy: ISortByParams[];
        limit: string;
    };
    isLoading: boolean;
    action?: { name: SymbolRefAction } & IEntitySchemaDefinition;
    capabilities: IEntityListCapabilities;
}

export type ISymbolDetailState = ISymbolRef;

interface ISymbolsState {
    list: ISymbolsListState;
    detail: ISymbolDetailState | null;
}

const initialState: ISymbolsState = {
    list: {
        items: [],
        page: null,
        hasNext: true,
        totalCount: 0,
        query: {
            filters: [],
            sortBy: [{
                field: 'name',
            }],
            limit: '30',
        },
        isLoading: false,
        capabilities: {
            $canCreate: false,
        },
    },
    detail: null,
};

const computeFilters = (previousFilters: IFiltersParams[], simulationId?: string): IFiltersParams[] => {
    return _.unionBy(
        _.compact([
            simulationId ? { field: 'simulationId', operator: '=', value: simulationId } : undefined,
        ]),
        previousFilters,
        'field');
};

export const simulationSymbolsReducer = combineReducers({
    list: createReducer(initialState.list)

        .handleAction([GET_SIMULATION_SYMBOL_SCHEMA.request],
            (state: ISymbolsListState, action: ActionType<typeof GET_SIMULATION_SYMBOL_SCHEMA.request>): ISymbolsListState => {
                return {
                    ...state,
                    action: undefined,
                };
            })
        .handleAction([GET_SIMULATION_SYMBOL_SCHEMA.success],
            (state: ISymbolsListState, action: ActionType<typeof GET_SIMULATION_SYMBOL_SCHEMA.success>): ISymbolsListState => {
                return {
                    ...state,
                    action: {
                        name: action.payload.name,
                        $schema: action.payload.result.$schema,
                        $representation: action.payload.result.$representation,
                    },
                };
            })

        .handleAction([LIST_SIMULATION_SYMBOLS.request],
            (state: ISymbolsListState, action: ActionType<typeof LIST_SIMULATION_SYMBOLS.request>): ISymbolsListState => {
                if (state.simulationId !== action.payload.simulationId) {
                    return {
                        ...initialState.list,
                        query: {
                            ...initialState.list.query,
                            filters: computeFilters(initialState.list.query.filters, action.payload.simulationId),
                        },
                        simulationId: action.payload.simulationId,
                        isLoading: true,
                    };
                }
                return {
                    ...state,
                    isLoading: true,
                };
            })

        .handleAction([LIST_SIMULATION_SYMBOLS.failure],
            (state: ISymbolsListState): ISymbolsListState => {
                return {
                    ...state,
                    isLoading: false,
                };
            })

        .handleAction([LIST_SIMULATION_SYMBOLS.success],
            (state: ISymbolsListState, action: ActionType<typeof LIST_SIMULATION_SYMBOLS.success>): ISymbolsListState => {
                return {
                    ...state,
                    ...reducePage(action.payload.result),
                    query: {
                        limit: action.payload.params.limit || state.query.limit,
                        filters: action.payload.params.filters || state.query.filters,
                        sortBy: action.payload.params.sortBy || state.query.sortBy,
                    },
                    capabilities: action.payload.result.$capabilities,
                    isLoading: false,
                };
            })

        .handleAction([ADD_SIMULATION_SYMBOL.success],
            (state: ISymbolsListState, action: ActionType<typeof ADD_SIMULATION_SYMBOL.success>): ISymbolsListState => {
                return {
                    ...initialState.list,
                };
            })

        .handleAction([REMOVE_SIMULATION_SYMBOL.success],
            (state: ISymbolsListState, action: ActionType<typeof REMOVE_SIMULATION_SYMBOL.success>): ISymbolsListState => {
                return {
                    ...initialState.list,
                };
            })

        .handleAction([GET_SIMULATION.request],
            (state: ISymbolsListState, action: ActionType<typeof GET_SIMULATION.request>): ISymbolsListState => {
                return {
                    ...initialState.list,
                };
            }),

    detail: createReducer(initialState.detail)
        .handleAction([GET_SIMULATION_SYMBOL.success],
            (state: ISymbolDetailState | null, action: ActionType<typeof GET_SIMULATION_SYMBOL.success>): ISymbolDetailState => {
                return {
                    ...state,
                    ...action.payload,
                };
            }),
});

export default simulationSymbolsReducer;
