import _ from 'lodash';

import { IApiCommonPageResult } from 'alpha-api-client';

import { IFiltersParams } from './types';

export function reduceQueryFilter(queryFilter: IFiltersParams[]) {
    return _.reduce(queryFilter, (acc, filter) => {
        if (filter.field !== 'id') {
            acc[filter.field] = {
                [filter.operator || '=']: filter.value,
            };
        }
        return acc;
    }, {} as any);
}

export function reducePage<T>(page: IApiCommonPageResult<T> = {} as any, itemsToMerge: T[] = [], reverse = false) {
    return {
        items: page.$items ? (reverse ? page.$items.concat(itemsToMerge) : itemsToMerge.concat(page.$items)) : [],
        hasNext: page.$hasNext || false,
        totalCount: page.$totalCount || 0,
        page: page.$page,
    };
}
