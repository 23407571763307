import { Moment } from 'moment';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { CandleInterval, IAggregatePageApiResult } from 'alpha-api-client';

import { IFetchParams } from '../../../common/types';

export interface IFetchSimulationAggregatesActionParams extends IFetchParams {
    simulationId: string;
    startDate?: Moment;
    endDate?: Moment;
    interval?: CandleInterval;
}

export interface IFetchSimulationAggregatesActionResult {
    result: IAggregatePageApiResult;
    params: IFetchSimulationAggregatesActionParams;
}

export const SELECT_AGGREGATES_CHART_INTERVAL = createAction('SELECT_AGGREGATES_CHART_INTERVAL', (action) => {
    return (interval: CandleInterval) => action(interval);
});

export const FETCH_LIST_AGGREGATES = createAsyncAction(
    'FETCH_LIST_AGGREGATES_REQUEST',
    'FETCH_LIST_AGGREGATES_SUCCESS',
    'FETCH_LIST_AGGREGATES_FAILURE',
)<IFetchSimulationAggregatesActionParams, IFetchSimulationAggregatesActionResult, string>();

export const MORE_LIST_AGGREGATES = createAsyncAction(
    'MORE_LIST_AGGREGATES_REQUEST',
    'MORE_LIST_AGGREGATES_SUCCESS',
    'MORE_LIST_AGGREGATES_FAILURE',
)<IFetchSimulationAggregatesActionParams, IFetchSimulationAggregatesActionResult, string>();

export const FETCH_CHART_AGGREGATES = createAsyncAction(
    'FETCH_CHART_AGGREGATES_REQUEST',
    'FETCH_CHART_AGGREGATES_SUCCESS',
    'FETCH_CHART_AGGREGATES_FAILURE',
)<IFetchSimulationAggregatesActionParams, IFetchSimulationAggregatesActionResult, string>();
