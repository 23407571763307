import { createAsyncAction } from 'typesafe-actions';

import { IEntityListResponse, IFetchPageParams, ISymbol } from 'alpha-api-client';

import { IFetchParams } from '../common/types';

export type IListSymbolsActionParams = IFetchPageParams;

export interface IListSymbolsActionResult {
    params: IFetchParams;
    result: IEntityListResponse<ISymbol>;
}

export const LIST_SYMBOLS = createAsyncAction(
    'LIST_SYMBOLS_REQUEST',
    'LIST_SYMBOLS_SUCCESS',
    'LIST_SYMBOLS_FAILURE',
)<IListSymbolsActionParams, IListSymbolsActionResult, string>();
