import { IApiJobHttpResponse } from './common';
import { ICandleIndicators } from './indicators';
import { IApiCommonPageResult } from './pagination';

export enum CandleInterval {
    // ONE_MINUTE = '1 minute',
    ONE_HOUR = '1 hour',
    ONE_DAY = '1 day',
    ONE_WEEK = '1 week',
}

export interface ICandle {
    symbolId: string;
    open: number;
    high: number;
    low: number;
    close: number;
    volume: number;
    date: string;
    indicators?: ICandleIndicators;
}

export type ICandlePageApiResult = IApiCommonPageResult<ICandle>;

export type IImportCandleApiResult = IApiJobHttpResponse;
