/* eslint-disable no-console */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IncomingHttpHeaders } from 'http';
import * as _ from 'lodash';
import * as querystring from 'querystring';
import { ParsedUrlQueryInput } from 'querystring';
import * as stream from 'stream';

import { IFetchParams } from 'alpha-screener-front/src/content/common/types';

import { AlphaError } from './AlphaError';
import { SessionContext } from './SessionContext';
import { IAlphaApiHttpRequest, IAlphaHttpResponseStream, IFormData } from './types/common';

const DEBUG = process.env.DEBUG === '1';

export abstract class AbstractClient {

    private static extractFileNameFromHeaders(headers: any): string {
        const matches = headers['content-disposition'].match(/attachment; filename="(.*)"/);
        if (!matches || !matches[1]) {
            throw new AlphaError('No filename found in content-disposition header');
        }
        return decodeURIComponent(matches[1]);
    }

    protected session: SessionContext;

    constructor(session: SessionContext) {
        this.session = session;
    }

    protected async runRequest<T>(request: IAlphaApiHttpRequest): Promise<T> {
        return this.sendRequestAndInspectErrors<T>(request);
    }

    protected async runRequestAsMultipartFormBody<T>(request: IAlphaApiHttpRequest, form: IFormData): Promise<T> {
        request.data = form;
        request.headers = {
			...request.headers,
			...(form.getHeaders?.() || {}),
			...(this.session.additionalRequestHeaders?.() || {}),
        };
        return this.sendRequestAndInspectErrors<T>(request);
    }

    protected generateQueryString(params: ParsedUrlQueryInput): string {
        return querystring.stringify(params);
    }

    protected async getResourceStream(request: IAlphaApiHttpRequest): Promise<IAlphaHttpResponseStream> {
        request.headers = {
			...(this.session.additionalRequestHeaders?.() || {}),
			...(this.session.accessToken ? { Authorization: this.session.accessToken } : {}),
        };
        const config: AxiosRequestConfig = {
			validateStatus: () => true,
			headers: request.headers,
			responseType: 'stream',
			...request,
        };
        const response = await axios.request<stream.Readable>(config);
        await this.checkStatusAndInspectDiagnoses(response);
        const filename = AbstractClient.extractFileNameFromHeaders(response.headers);

        return {
			stream: response.data,
			contentType: response.headers['content-type'],
			filename,
        };
    }

    protected getPaginatedEntity<T>(request: IAlphaApiHttpRequest, params: IFetchParams): Promise<T> {
        // console.log('PARAMS =', JSON.stringify(params, null, 2));

        const qsFilters = params?.filters?.length ? `&${_.map(params?.filters, (filter) => {
			return filter.field + (filter.operator || '=') + filter.value;
		}).join('&')}` : '';

        const qsSortBy = params?.sortBy?.length ? `&sortBy=${_.map(params?.sortBy, (param) => {
			return `${param.field}~${param.direction || 'asc'}`;
		}).join('&')}` : '';

        const qsPage = params?.page ? `page=${params.page}` : 'page=1';
        const qsLimit = params?.limit ? `&limit=${params?.limit}` : '';
        const qsReverse = params?.reverse ? `&reverse=${params.reverse}` : '';
        const qsIncludes = params?.includes ? `&includes=${params.includes}` : '';
        const query = params?.query ? `&${params?.query}` : '';
        return this.runRequest({
			...request,
			url: `${request.url}?${qsPage}${qsLimit}${qsFilters}${query}${qsSortBy}${qsReverse}${qsIncludes}`,
        });
    }

    private async sendRequestAndInspectErrors<T>(request: IAlphaApiHttpRequest): Promise<T> {
        const authHeaders: IncomingHttpHeaders = {
			...(this.session.additionalRequestHeaders?.() || {}),
			...(this.session.accessToken ? { Authorization: this.session.accessToken } : {}),
        };
        request.headers ? _.merge(request.headers, authHeaders) : request.headers = authHeaders;
        const config: AxiosRequestConfig = {
			validateStatus: () => true,
			maxContentLength: Infinity,
			maxBodyLength: Infinity,
			...request,
        };
        DEBUG && console.log(JSON.stringify(config, null, 2));
        const response = await axios.request<T>(config);
        DEBUG && console.log(response.status, JSON.stringify(response.data, null, 2));
        await this.checkStatusAndInspectDiagnoses(response);
        if (response.status === 204) {
            return {} as T;
        }
        return response.data;
    }

    private async checkStatusAndInspectDiagnoses(response: AxiosResponse) {
        if (response.status >= 400) {
            let data: any = response.data;
            if (data instanceof stream.Readable) {
                data = await new Promise((resolve, reject) => {
					let body: any = '';
					response.data.on('data', (chunk: any) => {
						body += chunk;
					});
					response.data.on('end', () => {
						try {
						    const json = JSON.parse(body);
						    resolve(json);
						} catch (e) {
						    reject(new Error(`JSON.parse(body) failed : body = ${body}`));
						}
					});
					response.data.on('error', (e: Error) => {
						reject(e);
					});
				});
            }

            const dataStr = typeof data === 'string' ? data : undefined;
            if (dataStr || data.$diagnoses) {
                // TODO: Changer le traitement des erreurs avec potentiellement plusieurs diagnoses.
                const errorDiagnose = _.find(data?.$diagnoses, d => d.$severity === 'error');
                if (!errorDiagnose) {
                    throw new AlphaError(dataStr || 'An unexpected error occurred',
						[],
						response.status,
						JSON.stringify(data, null, 2));
                }
                throw new AlphaError(`${errorDiagnose.$message} (${errorDiagnose.$detail})`,
					data.$diagnoses,
					response.status,
					errorDiagnose.$detail,
					+(errorDiagnose.$code || 0));
            }
        }
    }
}
