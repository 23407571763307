import { createAction } from 'typesafe-actions';

import { IDiagnose } from 'alpha-api-client';

export type NotificationType = 'job' | 'req' | 'diagnose';

export interface INotification {
    id: string;
    index: number;
    type: NotificationType;
    diagnoses?: IDiagnose[];
    progress?: number;
    title?: string;
    phase?: string;
}

export const ADD_NOTIFICATION = createAction('ADD_NOTIFICATION', (action) => {
    return (notif: INotification) => action(notif);
});

export const TOGGLE_NOTIFICATION_PANEL = createAction('TOGGLE_NOTIFICATION_PANEL', (action) => {
    return () => action();
});
