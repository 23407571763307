import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';

import { RootAction, RootState } from 'alpha-screener-types';

import services from '../services/index';
import rootEpic from './root-epic';
import createRootReducer from './root-reducer';
import { composeEnhancers } from './utils';

export const history = createBrowserHistory();
const routerMiddleware = createRouterMiddleware(history);

export const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, any>({
    dependencies: services,
});

// configure middlewares
const middlewares = [epicMiddleware, routerMiddleware];
// compose enhancers
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

// rehydrate state on app start
const initialState = {};
// create store
const rootReducer = createRootReducer(history);
const store = createStore(rootReducer, initialState, enhancer);

epicMiddleware.run(rootEpic);

// export store singleton instance
export default store;
