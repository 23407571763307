import { AbstractClient } from '../../AbstractClient';
import { IApiJobHttpResponse } from '../../types/common';
import { IEntityDetailResponse, IEntitySchemaDefinition } from '../../types/entities';
import {
    IComputeOptimizationsRulesParams,
    IOptimization,
    IOptimizationCreate,
    IOptimizationsPageApiResult,
    IOptimizationUpdate,
    OptimizationAction,
} from '../../types/optimizations';
import { IFetchPageParams } from '../../types/pagination';

export class SimulationOptimizationsClient extends AbstractClient {

    public getSimulationOptimizationSchema(simulationId: string, schema: OptimizationAction): Promise<IEntitySchemaDefinition> {
        return this.runRequest({
            method: 'GET',
            url: `${this.session.apiUrl}/simulations/${simulationId}/optimizations/$schema/${schema}`,
        });
    }

    public listSimulationOptimizations(simulationId: string, params: IFetchPageParams): Promise<IOptimizationsPageApiResult> {
        return this.getPaginatedEntity({
            method: 'GET',
            url: `${this.session.apiUrl}/simulations/${simulationId}/optimizations`,
        }, params);
    }

    public getSimulationOptimization(simulationId: string, optimizationId: string): Promise<IEntityDetailResponse<IOptimization>> {
        return this.runRequest({
            method: 'GET',
            url: `${this.session.apiUrl}/simulations/${simulationId}/optimizations/${optimizationId}`,
        });
    }

    public createSimulationOptimization(simulationId: string, data: IOptimizationCreate): Promise<IEntityDetailResponse<IOptimization>> {
        return this.runRequest({
            method: 'POST',
            url: `${this.session.apiUrl}/simulations/${simulationId}/optimizations`,
            data: {
                alias: data.alias,
            },
        });
    }

    public updateSimulationOptimization(simulationId: string,
                                        optimizationId: string,
                                        data: IOptimizationUpdate): Promise<IEntityDetailResponse<IOptimization>> {
        return this.runRequest({
            method: 'PUT',
            url: `${this.session.apiUrl}/simulations/${simulationId}/optimizations/${optimizationId}`,
            data: {
                alias: data.alias,
                config: data.config,
            },
        });
    }

    public deleteSimulationOptimization(simulationId: string, optimizationId: string): Promise<void> {
        return this.runRequest({
            method: 'DELETE',
            url: `${this.session.apiUrl}/simulations/${simulationId}/optimizations/${optimizationId}`,
        });
    }

    public computeSimulationOptimization(simulationId: string, optimizationId: string, data: IComputeOptimizationsRulesParams): Promise<IApiJobHttpResponse> {
        return this.runRequest({
            method: 'POST',
            url: `${this.session.apiUrl}/simulations/${simulationId}/optimizations/${optimizationId}/$action/optimize`,
            data,
        });
    }

}
