import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { IFetchPageParams } from 'alpha-api-client';
import { RootAction, RootState, Services } from 'alpha-screener-types';

import store from '../../../../store/index';
import { computeFiltersWithDateRange } from '../../../common/epicsUtils';
import { FETCH_CHART_CANDLES, FETCH_LIST_CANDLES, IMPORT_SYMBOL_CANDLES, MORE_LIST_CANDLES } from './actions';

export const fetchListCandlesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(FETCH_LIST_CANDLES.request)),
        mergeMap(async (action) => {
            const candlesState = store.getState().candles;

            const startDate = action.payload.startDate;
            const endDate = action.payload.endDate;
            const filters = computeFiltersWithDateRange('date', action.payload.filters || candlesState.list.query.filters, startDate, endDate);

            const sortBy = action.payload.sortBy || candlesState.list.query.sortBy;
            const limit = action.payload.limit || candlesState.list.query.limit;

            const { simulationId, symbolId, ..._params } = action.payload;
            const params: IFetchPageParams = {
                ..._params,
                filters,
                sortBy,
                limit,
            };

            try {
                const result = await api.apiClient.simulations.getSimulationCandles(simulationId, symbolId, params);
                return FETCH_LIST_CANDLES.success({ params: action.payload, result });
            } catch (e) {
                return FETCH_LIST_CANDLES.failure(e.message);
            }
        }),
    );
};

export const moreListCandlesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(MORE_LIST_CANDLES.request)),
        mergeMap(async (action) => {
            const candlesState = store.getState().candles;
            if (!candlesState.list.hasNext) {
                throw new Error('NO NEXT');
            }
            const stateQuery = candlesState.list.query || {};
            const { simulationId, symbolId, ..._params } = action.payload;
            const params: IFetchPageParams = {
                ...stateQuery,
                ..._params,
                page: (candlesState.list.page || 1) + 1,
            };

            try {
                const result = await api.apiClient.simulations.getSimulationCandles(simulationId, symbolId, params);
                return MORE_LIST_CANDLES.success({ params: action.payload, result });
            } catch (e) {
                return MORE_LIST_CANDLES.failure(e.message);
            }
        }),
    );
};

export const fetchChartCandlesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(FETCH_CHART_CANDLES.request)),
        mergeMap(async (action) => {
            const candlesState = store.getState().candles;

            const startDate = action.payload.startDate;
            const endDate = action.payload.endDate;
            const filters = computeFiltersWithDateRange('date', action.payload.filters || candlesState.chart.query.filters, startDate, endDate);

            const sortBy = action.payload.sortBy || candlesState.chart.query.sortBy;
            const limit = action.payload.limit || candlesState.chart.query.limit;

            const { simulationId, symbolId, ..._params } = action.payload;
            const params: IFetchPageParams = {
                ..._params,
                filters,
                sortBy,
                limit,
            };

            try {
                const result = await api.apiClient.simulations.getSimulationCandles(simulationId, symbolId, params);
                return FETCH_CHART_CANDLES.success({ params: action.payload, result });
            } catch (e) {
                return FETCH_CHART_CANDLES.failure(e.message);
            }
        }),
    );
};

export const importSymbolCandlesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(IMPORT_SYMBOL_CANDLES.request)),
        mergeMap(async (action) => {
            const symbolId = action.payload.symbolId;
            const fromDate = action.payload.fromDate;
            const simulationId = action.payload.simulationId;
            const resetData = action.payload.resetData;
            try {
                const result = await api.apiClient.simulations.importSimulationCandles(simulationId, symbolId, fromDate, resetData);
                return IMPORT_SYMBOL_CANDLES.success({
                    params: {
                        simulationId,
                        symbolId,
                        fromDate,
                    },
                    result: result as any,
                });
            } catch (e) {
                return IMPORT_SYMBOL_CANDLES.failure(e.message);
            }
        }),
    );
};
