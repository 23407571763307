import { AbstractClient } from '../../AbstractClient';
import { IFetchPageParams } from '../../types/pagination';
import { IPositionsPageApiResult } from '../../types/positions';

export class SimulationPositionsClient extends AbstractClient {

    public getSimulationPositions(simulationId: string, params: IFetchPageParams): Promise<IPositionsPageApiResult> {
        return this.getPaginatedEntity({
            method: 'GET',
            url: `${this.session.apiUrl}/simulations/${simulationId}/positions`,
        }, params);
    }

}
