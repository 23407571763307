import { IEntityListResponse } from './entities';
import { SymbolKind } from './symbols';

export enum SymbolRefAction {
    ADD = 'add',
}

export interface ISymbolRef {
    id: string;
    name: string;
    kind: SymbolKind;
    leverage: number;
    description: string;
    icon?: string;
    riskLimit?: number;
}

export interface ISymbolRefAdd {
    symbolId: string;
    leverage: number;
}

export interface ISymbolRefUpdate {
    leverage: number;
}

export type ISymbolRefPageApiResult = IEntityListResponse<ISymbolRef>;

export interface ISimulationSymbolSummaryParams {
    optimizationId?: string;
    startDate?: string;
    endDate?: string;
    nominalInvest?: number;
    initialCapital?: number | null;
    riskFactor?: number | null;
    maxLeverage?: number | null;
}
