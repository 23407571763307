import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import { IPosition } from 'alpha-api-client';

import { reducePage } from '../../../common/reducersUtils';
import { IFiltersParams, ISortByParams } from '../../../common/types';
import { SET_GLOBAL_DATES_RANGE } from '../../../global/actions';
import { GET_SIMULATION, SET_INITIAL_CAPITAL, SET_MAX_LEVERAGE, SET_NOMINAL_INVEST, SET_RISK_FACTOR } from '../../actions';
import { SELECT_OPTIMIZATION } from '../optimizations/actions';
import {
    FETCH_SIMULATION_CLOSED_POSITIONS,
    FETCH_SIMULATION_OPENED_POSITIONS,
    FETCH_SIMULATION_SYMBOL_CLOSED_POSITIONS,
    FETCH_SIMULATION_SYMBOL_OPENED_POSITIONS,
    MORE_SIMULATION_CLOSED_POSITIONS,
    MORE_SIMULATION_OPENED_POSITIONS,
    MORE_SIMULATION_SYMBOL_CLOSED_POSITIONS,
    MORE_SIMULATION_SYMBOL_OPENED_POSITIONS,
} from './actions';

export interface IPositionsStateQuery {
    limit: string;
    filters: IFiltersParams[];
    sortBy: ISortByParams[];
    query?: string;
}

interface ISimulationPositionsState {
    items: IPosition[];
    page: number | null;
    hasNext: boolean;
    totalCount: number;
    query: IPositionsStateQuery;
    isLoading: boolean;
}

interface ISimulationPositionsStates {
    simulationId?: string;
    opened: ISimulationPositionsState;
    closed: ISimulationPositionsState;
}

interface ISimulationSymbolPositionsStates extends ISimulationPositionsStates {
    symbolId?: string;
}

interface IPositionsStates {
    simulation: ISimulationPositionsStates;
    symbol: ISimulationSymbolPositionsStates;
}

const defaultPositionState: () => ISimulationPositionsState = () => ({
    items: [],
    page: null,
    hasNext: false,
    totalCount: 0,
    query: {
        limit: '300',
        filters: [],
        sortBy: [{
            field: 'date',
            direction: 'desc',
        }],
        query: '',
    },
    isLoading: false,
});

const initialState: IPositionsStates = {
    simulation: {
        opened: defaultPositionState(),
        closed: defaultPositionState(),
    },
    symbol: {
        opened: defaultPositionState(),
        closed: defaultPositionState(),
    },
};

const candlesReducer = combineReducers({
    simulation: createReducer(initialState.simulation)

        .handleAction([FETCH_SIMULATION_OPENED_POSITIONS.request],
            (state: ISimulationPositionsStates, action: ActionType<typeof FETCH_SIMULATION_OPENED_POSITIONS.request>): ISimulationPositionsStates => {
                if (state.simulationId != null && state.simulationId !== action.payload.simulationId) {
                    return {
                        ...initialState.simulation,
                        simulationId: action.payload.simulationId,
                        opened: {
                            ...initialState.simulation.opened,
                            isLoading: true,
                        },
                    };
                }
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        isLoading: true,
                    },
                };
            })

        .handleAction([MORE_SIMULATION_OPENED_POSITIONS.request],
            (state: ISimulationPositionsStates): ISimulationPositionsStates => {
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        isLoading: true,
                    },
                };
            })

        .handleAction([FETCH_SIMULATION_OPENED_POSITIONS.failure, MORE_SIMULATION_OPENED_POSITIONS.failure],
            (state: ISimulationPositionsStates): ISimulationPositionsStates => {
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        isLoading: false,
                    },
                };
            })

        .handleAction([FETCH_SIMULATION_OPENED_POSITIONS.success],
            (state: ISimulationPositionsStates, action: ActionType<typeof FETCH_SIMULATION_OPENED_POSITIONS.success>): ISimulationPositionsStates => {
                const simulationChanged = action.payload.simulationId !== state.simulationId;
                return {
                    simulationId: action.payload.simulationId,
                    ...(simulationChanged ? initialState.simulation : state),
                    ...state,
                    opened: {
                        ...state.opened,
                        ...reducePage(action.payload.result),
                        query: {
                            limit: action.payload.params.limit || state.opened.query.limit,
                            filters: action.payload.params.filters || state.opened.query.filters,
                            sortBy: action.payload.params.sortBy || state.opened.query.sortBy,
                            query: action.payload.params.query || state.opened.query.query,
                        },
                        isLoading: false,
                    },
                };
            })

        .handleAction([MORE_SIMULATION_OPENED_POSITIONS.success],
            (state: ISimulationPositionsStates, action: ActionType<typeof MORE_SIMULATION_OPENED_POSITIONS.success>): ISimulationPositionsStates => {
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        ...reducePage(action.payload.result, state.opened.items, action.payload.params.reverse),
                        isLoading: false,
                    },
                };
            })

        .handleAction([FETCH_SIMULATION_CLOSED_POSITIONS.request],
            (state: ISimulationPositionsStates, action: ActionType<typeof FETCH_SIMULATION_CLOSED_POSITIONS.request>): ISimulationPositionsStates => {
                if (state.simulationId != null && state.simulationId !== action.payload.simulationId) {
                    return {
                        ...initialState.simulation,
                        simulationId: action.payload.simulationId,
                        closed: {
                            ...initialState.simulation.closed,
                            isLoading: true,
                        },
                    };
                }
                return {
                    ...state,
                    closed: {
                        ...state.closed,
                        isLoading: true,
                    },
                };
            })

        .handleAction([MORE_SIMULATION_CLOSED_POSITIONS.request],
            (state: ISimulationPositionsStates): ISimulationPositionsStates => {
                return {
                    ...state,
                    closed: {
                        ...state.closed,
                        isLoading: true,
                    },
                };
            })

        .handleAction([FETCH_SIMULATION_CLOSED_POSITIONS.failure, FETCH_SIMULATION_CLOSED_POSITIONS.failure],
            (state: ISimulationPositionsStates): ISimulationPositionsStates => {
                return {
                    ...state,
                    closed: {
                        ...state.closed,
                        isLoading: false,
                    },
                };
            })

        .handleAction([FETCH_SIMULATION_CLOSED_POSITIONS.success],
            (state: ISimulationPositionsStates, action: ActionType<typeof FETCH_SIMULATION_CLOSED_POSITIONS.success>): ISimulationPositionsStates => {
                const simulationChanged = action.payload.simulationId !== state.simulationId;
                return {
                    simulationId: action.payload.simulationId,
                    ...(simulationChanged ? initialState.simulation : state),
                    ...state,
                    closed: {
                        ...state.closed,
                        ...reducePage(action.payload.result),
                        query: {
                            limit: action.payload.params.limit || state.closed.query.limit,
                            filters: action.payload.params.filters || state.closed.query.filters,
                            sortBy: action.payload.params.sortBy || state.closed.query.sortBy,
                            query: action.payload.params.query || state.closed.query.query,
                        },
                        isLoading: false,
                    },
                };
            })

        .handleAction([MORE_SIMULATION_CLOSED_POSITIONS.success],
            (state: ISimulationPositionsStates, action: ActionType<typeof MORE_SIMULATION_CLOSED_POSITIONS.success>): ISimulationPositionsStates => {
                return {
                    ...state,
                    closed: {
                        ...state.closed,
                        ...reducePage(action.payload.result, state.closed.items, action.payload.params.reverse),
                        isLoading: false,
                    },
                };
            })

        .handleAction([GET_SIMULATION.request],
            (state: ISimulationPositionsStates, action: ActionType<typeof GET_SIMULATION.request>): ISimulationPositionsStates => {
                return {
                    ...initialState.simulation,
                };
            })

        .handleAction([SELECT_OPTIMIZATION],
            (state: ISimulationPositionsStates, action: ActionType<typeof SELECT_OPTIMIZATION>): ISimulationPositionsStates => {
                return {
                    ...initialState.simulation,
                };
            })

        .handleAction([SET_NOMINAL_INVEST],
            (state: ISimulationPositionsStates, action: ActionType<typeof SELECT_OPTIMIZATION>): ISimulationPositionsStates => {
                return {
                    ...state,
                    opened: initialState.simulation.opened,
                    closed: initialState.simulation.closed,
                };
            })

        .handleAction([SET_INITIAL_CAPITAL],
            (state: ISimulationPositionsStates, action: ActionType<typeof SET_INITIAL_CAPITAL>): ISimulationPositionsStates => {
                return {
                    ...state,
                    opened: initialState.simulation.opened,
                    closed: initialState.simulation.closed,
                };
            })
        .handleAction([SET_RISK_FACTOR],
            (state: ISimulationPositionsStates, action: ActionType<typeof SET_RISK_FACTOR>): ISimulationPositionsStates => {
                return {
                    ...state,
                    opened: initialState.simulation.opened,
                    closed: initialState.simulation.closed,
                };
            })
        .handleAction([SET_MAX_LEVERAGE],
            (state: ISimulationPositionsStates, action: ActionType<typeof SET_MAX_LEVERAGE>): ISimulationPositionsStates => {
                return {
                    ...state,
                    opened: initialState.simulation.opened,
                    closed: initialState.simulation.closed,
                };
            })

        .handleAction([SET_GLOBAL_DATES_RANGE],
            (state: ISimulationPositionsStates, action: ActionType<typeof SET_GLOBAL_DATES_RANGE>): ISimulationPositionsStates => {
                return {
                    ...state,
                    opened: initialState.simulation.opened,
                    closed: initialState.simulation.closed,
                };
            }),

    symbol: createReducer(initialState.symbol)

        .handleAction([FETCH_SIMULATION_SYMBOL_OPENED_POSITIONS.request], (
            state: ISimulationSymbolPositionsStates,
            action: ActionType<typeof FETCH_SIMULATION_SYMBOL_OPENED_POSITIONS.request>,
        ): ISimulationSymbolPositionsStates => {
            if (
                (state.simulationId != null && state.simulationId !== action.payload.simulationId) ||
                (state.symbolId != null && state.symbolId !== action.payload.symbolId)
            ) {
                return {
                    ...initialState.symbol,
                    simulationId: action.payload.simulationId,
                    symbolId: action.payload.symbolId,
                    opened: {
                        ...initialState.symbol.opened,
                        isLoading: true,
                    },
                };
            }
            return {
                ...state,
                opened: {
                    ...state.opened,
                    isLoading: true,
                },
            };
        })

        .handleAction([MORE_SIMULATION_SYMBOL_OPENED_POSITIONS.request],
            (state: ISimulationSymbolPositionsStates): ISimulationSymbolPositionsStates => {
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        isLoading: true,
                    },
                };
            })

        .handleAction([FETCH_SIMULATION_SYMBOL_OPENED_POSITIONS.failure, MORE_SIMULATION_SYMBOL_OPENED_POSITIONS.failure],
            (state: ISimulationSymbolPositionsStates): ISimulationSymbolPositionsStates => {
                return {
                    ...state,
                    opened: {
                        ...state.opened,
                        isLoading: false,
                    },
                };
            })

        .handleAction([FETCH_SIMULATION_SYMBOL_OPENED_POSITIONS.success], (
            state: ISimulationSymbolPositionsStates,
            action: ActionType<typeof FETCH_SIMULATION_SYMBOL_OPENED_POSITIONS.success>,
        ): ISimulationSymbolPositionsStates => {
            const simulationChanged = action.payload.simulationId !== state.simulationId || action.payload.symbolId !== state.symbolId;
            return {
                simulationId: action.payload.simulationId,
                symbolId: action.payload.symbolId,
                ...(simulationChanged ? initialState.symbol : state),
                ...state,
                opened: {
                    ...state.opened,
                    ...reducePage(action.payload.result),
                    query: {
                        limit: action.payload.params.limit || state.opened.query.limit,
                        filters: action.payload.params.filters || state.opened.query.filters,
                        sortBy: action.payload.params.sortBy || state.opened.query.sortBy,
                        query: action.payload.params.query || state.opened.query.query,
                    },
                    isLoading: false,
                },
            };
        })

        .handleAction([MORE_SIMULATION_SYMBOL_OPENED_POSITIONS.success], (
            state: ISimulationSymbolPositionsStates,
            action: ActionType<typeof MORE_SIMULATION_SYMBOL_OPENED_POSITIONS.success>,
        ): ISimulationSymbolPositionsStates => {
            return {
                ...state,
                opened: {
                    ...state.opened,
                    ...reducePage(action.payload.result, state.opened.items, action.payload.params.reverse),
                    isLoading: false,
                },
            };
        })

        .handleAction([FETCH_SIMULATION_SYMBOL_CLOSED_POSITIONS.request], (
            state: ISimulationSymbolPositionsStates,
            action: ActionType<typeof FETCH_SIMULATION_SYMBOL_CLOSED_POSITIONS.request>,
        ): ISimulationSymbolPositionsStates => {
            if (
                (state.simulationId != null && state.simulationId !== action.payload.simulationId) ||
                (state.symbolId != null && state.symbolId !== action.payload.symbolId)
            ) {
                return {
                    ...initialState.symbol,
                    simulationId: action.payload.simulationId,
                    symbolId: action.payload.symbolId,
                    closed: {
                        ...initialState.symbol.closed,
                        isLoading: true,
                    },
                };
            }
            return {
                ...state,
                closed: {
                    ...state.closed,
                    isLoading: true,
                },
            };
        })

        .handleAction([MORE_SIMULATION_SYMBOL_CLOSED_POSITIONS.request],
            (state: ISimulationSymbolPositionsStates): ISimulationSymbolPositionsStates => {
                return {
                    ...state,
                    closed: {
                        ...state.closed,
                        isLoading: true,
                    },
                };
            })

        .handleAction([FETCH_SIMULATION_SYMBOL_CLOSED_POSITIONS.failure, FETCH_SIMULATION_SYMBOL_CLOSED_POSITIONS.failure],
            (state: ISimulationSymbolPositionsStates): ISimulationSymbolPositionsStates => {
                return {
                    ...state,
                    closed: {
                        ...state.closed,
                        isLoading: false,
                    },
                };
            })

        .handleAction([FETCH_SIMULATION_SYMBOL_CLOSED_POSITIONS.success], (
            state: ISimulationSymbolPositionsStates,
            action: ActionType<typeof FETCH_SIMULATION_SYMBOL_CLOSED_POSITIONS.success>,
        ): ISimulationSymbolPositionsStates => {
            const simulationChanged = action.payload.simulationId !== state.simulationId || action.payload.symbolId !== state.symbolId;
            return {
                simulationId: action.payload.simulationId,
                symbolId: action.payload.symbolId,
                ...(simulationChanged ? initialState.symbol : state),
                ...state,
                closed: {
                    ...state.closed,
                    ...reducePage(action.payload.result),
                    query: {
                        limit: action.payload.params.limit || state.closed.query.limit,
                        filters: action.payload.params.filters || state.closed.query.filters,
                        sortBy: action.payload.params.sortBy || state.closed.query.sortBy,
                        query: action.payload.params.query || state.closed.query.query,
                    },
                    isLoading: false,
                },
            };
        })

        .handleAction([MORE_SIMULATION_SYMBOL_CLOSED_POSITIONS.success], (
            state: ISimulationSymbolPositionsStates,
            action: ActionType<typeof MORE_SIMULATION_SYMBOL_CLOSED_POSITIONS.success>,
        ): ISimulationSymbolPositionsStates => {
            return {
                ...state,
                closed: {
                    ...state.closed,
                    ...reducePage(action.payload.result, state.closed.items, action.payload.params.reverse),
                    isLoading: false,
                },
            };
        })

        .handleAction([GET_SIMULATION.request],
            (state: ISimulationSymbolPositionsStates, action: ActionType<typeof GET_SIMULATION.request>): ISimulationSymbolPositionsStates => {
                return {
                    ...initialState.symbol,
                };
            })

        .handleAction([SELECT_OPTIMIZATION],
            (state: ISimulationSymbolPositionsStates, action: ActionType<typeof SELECT_OPTIMIZATION>): ISimulationSymbolPositionsStates => {
                return {
                    ...initialState.symbol,
                };
            })

        .handleAction([SET_NOMINAL_INVEST],
            (state: ISimulationSymbolPositionsStates, action: ActionType<typeof SELECT_OPTIMIZATION>): ISimulationSymbolPositionsStates => {
                return {
                    ...state,
                    opened: initialState.symbol.opened,
                    closed: initialState.symbol.closed,
                };
            })

        .handleAction([SET_INITIAL_CAPITAL],
            (state: ISimulationSymbolPositionsStates, action: ActionType<typeof SET_INITIAL_CAPITAL>): ISimulationSymbolPositionsStates => {
                return {
                    ...state,
                    opened: initialState.symbol.opened,
                    closed: initialState.symbol.closed,
                };
            })
        .handleAction([SET_RISK_FACTOR],
            (state: ISimulationSymbolPositionsStates, action: ActionType<typeof SET_RISK_FACTOR>): ISimulationSymbolPositionsStates => {
                return {
                    ...state,
                    opened: initialState.symbol.opened,
                    closed: initialState.symbol.closed,
                };
            })
        .handleAction([SET_MAX_LEVERAGE],
            (state: ISimulationSymbolPositionsStates, action: ActionType<typeof SET_MAX_LEVERAGE>): ISimulationSymbolPositionsStates => {
                return {
                    ...state,
                    opened: initialState.symbol.opened,
                    closed: initialState.symbol.closed,
                };
            })

        .handleAction([SET_GLOBAL_DATES_RANGE],
            (state: ISimulationSymbolPositionsStates, action: ActionType<typeof SET_GLOBAL_DATES_RANGE>): ISimulationSymbolPositionsStates => {
                return {
                    ...state,
                    opened: initialState.symbol.opened,
                    closed: initialState.symbol.closed,
                };
            }),
});

export default candlesReducer;
