import { createAction, createAsyncAction } from 'typesafe-actions';

import {
    ICreateSimulationBody,
    IEntityDetailResponse,
    IEntityListResponse,
    IEntitySchemaDefinition,
    IFetchPageParams,
    ISimulation,
    IUpdateSimulationBody,
    SimulationAction,
} from 'alpha-api-client';

export const SET_NOMINAL_INVEST = createAction('SET_NOMINAL_INVEST', (action) => {
    return (nominalInvest: number) => action(nominalInvest);
});

export const SET_INITIAL_CAPITAL = createAction('SET_INITIAL_CAPITAL', (action) => {
    return (initialCapital: number | null) => action(initialCapital);
});

export const SET_RISK_FACTOR = createAction('SET_RISK_FACTOR', (action) => {
    return (riskFactor: number | null) => action(riskFactor);
});

export const SET_MAX_LEVERAGE = createAction('SET_MAX_LEVERAGE', (action) => {
    return (maxLeverage: number | null) => action(maxLeverage);
});

export interface IGetSimulationsSchemaActionParams {
    name: SimulationAction;
}

export interface IGetSimulationsSchemaActionResult {
    name: SimulationAction;
    result: IEntitySchemaDefinition;
}

export interface IListSimulationsActionResult {
    params: IFetchPageParams;
    result: IEntityListResponse<ISimulation>;
}

export interface IGetSimulationActionParams {
    simulationId: string;
}

export type IGetSimulationActionResult = IEntityDetailResponse<ISimulation>;

export interface IDeleteSimulationsActionParams {
    simulationId: string;
}

export interface IDeleteSimulationsActionResult {
    simulationId: string;
}

export type ICreateSimulationActionParams = ICreateSimulationBody;

export type ICreateSimulationActionResult = IEntityDetailResponse<ISimulation>;

export interface IUpdateSimulationActionParams extends IUpdateSimulationBody {
    simulationId: string;
}

export type IUpdateSimulationActionResult = IEntityDetailResponse<ISimulation>;

export const GET_SIMULATION_SCHEMA = createAsyncAction(
    'GET_SIMULATION_SCHEMA_REQUEST',
    'GET_SIMULATION_SCHEMA_SUCCESS',
    'GET_SIMULATION_SCHEMA_FAILURE',
)<IGetSimulationsSchemaActionParams, IGetSimulationsSchemaActionResult, string>();

export const LIST_SIMULATIONS = createAsyncAction(
    'FETCH_SIMULATIONS_REQUEST',
    'FETCH_SIMULATIONS_SUCCESS',
    'FETCH_SIMULATIONS_FAILURE',
)<IFetchPageParams, IListSimulationsActionResult, string>();

export const CREATE_SIMULATION = createAsyncAction(
    'CREATE_SIMULATION_REQUEST',
    'CREATE_SIMULATION_SUCCESS',
    'CREATE_SIMULATION_FAILURE',
)<ICreateSimulationActionParams, ICreateSimulationActionResult, string>();

export const GET_SIMULATION = createAsyncAction(
    'GET_SIMULATION_REQUEST',
    'GET_SIMULATION_SUCCESS',
    'GET_SIMULATION_FAILURE',
)<IGetSimulationActionParams, IGetSimulationActionResult, string>();

export const UPDATE_SIMULATION = createAsyncAction(
    'UPDATE_SIMULATION_REQUEST',
    'UPDATE_SIMULATION_SUCCESS',
    'UPDATE_SIMULATION_FAILURE',
)<IUpdateSimulationActionParams, IUpdateSimulationActionResult, string>();

export const DELETE_SIMULATION = createAsyncAction(
    'DELETE_SIMULATION_REQUEST',
    'DELETE_SIMULATION_SUCCESS',
    'DELETE_SIMULATION_FAILURE',
)<IDeleteSimulationsActionParams, IDeleteSimulationsActionResult, string>();
