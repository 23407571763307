import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { IFetchPageParams } from 'alpha-api-client';
import { RootAction, RootState, Services } from 'alpha-screener-types';

import store from '../../../../store/index';
import {
    COMPUTE_SIMULATION_OPTIMIZATION,
    CREATE_SIMULATION_OPTIMIZATION,
    DELETE_SIMULATION_OPTIMIZATION,
    GET_SIMULATION_OPTIMIZATION,
    GET_SIMULATION_OPTIMIZATION_SCHEMA,
    LIST_SIMULATION_OPTIMIZATIONS,
    UPDATE_SIMULATION_OPTIMIZATION,
} from './actions';

export const getSimulationOptimizationSchemaEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(GET_SIMULATION_OPTIMIZATION_SCHEMA.request)),
        mergeMap(async (action) => {
            const { simulationId, name } = action.payload;
            try {
                const result = await api.apiClient.simulations.optimizations.getSimulationOptimizationSchema(simulationId, name);
                return GET_SIMULATION_OPTIMIZATION_SCHEMA.success({ name, simulationId, result });
            } catch (e) {
                return GET_SIMULATION_OPTIMIZATION_SCHEMA.failure(e.message);
            }
        }),
    );
};

export const fetchSimulationOptimizationsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(LIST_SIMULATION_OPTIMIZATIONS.request)),
        mergeMap(async (action) => {
            const optimizations = store.getState().optimizations.optimizations.list;

            const filters = action.payload.filters || optimizations.query.filters;
            const sortBy = action.payload.sortBy || optimizations.query.sortBy;
            const limit = action.payload.limit || optimizations.query.limit;

            const { simulationId, ..._params } = action.payload;
            const params: IFetchPageParams = {
                ..._params,
                filters,
                sortBy,
                limit,
            };
            try {
                const result = await api.apiClient.simulations.optimizations.listSimulationOptimizations(simulationId, params);
                return LIST_SIMULATION_OPTIMIZATIONS.success({ simulationId, params, result });
            } catch (e) {
                return LIST_SIMULATION_OPTIMIZATIONS.failure(e.message);
            }
        }),
    );
};

export const getSimulationOptimizationEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(GET_SIMULATION_OPTIMIZATION.request)),
        mergeMap(async (action) => {
            const { simulationId, optimizationId } = action.payload;
            try {
                const result = await api.apiClient.simulations.optimizations.getSimulationOptimization(simulationId, optimizationId);
                return GET_SIMULATION_OPTIMIZATION.success(result);
            } catch (e) {
                return GET_SIMULATION_OPTIMIZATION.failure(e.message);
            }
        }),
    );
};

export const createSimulationOptimizationEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(CREATE_SIMULATION_OPTIMIZATION.request)),
        mergeMap(async (action) => {
            try {
                const { simulationId, ...params } = action.payload;
                const result = await api.apiClient.simulations.optimizations.createSimulationOptimization(simulationId, params);
                return CREATE_SIMULATION_OPTIMIZATION.success(result);
            } catch (e) {
                return CREATE_SIMULATION_OPTIMIZATION.failure(e.message);
            }
        }),
    );
};

export const updateSimulationOptimizationEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(UPDATE_SIMULATION_OPTIMIZATION.request)),
        mergeMap(async (action) => {
            try {
                const { simulationId, optimizationId, ...params } = action.payload;
                const result = await api.apiClient.simulations.optimizations.updateSimulationOptimization(simulationId, optimizationId, params);
                return UPDATE_SIMULATION_OPTIMIZATION.success(result);
            } catch (e) {
                return UPDATE_SIMULATION_OPTIMIZATION.failure(e.message);
            }
        }),
    );
};

export const deleteSimulationOptimizationsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(DELETE_SIMULATION_OPTIMIZATION.request)),
        mergeMap(async (action) => {
            const { simulationId, optimizationId } = action.payload;
            try {
                const result = await api.apiClient.simulations.optimizations.deleteSimulationOptimization(simulationId, optimizationId);
                return DELETE_SIMULATION_OPTIMIZATION.success({ optimizationId });
            } catch (e) {
                return DELETE_SIMULATION_OPTIMIZATION.failure(e.message);
            }
        }),
    );
};

export const computeSimulationOptimizationEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(COMPUTE_SIMULATION_OPTIMIZATION.request)),
        mergeMap(async (action) => {
            try {
                const { simulationId, optimizationId, ...params } = action.payload;
                const result = await api.apiClient.simulations.optimizations.computeSimulationOptimization(simulationId, optimizationId, params);
                return COMPUTE_SIMULATION_OPTIMIZATION.success(result);
            } catch (e) {
                return COMPUTE_SIMULATION_OPTIMIZATION.failure(e.message);
            }
        }),
    );
};
