import { Moment } from 'moment';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { CandleInterval, ICandlePageApiResult, IImportCandleApiResult } from 'alpha-api-client';

import { IFetchParams } from '../../../common/types';

export interface IFetchSimulationSymbolCandlesParams extends IFetchParams {
    simulationId: string;
    symbolId: string;
    startDate?: Moment;
    endDate?: Moment;
    interval?: CandleInterval;
}

export interface IFetchSimulationSymbolCandlesResult {
    result: ICandlePageApiResult;
    params: IFetchSimulationSymbolCandlesParams;
}

export interface IImportSymbolCandlesParams {
    simulationId: string;
    symbolId: string;
    fromDate?: string;
    resetData?: boolean;
}

export interface IImportSymbolCandlesResult {
    params: IImportSymbolCandlesParams;
    result: IImportCandleApiResult;
}

export const SELECT_CANDLES_CHART_INTERVAL = createAction('SELECT_CANDLES_CHART_INTERVAL', (action) => {
    return (interval: CandleInterval) => action(interval);
});

export const FETCH_LIST_CANDLES = createAsyncAction(
    'FETCH_LIST_CANDLES_REQUEST',
    'FETCH_LIST_CANDLES_SUCCESS',
    'FETCH_LIST_CANDLES_FAILURE',
)<IFetchSimulationSymbolCandlesParams, IFetchSimulationSymbolCandlesResult, string>();

export const MORE_LIST_CANDLES = createAsyncAction(
    'MORE_LIST_CANDLES_REQUEST',
    'MORE_LIST_CANDLES_SUCCESS',
    'MORE_LIST_CANDLES_FAILURE',
)<IFetchSimulationSymbolCandlesParams, IFetchSimulationSymbolCandlesResult, string>();

export const FETCH_CHART_CANDLES = createAsyncAction(
    'FETCH_CHART_CANDLES_REQUEST',
    'FETCH_CHART_CANDLES_SUCCESS',
    'FETCH_CHART_CANDLES_FAILURE',
)<IFetchSimulationSymbolCandlesParams, IFetchSimulationSymbolCandlesResult, string>();

export const IMPORT_SYMBOL_CANDLES = createAsyncAction(
    'IMPORT_SYMBOL_CANDLES_REQUEST',
    'IMPORT_SYMBOL_CANDLES_SUCCESS',
    'IMPORT_SYMBOL_CANDLES_FAILURE',
)<IImportSymbolCandlesParams, IImportSymbolCandlesResult, string>();
