import * as _ from 'lodash';
import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from './components/SuspenseLoader';
import BaseLayout from './layouts/BaseLayout';
import SidebarLayout from './layouts/SidebarLayout';

const Loader = Component => props => (
    <Suspense fallback={<SuspenseLoader/>}>
        <Component {...props} />
    </Suspense>
);

// Simulations

const Simulations = Loader(lazy(() => import('src/content/simulations/components/simulations/SimulationListContainer')));
const SimulationDetailContainer = Loader(lazy(() => import('src/content/simulations/components/simulations/SimulationDetailContainer')));
const SimulationSymbolDetailContainer = Loader(lazy(() => import('src/content/simulations/components/symbols/components/SimulationSymbolDetailContainer')));
const SimulationEditContainer = Loader(lazy(() => import('src/content/simulations/components/simulations/SimulationEditContainer')));
const OptimizationEditContainer = Loader(lazy(() => import('src/content/simulations/components/optimizations/components/OptimizationEditContainer')));

// Wallets

const Wallets = Loader(lazy(() => import('src/content/wallets/components/wallets/WalletListContainer')));
const WalletDetailContainer = Loader(lazy(() => import('src/content/wallets/components/WalletDetailContainer')));
const WalletEditContainer = Loader(lazy(() => import('src/content/wallets/components/wallets/WalletEditContainer')));
const WalletSymbolDetailContainer = Loader(lazy(() => import('src/content/wallets/components/WalletSymbolDetailContainer')));

// Status

const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));

export class Routes {
    public static ROOT = `/`;

    public static SIMULATIONS_LIST = `/simulations`;
    public static SIMULATION_DETAIL = `${Routes.SIMULATIONS_LIST}/:simulationId`;
    public static SIMULATION_EDIT = `${Routes.SIMULATION_DETAIL}/edit`;
    public static SIMULATION_EDIT_OPTIMIZATIONS = `${Routes.SIMULATION_DETAIL}/edit-optimizations`;
    public static SIMULATION_SYMBOL_DETAIL = `${Routes.SIMULATION_DETAIL}/:symbolId`;

    public static WALLETS_LIST = `/wallets`;
    public static WALLET_DETAIL = `${Routes.WALLETS_LIST}/:walletId`;
    public static WALLET_EDIT = `${Routes.WALLET_DETAIL}/edit`;
    public static WALLET_SYMBOL_DETAIL = `${Routes.WALLET_DETAIL}/:symbolId`;
}

export function buildRoute(route: string, params?: any) {
    return _.reduce(params, (_route, value, key) => {
        return _route.replace(new RegExp(`:${key}`, 'g'), value);
    }, route);
}

export const routes: RouteObject[] = [{
    path: Routes.ROOT,
    element: <BaseLayout/>,
    children: [{
        path: '/',
        element: <Navigate to={Routes.SIMULATIONS_LIST} replace/>,
    }, {
        path: '*',
        element: <Status404/>,
    }],
}, {
    path: 'simulations',
    element: <SidebarLayout/>,
    children: [{
        path: Routes.SIMULATIONS_LIST,
        element: <Simulations/>,
    }, {
        path: Routes.SIMULATION_DETAIL,
        element: <SimulationDetailContainer/>,
    }, {
        path: Routes.SIMULATION_EDIT,
        element: <SimulationEditContainer/>,
    }, {
        path: Routes.SIMULATION_EDIT_OPTIMIZATIONS,
        element: <OptimizationEditContainer/>,
    }, {
        path: Routes.SIMULATION_SYMBOL_DETAIL,
        element: <SimulationSymbolDetailContainer/>,
    }],
}, {
    path: 'wallets',
    element: <SidebarLayout/>,
    children: [{
        path: Routes.WALLETS_LIST,
        element: <Wallets/>,
    }, {
        path: Routes.WALLET_DETAIL,
        element: <WalletDetailContainer/>,
    }, {
        path: Routes.WALLET_EDIT,
        element: <WalletEditContainer/>,
    }, {
        path: Routes.WALLET_SYMBOL_DETAIL,
        element: <WalletSymbolDetailContainer/>,
    }],
}];
