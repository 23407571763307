import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TableChartIcon from '@mui/icons-material/TableChart';
import { ReactNode } from 'react';

export interface IMenuItem {
    link?: string;
    icon?: ReactNode;
    badge?: string;
    items?: IMenuItem[];
    name: string;
}

export interface IMenuItems {
    items: IMenuItem[];
    heading: string;
}

const menuItems: IMenuItems[] = [
    {
        heading: '',
        items: [
            {
                name: 'Simulations',
                link: '/simulations',
                icon: TableChartIcon,
            },
            {
                name: 'Wallets',
                link: '/wallets',
                icon: AccountBalanceWalletIcon,
            },
        ],
    },
];

export default menuItems;
