import { EventSourcePolyfill } from 'event-source-polyfill';
import { Observable } from 'rxjs';

import { HttpRequestHelpers } from './HttpRequestHelpers';

export class SseClient {

    private readonly apiBaseUrl: string;

    constructor(apiBaseUrl: string) {
        this.apiBaseUrl = apiBaseUrl;
    }

    public listen(sseUrl: string, tid: string): Observable<any> {
        return new Observable<any>((obs) => {
            const headers = HttpRequestHelpers.buildHttpAuthorizationRequestHeader();
            const es = new EventSourcePolyfill(`${this.apiBaseUrl}/${sseUrl}?tid=${tid}`, { headers });
            es.onopen = () => {
                const clearIntervalId = setInterval(() => {
                    if (es.readyState === EventSource.CLOSED) {
                        clearInterval(clearIntervalId);
                        this.listen(sseUrl, tid);
                    }
                }, 5000);
            };
            es.onmessage = (evt: any) => {
                obs.next(JSON.parse(evt.data));
            };
            return () => es.close();
        });
    }

}
