import Box from '@mui/material/Box';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

const PREFIX = 'LinearProgressWithLabel';

const classes = {
    primary: `${PREFIX}-primary`,
    error: `${PREFIX}-error`,
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.primary}`]: {
        backgroundColor: theme.palette.primary.light,
    },

    [`& .${classes.error}`]: {
        backgroundColor: '#ba002b',
    },
}));

export default (props: LinearProgressProps & { value: number; status: 'active' | 'success' | 'error' }) => {

    return (
        <StyledBox display='flex' alignItems='center'>
            <Box width='100%' mr={1}>
                <LinearProgress variant='determinate' {...props} classes={{ colorPrimary: props.status === 'error' ? classes.error : classes.primary }}/>
            </Box>
            <Box minWidth={35}>
                <Typography variant='body2' color='textSecondary'>{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </StyledBox>
    );
};
