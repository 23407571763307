import { AbstractClient } from '../../AbstractClient';
import { SessionContext } from '../../SessionContext';
import { IEntityDetailResponse, IEntityListResponse, IEntitySchemaDefinition } from '../../types/entities';
import { IOrdersPageApiResult } from '../../types/orders';
import { IFetchPageParams } from '../../types/pagination';
import { ISummary, IWalletSummary } from '../../types/summaries';
import { ICreateWalletBody, IUpdateWalletBody, IWallet, IWalletAccountBalanceInfo, IWalletSummaryParams, WalletAction } from '../../types/wallets';
import { SimulationOptimizationsClient } from './SimulationOptimizationsClient';
import { SimulationPositionsClient } from './SimulationPositionsClient';
import { SimulationSymbolsClient } from './SimulationSymbolsClient';

export class WalletsClient extends AbstractClient {

    public readonly symbols: SimulationSymbolsClient;
    public readonly optimizations: SimulationOptimizationsClient;
    public readonly positions: SimulationPositionsClient;

    constructor(session: SessionContext) {
        super(session);
        this.positions = new SimulationPositionsClient(session);
        this.optimizations = new SimulationOptimizationsClient(session);
        this.symbols = new SimulationSymbolsClient(session);
    }

    public getWalletSchema(schema: WalletAction): Promise<IEntitySchemaDefinition> {
        return this.runRequest({
            method: 'GET',
            url: `${this.session.apiUrl}/wallets/$schema/${schema}`,
        });
    }

    public listWallets(params: IFetchPageParams): Promise<IEntityListResponse<IWallet>> {
        return this.getPaginatedEntity({
            method: 'GET',
            url: `${this.session.apiUrl}/wallets`,
        }, params);
    }

    public getWallet(walletId: string): Promise<IEntityDetailResponse<IWallet>> {
        const url = `${this.session.apiUrl}/wallets/${walletId}`;
        return this.runRequest({ method: 'GET', url });
    }

    public getWalletBalance(walletId: string): Promise<IWalletAccountBalanceInfo> {
        const url = `${this.session.apiUrl}/wallets/${walletId}/balance`;
        return this.runRequest({ method: 'GET', url });
    }

    public createWallet(data: ICreateWalletBody): Promise<IEntityDetailResponse<IWallet>> {
        return this.runRequest({
            method: 'POST',
            url: `${this.session.apiUrl}/wallets`,
            data,
        });
    }

    public updateWallet(walletId: string, data: IUpdateWalletBody): Promise<IEntityDetailResponse<IWallet>> {
        return this.runRequest({
            method: 'PUT',
            url: `${this.session.apiUrl}/wallets/${walletId}`,
            data,
        });
    }

    public deleteWallet(walletId: string): Promise<void> {
        return this.runRequest({
            method: 'DELETE',
            url: `${this.session.apiUrl}/wallets/${walletId}`,
        });
    }

    public getWalletOrders(walletId: string, params: IFetchPageParams): Promise<IOrdersPageApiResult> {
        return this.getPaginatedEntity({
            method: 'GET',
            url: `${this.session.apiUrl}/wallets/${walletId}/orders`,
        }, params);
    }

    public getWalletSummary(walletId: string, params: IWalletSummaryParams): Promise<IWalletSummary> {
        const query = this.generateQueryString({
            from: params.startDate,
            to: params.endDate,
            cashByInterval: params.cashByInterval,
        });
        const url = `${this.session.apiUrl}/wallets/${walletId}/summary?${query}`;
        return this.runRequest({ method: 'GET', url });
    }

    public getWalletSymbolSummary(walletId: string, symbolId: string, params: IWalletSummaryParams): Promise<ISummary> {
        const query = this.generateQueryString({
            symbolId,
            from: params.startDate,
            to: params.endDate,
        });
        const url = `${this.session.apiUrl}/wallets/${walletId}/summary?${query}`;
        return this.runRequest({ method: 'GET', url });
    }

    public closeWalletOrder(walletId: string, orderId: string): Promise<void> {
        return this.runRequest({
            method: 'POST',
            url: `${this.session.apiUrl}/wallets/${walletId}/orders/$action/close-order`,
            data: {
                orderId,
            },
        });
    }
}
