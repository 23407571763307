import { createAction, createAsyncAction } from 'typesafe-actions';

import {
    IApiJobHttpResponse,
    IComputeOptimizationsRulesParams,
    IEntityDetailResponse,
    IEntitySchemaDefinition,
    IOptimization,
    IOptimizationCreate,
    IOptimizationsPageApiResult,
    IOptimizationUpdate,
    OptimizationAction,
} from 'alpha-api-client';

import { IFetchParams } from '../../../common/types';

export const SELECT_OPTIMIZATION = createAction('SELECT_OPTIMIZATION', (action) => {
    return (optimizationId: string) => action(optimizationId);
});

export interface IGetOptimizationsSchemaParams {
    simulationId: string;
    name: OptimizationAction;
}

export interface IGetOptimizationsSchemaResult {
    simulationId: string;
    name: OptimizationAction;
    result: IEntitySchemaDefinition;
}

export interface IListOptimizationsParams extends IFetchParams {
    simulationId: string;
}

export interface IListOptimizationsResult {
    simulationId: string;
    params: IFetchParams;
    result: IOptimizationsPageApiResult;
}

export interface IGetOptimizationParams {
    simulationId: string;
    optimizationId: string;
}

export type IGetOptimizationResult = IEntityDetailResponse<IOptimization>;

export interface IOptimizationsParams {
    simulationId: string;
    optimizationId: string;
}

export interface IDeleteOptimizationsResult {
    optimizationId: string;
}

export interface ICreateOptimizationParams extends IOptimizationCreate {
    simulationId: string;
}

export type ICreateOptimizationResult = IEntityDetailResponse<IOptimization>;

export interface IUpdateOptimizationParams extends IOptimizationUpdate {
    simulationId: string;
    optimizationId: string;
}

export type IUpdateOptimizationResult = IEntityDetailResponse<IOptimization>;

export interface IComputeOptimizationParams extends IComputeOptimizationsRulesParams {
    simulationId: string;
    optimizationId: string;
}

export type IComputeOptimizationResult = IApiJobHttpResponse;

export const GET_SIMULATION_OPTIMIZATION_SCHEMA = createAsyncAction(
    'GET_SIMULATION_OPTIMIZATION_SCHEMA_REQUEST',
    'GET_SIMULATION_OPTIMIZATION_SCHEMA_SUCCESS',
    'GET_SIMULATION_OPTIMIZATION_SCHEMA_FAILURE',
)<IGetOptimizationsSchemaParams, IGetOptimizationsSchemaResult, string>();

export const LIST_SIMULATION_OPTIMIZATIONS = createAsyncAction(
    'LIST_SIMULATION_OPTIMIZATIONS_REQUEST',
    'LIST_SIMULATION_OPTIMIZATIONS_SUCCESS',
    'LIST_SIMULATION_OPTIMIZATIONS_FAILURE',
)<IListOptimizationsParams, IListOptimizationsResult, string>();

export const CREATE_SIMULATION_OPTIMIZATION = createAsyncAction(
    'CREATE_SIMULATION_OPTIMIZATION_REQUEST',
    'CREATE_SIMULATION_OPTIMIZATION_SUCCESS',
    'CREATE_SIMULATION_OPTIMIZATION_FAILURE',
)<ICreateOptimizationParams, ICreateOptimizationResult, string>();

export const GET_SIMULATION_OPTIMIZATION = createAsyncAction(
    'GET_SIMULATION_OPTIMIZATION_REQUEST',
    'GET_SIMULATION_OPTIMIZATION_SUCCESS',
    'GET_SIMULATION_OPTIMIZATION_FAILURE',
)<IGetOptimizationParams, IGetOptimizationResult, string>();

export const UPDATE_SIMULATION_OPTIMIZATION = createAsyncAction(
    'UPDATE_SIMULATION_OPTIMIZATION_REQUEST',
    'UPDATE_SIMULATION_OPTIMIZATION_SUCCESS',
    'UPDATE_SIMULATION_OPTIMIZATION_FAILURE',
)<IUpdateOptimizationParams, IUpdateOptimizationResult, string>();

export const DELETE_SIMULATION_OPTIMIZATION = createAsyncAction(
    'DELETE_SIMULATION_OPTIMIZATION_REQUEST',
    'DELETE_SIMULATION_OPTIMIZATION_SUCCESS',
    'DELETE_SIMULATION_OPTIMIZATION_FAILURE',
)<IOptimizationsParams, IDeleteOptimizationsResult, string>();

export const COMPUTE_SIMULATION_OPTIMIZATION = createAsyncAction(
    'COMPUTE_SIMULATION_OPTIMIZATION_REQUEST',
    'COMPUTE_SIMULATION_OPTIMIZATION_SUCCESS',
    'COMPUTE_SIMULATION_OPTIMIZATION_FAILURE',
)<IComputeOptimizationParams, IComputeOptimizationResult, string>();
