import * as _ from 'lodash';
import qs from 'qs';
import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { PositionStatus } from 'alpha-api-client';
import { RootAction, RootState, Services } from 'alpha-screener-types';

import store from '../../../../store/index';
import { computeFiltersWithDateRange } from '../../../common/epicsUtils';
import { IFetchParams } from '../../../common/types';
import {
    FETCH_SIMULATION_CLOSED_POSITIONS,
    FETCH_SIMULATION_OPENED_POSITIONS,
    FETCH_SIMULATION_SYMBOL_CLOSED_POSITIONS,
    FETCH_SIMULATION_SYMBOL_OPENED_POSITIONS,
    IFetchSimulationPositionsParams,
    IFetchSimulationSymbolPositionsParams,
    MORE_SIMULATION_CLOSED_POSITIONS,
    MORE_SIMULATION_OPENED_POSITIONS,
    MORE_SIMULATION_SYMBOL_CLOSED_POSITIONS,
    MORE_SIMULATION_SYMBOL_OPENED_POSITIONS,
} from './actions';
import { IPositionsStateQuery } from './reducer';

function computeFetchSimulationPositionsParams(_params: IFetchSimulationPositionsParams,
                                               stateQuery: IPositionsStateQuery,
                                               status?: PositionStatus) {
    const simulationId = _params.simulationId;
    const optimizationId = _params.optimizationId;
    const nominalInvest = _params.nominalInvest;
    const startDate = _params.startDate;
    const endDate = _params.endDate;
    const filters = computeFiltersWithDateRange('date', _params.filters || stateQuery.filters, startDate, endDate);
    _.remove(filters, f => f.field === 'optimizationid');
    if (optimizationId) {
        filters.push({
            field: 'optimizationid', // lower case necessary !
            operator: '=',
            value: optimizationId,
        });
    }
    const sortBy = _params.sortBy || stateQuery.sortBy;
    const limit = _params.limit || stateQuery.limit;
    const query = _params.query || qs.stringify({ status, nominalInvest });
    const params: IFetchParams = {
        page: _params.page,
        includes: _params.includes,
        reverse: _params.reverse,
        sortAscending: _params.sortAscending,
        next: _params.next,
        previous: _params.previous,
        query,
        filters,
        sortBy,
        limit,
    };
    return { simulationId, nominalInvest, params };
}

function computeFetchSimulationSymbolPositionsParams(_params: IFetchSimulationSymbolPositionsParams,
                                                     stateQuery: IPositionsStateQuery,
                                                     status?: PositionStatus) {
    const { simulationId, nominalInvest, params } = computeFetchSimulationPositionsParams(_params, stateQuery, status);
    const symbolId = _params.symbolId;
    _.remove(params.filters, f => f.field === 'symbolid');
    params.filters.push({
        field: 'symbolid', // lower case necessary !
        operator: '=',
        value: _params.symbolId,
    });
    return { simulationId, symbolId, nominalInvest, params };
}

export const fetchSimulationOpenedPositionsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(FETCH_SIMULATION_OPENED_POSITIONS.request)),
        mergeMap(async (action) => {
            const positionsState = store.getState().positions.simulation;
            const { simulationId, params } = computeFetchSimulationPositionsParams(action.payload, positionsState.opened.query, PositionStatus.OPENED);
            try {
                const result = await api.apiClient.simulations.positions.getSimulationPositions(simulationId, params);
                return FETCH_SIMULATION_OPENED_POSITIONS.success({ simulationId, params, result });
            } catch (e) {
                return FETCH_SIMULATION_OPENED_POSITIONS.failure(e.message);
            }
        }),
    );
};

export const moreSimulationOpenedPositionsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(MORE_SIMULATION_OPENED_POSITIONS.request)),
        mergeMap(async (action) => {
            const positionsState = store.getState().positions.simulation;
            if (!positionsState.opened.hasNext) {
                throw new Error('NO NEXT');
            }
            const stateQuery = positionsState.opened.query || {};
            const _params: IFetchSimulationPositionsParams = {
                ...stateQuery,
                ...action.payload,
                page: (positionsState.opened.page || 1) + 1,
            };
            const { simulationId, params } = computeFetchSimulationPositionsParams(_params, positionsState.opened.query, PositionStatus.OPENED);
            try {
                const result = await api.apiClient.simulations.positions.getSimulationPositions(simulationId, params);
                return MORE_SIMULATION_OPENED_POSITIONS.success({ simulationId, params, result });
            } catch (e) {
                return MORE_SIMULATION_OPENED_POSITIONS.failure(e.message);
            }
        }),
    );
};

export const fetchSimulationClosedPositionsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(FETCH_SIMULATION_CLOSED_POSITIONS.request)),
        mergeMap(async (action) => {
            const positionsState = store.getState().positions.simulation;
            const { simulationId, params } = computeFetchSimulationPositionsParams(action.payload, positionsState.closed.query, PositionStatus.CLOSED);
            try {
                const result = await api.apiClient.simulations.positions.getSimulationPositions(simulationId, params);
                return FETCH_SIMULATION_CLOSED_POSITIONS.success({ simulationId, params, result });
            } catch (e) {
                return FETCH_SIMULATION_CLOSED_POSITIONS.failure(e.message);
            }
        }),
    );
};

export const moreSimulationClosedPositionsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(MORE_SIMULATION_CLOSED_POSITIONS.request)),
        mergeMap(async (action) => {
            const positionsState = store.getState().positions.simulation;
            if (!positionsState.closed.hasNext) {
                throw new Error('NO NEXT');
            }
            const stateQuery = positionsState.closed.query || {};
            const _params: IFetchSimulationPositionsParams = {
                ...stateQuery,
                ...action.payload,
                page: (positionsState.closed.page || 1) + 1,
            };
            const { simulationId, params } = computeFetchSimulationPositionsParams(_params, positionsState.closed.query, PositionStatus.CLOSED);
            try {
                const result = await api.apiClient.simulations.positions.getSimulationPositions(simulationId, params);
                return MORE_SIMULATION_CLOSED_POSITIONS.success({ simulationId, params, result });
            } catch (e) {
                return MORE_SIMULATION_CLOSED_POSITIONS.failure(e.message);
            }
        }),
    );
};

export const fetchSimulationSymbolOpenedPositionsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(FETCH_SIMULATION_SYMBOL_OPENED_POSITIONS.request)),
        mergeMap(async (action) => {
            const positionsState = store.getState().positions.symbol;
            const { simulationId, symbolId, params } =
                computeFetchSimulationSymbolPositionsParams(action.payload, positionsState.opened.query, PositionStatus.OPENED);
            try {
                const result = await api.apiClient.simulations.positions.getSimulationPositions(simulationId, params);
                return FETCH_SIMULATION_SYMBOL_OPENED_POSITIONS.success({ simulationId, symbolId, params, result });
            } catch (e) {
                return FETCH_SIMULATION_SYMBOL_OPENED_POSITIONS.failure(e.message);
            }
        }),
    );
};

export const moreSimulationSymbolOpenedPositionsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(MORE_SIMULATION_SYMBOL_OPENED_POSITIONS.request)),
        mergeMap(async (action) => {
            const positionsState = store.getState().positions.symbol;
            if (!positionsState.opened.hasNext) {
                throw new Error('NO NEXT');
            }
            const stateQuery = positionsState.opened.query || {};
            const _params: IFetchSimulationSymbolPositionsParams = {
                ...stateQuery,
                ...action.payload,
                page: (positionsState.opened.page || 1) + 1,
            };
            const { simulationId, symbolId, params } = computeFetchSimulationSymbolPositionsParams(_params, positionsState.opened.query, PositionStatus.OPENED);
            try {
                const result = await api.apiClient.simulations.positions.getSimulationPositions(simulationId, params);
                return MORE_SIMULATION_SYMBOL_OPENED_POSITIONS.success({ simulationId, symbolId, params, result });
            } catch (e) {
                return MORE_SIMULATION_SYMBOL_OPENED_POSITIONS.failure(e.message);
            }
        }),
    );
};

export const fetchSimulationSymbolClosedPositionsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(FETCH_SIMULATION_SYMBOL_CLOSED_POSITIONS.request)),
        mergeMap(async (action) => {
            const positionsState = store.getState().positions.symbol;
            const { simulationId, symbolId, params } =
                computeFetchSimulationSymbolPositionsParams(action.payload, positionsState.closed.query, PositionStatus.CLOSED);
            try {
                const result = await api.apiClient.simulations.positions.getSimulationPositions(simulationId, params);
                return FETCH_SIMULATION_SYMBOL_CLOSED_POSITIONS.success({ simulationId, symbolId, params, result });
            } catch (e) {
                return FETCH_SIMULATION_SYMBOL_CLOSED_POSITIONS.failure(e.message);
            }
        }),
    );
};

export const moreSimulationSymbolClosedPositionsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(MORE_SIMULATION_SYMBOL_CLOSED_POSITIONS.request)),
        mergeMap(async (action) => {
            const positionsState = store.getState().positions.symbol;
            if (!positionsState.closed.hasNext) {
                throw new Error('NO NEXT');
            }
            const stateQuery = positionsState.closed.query || {};
            const _params: IFetchSimulationSymbolPositionsParams = {
                ...stateQuery,
                ...action.payload,
                page: (positionsState.closed.page || 1) + 1,
            };
            const { simulationId, symbolId, params } = computeFetchSimulationSymbolPositionsParams(_params, positionsState.closed.query, PositionStatus.CLOSED);
            try {
                const result = await api.apiClient.simulations.positions.getSimulationPositions(simulationId, params);
                return MORE_SIMULATION_SYMBOL_CLOSED_POSITIONS.success({ simulationId, symbolId, params, result });
            } catch (e) {
                return MORE_SIMULATION_SYMBOL_CLOSED_POSITIONS.failure(e.message);
            }
        }),
    );
};
