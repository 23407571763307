export class HttpRequestHelpers {
    public static buildHttpRequestHeaders(): any {
        return {
            ...HttpRequestHelpers.buildHttpAuthorizationRequestHeader(),
            'Content-Type': 'application/json',
        };
    }

    public static buildHttpAuthorizationRequestHeader(): any {
        return {
            Authorization: window.auth.token,
        };
    }
}
