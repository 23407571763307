export interface IShortEvents {
    s_macd_market_drop_support: boolean;
    s_macd_market_drop_resistance: boolean;
    s_macd_market_drop_sup_obl: boolean;
    s_macd5_drop_sig5: boolean;
    s_macd5_drop_sig36: boolean;
    s_macd5_drop_zero: boolean;
    s_macd_third_resistance_decrease: boolean;
    s_macd_support_lower_previous_resistance: boolean;
    s_macd_resistance_downturns: boolean;
    s_heikin_ashi_downturns: boolean;
    s_strong_macd5_drop_sig5_and_stoch_moment_downturns: boolean;
    s_macd_divergence_drop_1: boolean;
    s_macd_divergence_drop_2: boolean;
    s_macd_divergence_drop_3: boolean;
    s_psar_market_drop_support: boolean;
    s_econv_market_drop_low: boolean;
    s_econv_market_drop_low_upper_long_term_high: boolean;
    s_ichimoku_bearish_cross: boolean;
    s_ichimoku_in_kumo_from_top: boolean;
    s_ichimoku_drop_kumo_bottom: boolean;
    s_ichimoku_tenkan_drop_kijun: boolean;
}

export type SHORT_EVENT_NAMES = keyof IShortEvents;

export interface ILongEvents {
    l_macd_market_drop_support: boolean;
    l_macd_market_rise_resistance: boolean;
    l_macd_market_rise_support: boolean;
    l_macd_market_rise_res_obl: boolean;
    l_macd5_rise_sig5: boolean;
    l_macd5_drop_sig5: boolean;
    l_macd5_rise_sig36: boolean;
    l_macd5_rise_zero: boolean;
    l_macd_third_support_decrease: boolean;
    l_macd_fourth_support_decrease: boolean;
    l_macd_previous_support_greater_previous_resistance: boolean;
    l_macd_support_lower_previous_resistance: boolean;
    l_macd_support_decrease_slow_down: boolean;
    l_macd_support_upturns: boolean;
    l_heikin_ashi_upturns: boolean;
    l_heikin_ashi_follow_trend: boolean;
    l_macd12_lower_sig12_stoch_increase: boolean;
    l_stoch_moment_upturns: boolean;
    l_macd_divergence_rise_1: boolean;
    l_macd_divergence_rise_2: boolean;
    l_macd_divergence_rise_3: boolean;
    l_psar_market_rise_resistance: boolean;
    l_econv_market_rise_high: boolean;
    l_econv_market_rise_high_lower_long_term_low: boolean;
    l_ichimoku_bullish_cross: boolean;
    l_ichimoku_in_kumo_from_bottom: boolean;
    l_ichimoku_rise_kumo_top: boolean;
    l_ichimoku_tenkan_rise_kijun: boolean;
}

export type LONG_EVENT_NAMES = keyof ILongEvents;

export const SIMULATION_SHORT_EVENTS_INDEXES: SHORT_EVENT_NAMES[] = [
    's_macd_market_drop_support',
    's_macd_market_drop_resistance',
    's_macd_market_drop_sup_obl',
    's_macd5_drop_sig5',
    's_macd5_drop_sig36',
    's_macd5_drop_zero',
    's_macd_third_resistance_decrease',
    's_macd_support_lower_previous_resistance',
    's_macd_resistance_downturns',
    's_heikin_ashi_downturns',
    's_strong_macd5_drop_sig5_and_stoch_moment_downturns',
    's_macd_divergence_drop_1',
    's_macd_divergence_drop_2',
    's_macd_divergence_drop_3',
    's_psar_market_drop_support',
    's_econv_market_drop_low',
    's_econv_market_drop_low_upper_long_term_high',
    's_ichimoku_bearish_cross',
    's_ichimoku_in_kumo_from_top',
    's_ichimoku_drop_kumo_bottom',
    's_ichimoku_tenkan_drop_kijun',
];

export const SIMULATION_LONG_EVENTS_INDEXES: LONG_EVENT_NAMES[] = [
    'l_macd_market_drop_support',
    'l_macd_market_rise_resistance',
    'l_macd_market_rise_support',
    'l_macd_market_rise_res_obl',
    'l_macd5_rise_sig5',
    'l_macd5_drop_sig5',
    'l_macd5_rise_sig36',
    'l_macd5_rise_zero',
    'l_macd_third_support_decrease',
    'l_macd_fourth_support_decrease',
    'l_macd_previous_support_greater_previous_resistance',
    'l_macd_support_lower_previous_resistance',
    'l_macd_support_decrease_slow_down',
    'l_macd_support_upturns',
    'l_heikin_ashi_upturns',
    'l_heikin_ashi_follow_trend',
    'l_macd12_lower_sig12_stoch_increase',
    'l_stoch_moment_upturns',
    'l_macd_divergence_rise_1',
    'l_macd_divergence_rise_2',
    'l_macd_divergence_rise_3',
    'l_psar_market_rise_resistance',
    'l_econv_market_rise_high',
    'l_econv_market_rise_high_lower_long_term_low',
    'l_ichimoku_bullish_cross',
    'l_ichimoku_in_kumo_from_bottom',
    'l_ichimoku_rise_kumo_top',
    'l_ichimoku_tenkan_rise_kijun',
];
