export enum CandleIndicators {
    DMI = 'dmi',
    MA_5 = 'ma_5',
    MA_10 = 'ma_10',
    MA_12 = 'ma_12',
    MA_15 = 'ma_15',
    MA_20 = 'ma_20',
    MA_26 = 'ma_26',
    MA_36 = 'ma_36',
    MA_50 = 'ma_50',
    MA_78 = 'ma_78',
    MA_100 = 'ma_100',
    MA_200 = 'ma_200',
    MACD_5 = 'macd_5',
    MACD_12 = 'macd_12',
    MACD_36 = 'macd_36',
    STOCH = 'stoch',
    ATR = 'atr',
    HA = 'ha',
    SUP_RES = 'sup_res',
    PSAR = 'psar',
    ICHI = 'ichi',
    ECONV = 'econv',
    TREND = 'trend',
    TREND_MA = 'trendMa',
}

export interface ICandleIndicators {
    dmi?: IDirectionalMovementIndexIndicator;
    ma?: IMovingAverageIndicator;
    macd?: IMACDIndicators;
    stoch?: IStochasticIndicator;
    atr?: IAverageTrueRangeIndicator;
    ha?: IHeikinAshiIndicator;
    sup_res?: ISupportAndResistance;
    psar?: IParabolicSAR;
    trend?: ITrendMacd;
    trendMa?: ITrendMa;
    econv?: IConvergenceExcessIndicator;
    econv2?: IConvergenceExcessIndicator;
    ichi?: IIchimokuIndicator;
}

// Directional movement index
export interface IDirectionalMovementIndexIndicator {
    mdi?: number;
    pdi?: number;
    adx?: number;
}

// Moving average
export interface IMovingAverageIndicator {
    ma5?: number;
    ma10?: number;
    ma12?: number;
    ma15?: number;
    ma20?: number;
    ma26?: number;
    ma36?: number;
    ma50?: number;
    ma78?: number;
    ma100?: number;
    ma200?: number;
}

export interface IMACDIndicator {
    macd?: number;
    signal?: number;
    histo?: number;
}

export interface ISupportAndResistance {
    support?: number;
    sup_obl?: number;
    sup_hash?: string;
    resistance?: number;
    res_obl?: number;
    res_hash?: string;
    dist_sup_res_avg?: number;
}

export interface ITrendMacd {
    primary: string;
    secondary: string;
    score: number;
}

export interface ITrendMaIndicator {
    level: number;
    score: number;
}

export interface ITrendMa {
    rise: ITrendMaIndicator;
    drop: ITrendMaIndicator;
}

export interface IMACDIndicators {
    e5e15?: IMACDIndicator;
    e12e26?: IMACDIndicator;
    e36e78?: IMACDIndicator;
}

// Stockastic
export interface IStochasticIndicator {
    low?: number;
    high?: number;
    _k?: number;
    _d?: number;
}

// Average true range
export interface IAverageTrueRangeIndicator {
    atr14?: number;
    atr?: number;
}

// Heikin Ashi
export interface IHeikinAshiIndicator {
    open: number;
    close: number;
    high: number;
    low: number;
}

// Parabolic SAR
export interface IParabolicSAR {
    ep?: number;
    af?: number;
    trend?: string;
    psar?: number;

    // support
    rise_start?: number;
    rise_evo?: number;
    sup_obl_dur?: number;
    sup_obl?: number;
    sup_hash?: string;

    // resistance
    drop_start?: number;
    drop_evo?: number;
    res_obl_dur?: number;
    res_obl?: number;
    res_hash?: string;

}

export interface IConvergenceExcessIndicator {
    low?: number;
    low_evo?: number;
    sup_obl?: number;
    sup_obl_dur?: number;
    sup_hash?: string;
    high?: number;
    high_evo?: number;
    res_obl?: number;
    res_obl_dur?: number;
    res_hash?: string;
}

export interface IIchimokuIndicator {
    tenkanSen: number;
    kijunSen: number;
    spanA: number;
    spanB: number;
}
