import { createAsyncAction } from 'typesafe-actions';

import {
    IEntityListResponse,
    IEntitySchemaDefinition,
    IFetchPageParams,
    IImportCandleApiResult,
    ISymbolRef,
    ISymbolRefPageApiResult,
    SymbolRefAction,
} from 'alpha-api-client';

export interface IGetSimulationSymbolSchemaActionParams {
    simulationId: string;
    name: SymbolRefAction;
}

export interface IGetSimulationSymbolSchemaActionResult {
    simulationId: string;
    name: SymbolRefAction;
    result: IEntitySchemaDefinition;
}

export interface IGetSimulationSymbolActionParams {
    simulationId: string;
    symbolId: string;
}

export interface IListSimulationSymbolsActionParams extends IFetchPageParams {
    simulationId: string;
}

export interface IListSimulationSymbolsActionResult {
    result: ISymbolRefPageApiResult;
    params: IListSimulationSymbolsActionParams;
}

export interface IImportSimulationCandlesActionParams {
    fromDate?: string;
    simulationId?: string;
    resetData?: boolean;
}

export interface IImportSimulationCandlesActionResult {
    params: IImportSimulationCandlesActionParams;
    result: IImportCandleApiResult;
}

export interface IAddSimulationSymbolActionParams {
    simulationId: string;
    symbolId: string;
    leverage: number;
}

export interface IAddSimulationSymbolActionResult {
    params: IAddSimulationSymbolActionParams;
    result: IEntityListResponse<ISymbolRef>;
}

export interface IRemoveSimulationSymbolActionParams {
    simulationId: string;
    symbolId: string;
}

export interface IRemoveSimulationSymbolActionResult {
    params: IRemoveSimulationSymbolActionParams;
    result: void;
}

export const GET_SIMULATION_SYMBOL_SCHEMA = createAsyncAction(
    'GET_SIMULATION_SYMBOL_SCHEMA_REQUEST',
    'GET_SIMULATION_SYMBOL_SCHEMA_SUCCESS',
    'GET_SIMULATION_SYMBOL_SCHEMA_FAILURE',
)<IGetSimulationSymbolSchemaActionParams, IGetSimulationSymbolSchemaActionResult, string>();

export const GET_SIMULATION_SYMBOL = createAsyncAction(
    'GET_SIMULATION_SYMBOL_REQUEST',
    'GET_SIMULATION_SYMBOL_SUCCESS',
    'GET_SIMULATION_SYMBOL_FAILURE',
)<IGetSimulationSymbolActionParams, ISymbolRef, string>();

export const LIST_SIMULATION_SYMBOLS = createAsyncAction(
    'LIST_SIMULATION_SYMBOLS_REQUEST',
    'LIST_SIMULATION_SYMBOLS_SUCCESS',
    'LIST_SIMULATION_SYMBOLS_FAILURE',
)<IListSimulationSymbolsActionParams, IListSimulationSymbolsActionResult, string>();

export const ADD_SIMULATION_SYMBOL = createAsyncAction(
    'ADD_SIMULATION_SYMBOL_REQUEST',
    'ADD_SIMULATION_SYMBOL_SUCCESS',
    'ADD_SIMULATION_SYMBOL_FAILURE',
)<IAddSimulationSymbolActionParams, IAddSimulationSymbolActionResult, string>();

export const REMOVE_SIMULATION_SYMBOL = createAsyncAction(
    'REMOVE_SIMULATION_SYMBOL_REQUEST',
    'REMOVE_SIMULATION_SYMBOL_SUCCESS',
    'REMOVE_SIMULATION_SYMBOL_FAILURE',
)<IRemoveSimulationSymbolActionParams, IRemoveSimulationSymbolActionResult, string>();

export const IMPORT_SIMULATION_CANDLES = createAsyncAction(
    'IMPORT_SIMULATION_CANDLES_REQUEST',
    'IMPORT_SIMULATION_CANDLES_SUCCESS',
    'IMPORT_SIMULATION_CANDLES_FAILURE',
)<IImportSimulationCandlesActionParams, IImportSimulationCandlesActionResult, string>();
