import { SimulationsClient, SymbolsClient } from './clients';
import { WalletsClient } from './clients/wallets/WalletsClient';
import { IAlphaSessionOptions, SessionContext } from './SessionContext';

export class AlphaApiClient {

    public readonly session: SessionContext;

    public readonly symbols: SymbolsClient;
    public readonly simulations: SimulationsClient;
    public readonly wallets: WalletsClient;

    constructor(options?: IAlphaSessionOptions) {
        this.session = new SessionContext(options);
        this.symbols = new SymbolsClient(this.session);
        this.simulations = new SimulationsClient(this.session);
        this.wallets = new WalletsClient(this.session);
    }
}
