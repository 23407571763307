import { AbstractClient } from '../../AbstractClient';
import { IEntityListResponse } from '../../types/entities';
import { IFetchPageParams } from '../../types/pagination';
import { ISymbol } from '../../types/symbols';

export class SymbolsClient extends AbstractClient {

    public listSymbols(params: IFetchPageParams): Promise<IEntityListResponse<ISymbol>> {
        return this.getPaginatedEntity({
            method: 'GET',
            url: `${this.session.apiUrl}/symbols`,
        }, params);
    }
}
