import { IDiagnose } from './types/common';

export class AlphaError extends Error {
    public $diagnoses?: IDiagnose[];
    public status?: number;
    public detail?: string;
    public code?: number;

    /** @hidden */
    constructor(message: string, diagnoses?: IDiagnose[], status?: number, detail?: string, code?: number) {
        super(message);
        this.$diagnoses = diagnoses;
        this.status = status;
        this.detail = detail;
        this.code = code;
        Error.captureStackTrace(this, this.constructor);
    }
}
