import { createAsyncAction } from 'typesafe-actions';

import { ISimulationSummaryParams, ISimulationSymbolSummaryParams, ISummary } from 'alpha-api-client';

export interface ISimulationSummaryActionParams extends ISimulationSummaryParams {
    simulationId: string;
}

export interface ISimulationSummaryActionResult {
    params: ISimulationSummaryActionParams;
    result: ISummary;
}

export interface ISimulationSymbolSummaryActionParams extends ISimulationSymbolSummaryParams {
    simulationId: string;
    symbolId: string;
}

export interface ISymbolSimulationSummaryActionResult {
    params: ISimulationSymbolSummaryActionParams;
    result: ISummary;
}

export const FETCH_SIMULATION_SUMMARY = createAsyncAction(
    'FETCH_SIMULATION_SUMMARY_REQUEST',
    'FETCH_SIMULATION_SUMMARY_SUCCESS',
    'FETCH_SIMULATION_SUMMARY_FAILURE',
)<ISimulationSummaryActionParams, ISimulationSummaryActionResult, string>();

export const FETCH_SIMULATION_SYMBOL_SUMMARY = createAsyncAction(
    'FETCH_SIMULATION_SYMBOL_SUMMARY_REQUEST',
    'FETCH_SIMULATION_SYMBOL_SUMMARY_SUCCESS',
    'FETCH_SIMULATION_SYMBOL_SUMMARY_FAILURE',
)<ISimulationSymbolSummaryActionParams, ISymbolSimulationSummaryActionResult, string>();
