import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { RootAction, RootState, Services } from 'alpha-screener-types';

import { FETCH_WALLET_SUMMARY } from './actions';

export const fetchWalletSummaryEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(FETCH_WALLET_SUMMARY.request)),
        mergeMap(async (action) => {
            const { walletId, ...params } = action.payload;
            const result = await api.apiClient.wallets.getWalletSummary(walletId, params);
            return FETCH_WALLET_SUMMARY.success({ params: action.payload, result });
        }),
    );
};
