import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { RootAction, RootState, Services } from 'alpha-screener-types';

import store from '../../store/index';
import { CREATE_WALLET, DELETE_WALLET, GET_WALLET, GET_WALLET_BALANCE, GET_WALLET_SCHEMA, IListWalletsParams, LIST_WALLETS, UPDATE_WALLET } from './actions';

export const getWalletSchemaEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(GET_WALLET_SCHEMA.request)),
        mergeMap(async (action) => {
            const { name } = action.payload;
            try {
                const result = await api.apiClient.wallets.getWalletSchema(name);
                return GET_WALLET_SCHEMA.success({ name, result });
            } catch (e) {
                return GET_WALLET_SCHEMA.failure(e.message);
            }
        }),
    );
};

export const getWalletEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(GET_WALLET.request)),
        mergeMap(async (action) => {
            const walletId = action.payload.walletId;
            try {
                const result = await api.apiClient.wallets.getWallet(walletId);
                return GET_WALLET.success(result);
            } catch (e) {
                return GET_WALLET.failure(e.message);
            }
        }),
    );
};

export const listWalletsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(LIST_WALLETS.request)),
        mergeMap(async (action) => {
            const { wallets } = store.getState().wallets;

            const filters = action.payload.filters || wallets.list.query.filters;
            const sortBy = action.payload.sortBy || wallets.list.query.sortBy;

            const params: IListWalletsParams = {
                ...action.payload,
                filters,
                sortBy,
            };

            try {
                const result = await api.apiClient.wallets.listWallets(params);
                return LIST_WALLETS.success({
                    params,
                    result,
                });
            } catch (e) {
                return LIST_WALLETS.failure(e.message);
            }
        }),
    );
};

export const createWalletEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(CREATE_WALLET.request)),
        mergeMap(async (action) => {
            try {
                const result = await api.apiClient.wallets.createWallet(action.payload);
                return CREATE_WALLET.success(result);
            } catch (e) {
                return CREATE_WALLET.failure(e.message);
            }
        }),
    );
};

export const updateWalletEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(UPDATE_WALLET.request)),
        mergeMap(async (action) => {
            try {
                const { walletId, ...data } = action.payload;
                const result = await api.apiClient.wallets.updateWallet(walletId, data);
                return UPDATE_WALLET.success(result);
            } catch (e) {
                return UPDATE_WALLET.failure(e.message);
            }
        }),
    );
};

export const deleteWalletEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(DELETE_WALLET.request)),
        mergeMap(async (action) => {
            const { walletId } = action.payload;
            try {
                await api.apiClient.wallets.deleteWallet(walletId);
                return DELETE_WALLET.success({ walletId });
            } catch (e) {
                return DELETE_WALLET.failure(e.message);
            }
        }),
    );
};

export const getWalletBalanceEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(GET_WALLET_BALANCE.request)),
        mergeMap(async (action) => {
            const walletId = action.payload.walletId;
            try {
                const result = await api.apiClient.wallets.getWalletBalance(walletId);
                return GET_WALLET_BALANCE.success(result);
            } catch (e) {
                return GET_WALLET_BALANCE.failure(e.message);
            }
        }),
    );
};
