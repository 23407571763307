import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { RootAction, RootState, Services } from 'alpha-screener-types';

import { FETCH_SIMULATION_SUMMARY } from './actions';

export const fetchSimulationSummaryEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(FETCH_SIMULATION_SUMMARY.request)),
        mergeMap(async (action) => {
            const { simulationId, ...params } = action.payload;
            try {
                const result = await api.apiClient.simulations.getSimulationSummary(simulationId, params);
                return FETCH_SIMULATION_SUMMARY.success({ params: action.payload, result });
            } catch (e) {
                return FETCH_SIMULATION_SUMMARY.failure(e.message);
            }
        }),
    );
};
