import { Moment } from 'moment';
import { createAction, createAsyncAction } from 'typesafe-actions';

export interface IDatesRange {
    startDate?: Moment | null;
    endDate?: Moment | null;
}

export const SET_GLOBAL_DATES_RANGE = createAction('SET_GLOBAL_DATES_RANGE', (action) => {
    return (range: IDatesRange) => action(range);
});

export const LISTEN_GLOBAL_EVENTS = createAsyncAction(
    'LISTEN_GLOBAL_EVENTS_REQUEST',
    'LISTEN_GLOBAL_EVENTS_SUCCESS',
    'LISTEN_GLOBAL_EVENTS_FAILURE',
)<void, void, void>();
