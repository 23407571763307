import { createAsyncAction } from 'typesafe-actions';

import {
    ICreateWalletBody,
    IEntityDetailResponse,
    IEntityListResponse,
    IEntitySchemaDefinition,
    IUpdateWalletBody,
    IWallet,
    IWalletAccountBalanceInfo,
    WalletAction,
} from 'alpha-api-client';

import { IFetchParams } from '../common/types';

export interface IGetWalletsSchemaParams {
    name: WalletAction;
}

export interface IGetWalletsSchemaResult {
    name: WalletAction;
    result: IEntitySchemaDefinition;
}

export type IListWalletsParams = IFetchParams;

export interface IListWalletsResult {
    params: IFetchParams;
    result: IEntityListResponse<IWallet>;
}

export interface IGetWalletParams {
    walletId: string;
}

export type IGetWalletResult = IEntityDetailResponse<IWallet>;

export interface IGetWalletBalanceParams {
    walletId: string;
}

export type IGetWalletBalanceResult = IWalletAccountBalanceInfo;

export interface IDeleteWalletsParams {
    walletId: string;
}

export interface IDeleteWalletsResult {
    walletId: string;
}

export type ICreateWalletParams = ICreateWalletBody;

export type ICreateWalletResult = IEntityDetailResponse<IWallet>;

export interface IUpdateWalletParams extends IUpdateWalletBody {
    walletId: string;
}

export type IUpdateWalletResult = IEntityDetailResponse<IWallet>;

export const GET_WALLET_SCHEMA = createAsyncAction(
    'GET_WALLET_SCHEMA_REQUEST',
    'GET_WALLET_SCHEMA_SUCCESS',
    'GET_WALLET_SCHEMA_FAILURE',
)<IGetWalletsSchemaParams, IGetWalletsSchemaResult, string>();

export const LIST_WALLETS = createAsyncAction(
    'FETCH_WALLETS_REQUEST',
    'FETCH_WALLETS_SUCCESS',
    'FETCH_WALLETS_FAILURE',
)<IListWalletsParams, IListWalletsResult, string>();

export const CREATE_WALLET = createAsyncAction(
    'CREATE_WALLET_REQUEST',
    'CREATE_WALLET_SUCCESS',
    'CREATE_WALLET_FAILURE',
)<ICreateWalletParams, ICreateWalletResult, string>();

export const GET_WALLET = createAsyncAction(
    'GET_WALLET_REQUEST',
    'GET_WALLET_SUCCESS',
    'GET_WALLET_FAILURE',
)<IGetWalletParams, IGetWalletResult, string>();

export const UPDATE_WALLET = createAsyncAction(
    'UPDATE_WALLET_REQUEST',
    'UPDATE_WALLET_SUCCESS',
    'UPDATE_WALLET_FAILURE',
)<IUpdateWalletParams, IUpdateWalletResult, string>();

export const DELETE_WALLET = createAsyncAction(
    'DELETE_WALLET_REQUEST',
    'DELETE_WALLET_SUCCESS',
    'DELETE_WALLET_FAILURE',
)<IDeleteWalletsParams, IDeleteWalletsResult, string>();

export const GET_WALLET_BALANCE = createAsyncAction(
    'GET_WALLET_BALANCE_REQUEST',
    'GET_WALLET_BALANCE_SUCCESS',
    'GET_WALLET_BALANCE_FAILURE',
)<IGetWalletBalanceParams, IGetWalletBalanceResult, string>();
