import { IncomingHttpHeaders } from 'http';
import * as stream from 'stream';

export type DiagnoseSeverity = 'success' | 'info' | 'warning' | 'error';

export interface IDiagnose {
    severity: DiagnoseSeverity;
    message: string;
    code?: string;
    title?: string;
    detail?: string;
    stack?: string;
}

export interface IApiHttpResponse {
    $diagnoses?: IDiagnose[];
}

export interface IApiJobHttpResponse extends IApiHttpResponse {
    jobId?: string;
}

export interface IFormData {
    getHeaders?: () => IncomingHttpHeaders;
    append: (prop: 'file', file: any) => void;
}

export interface IAlphaApiHttpRequest {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    url: string;
    headers?: IncomingHttpHeaders;
    data?: object;
    params?: object;
}

/**
 * Object representing the result of a download. It contains a readable stream, the content-type and the name of the file downloaded.
 */
export interface IAlphaHttpResponseStream {
    /**
     * A readable stream which can be use to retrieve the resource downloaded.
     */
    stream: stream.Readable;
    /**
     * The content-type of the file.
     */
    contentType: string;
    /**
     * The name of the file downloaded.
     */
    filename: string;
}

export enum SseEventType {
    JOB = 'job',
    DOWNLOAD = 'download',
}
